import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import Sortable from 'react-sortablejs';
import classnames from 'classnames';

import ButtonToggle from '../atoms/ButtonToggle';
import CheckboxWithLabel from '../atoms/CheckboxWithLabel';
import DividerDottedVWide from '../atoms/DividerDottedVWide';
import FlexBox from '../atoms/FlexBox';
import HeadingS from '../atoms/HeadingS';
import SmallText from '../atoms/SmallText';
import Draggable from '../molecules/Draggable';

const StyledMenuAndSelected = styled.div`
  height: 340px;
  display: flex;
  align-items: stretch;
  margin-bottom: 4px;
  &.has-border {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
  }
`
const StyledMenu = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-right: 4px;
`
const StyledMenuIn = styled.div`
  padding: 32px 24px;
`
const StyledMenuSection = styled.div`
  position: relative;
`
const StyledMenuHeading = styled(HeadingS)`
  cursor: pointer;
  position: relative;
`
const StyledMenuItems = styled(FlexBox)`
  height: 0;
  align-items: flex-start;
  overflow: hidden;
  &.is-active {
    height: auto;
  }
`
const StyledSelected = styled.div`
  width: 284px;
  flex-shrink: 0;
`
const StyledSelectedIn = styled.div`
  padding: 32px 16px 8px 8px;
`
const StyledSelectedHeader = styled.div`
  margin-bottom: 16px;
  padding-left: 16px;
`
const StyledCheckbox = styled(CheckboxWithLabel)`
  width: 160px;
  box-sizing: border-box;
  margin-top: 8px;
  padding-right: 1em;
`
const StyledButtonToggle = styled(ButtonToggle)`
  position: absolute;
  top: 0;
  right: 0;
`

class SelectDispItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked,
      isOpened: [false, false, false, false],
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      // チェックした要素が更新されたとき
      prevProps.checked !== this.props.checked
    ) {
      this.setState({
        checked: this.props.checked
      });
    }
  }
  render() {
    // 表示項目カテゴリを開閉する
    const toggleMenu = (i) => {
      const isOpened = this.state.isOpened;
      isOpened[i] = !isOpened[i];
      this.setState({
        isOpened: isOpened
      })
    }

    // 表示項目を選択する
    const selectItem = (e) => {
      const item = e.target.value.split(',');
      const checked = _.clone(this.state.checked);
      if (e.target.checked === true) {
        checked.push({
          id: item[0],
          label: item[1],
        });
      } else {
        checked.splice(
          _.findIndex(checked, o => { return o.id === item[0] }),
          1
        );
      }
      this.setState({
        checked: checked
      });
      this.props.updateChecked(checked);
    }

    // 選択済みの表示項目を削除する
    const removeItem = (id) => {
      const checked = _.clone(this.state.checked);
      checked.splice(
        _.findIndex(checked, o => { return o.id === id }),
        1
      );
      this.setState({
        checked: checked
      });
      this.props.updateChecked(checked);
    }

    // 表示項目をソートする
    const sortItems = (sortedItems) => {
      const checked = sortedItems.map((str) => {
        const item = str.split(',');
        return {
          id: item[0],
          label: item[1],
        }
      });
      this.setState({
        checked: checked
      });
      this.props.updateChecked(checked);
    }

    return (
      <StyledMenuAndSelected
        className = {
          classnames({
            'has-border': this.props.hasBorder === true,
          })
        }
      >
        <StyledMenu>
          <Scrollbars>
            <StyledMenuIn>
              {
                (() => {
                  // 選択可能な表示項目一覧を生成
                  return this.props.selectableDispItems.map((category, index) => {
                    return (
                      <StyledMenuSection
                        key = { _.uniqueId() }
                        className = { index > 0 ? 'm-t-16' : '' }
                      >
                        <StyledMenuHeading
                          noMargin
                          onClick = { (() => { toggleMenu(index) }) }
                        >
                          { category.categoryName }
                          <StyledButtonToggle
                            isActive = { this.state.isOpened[index] === true }
                          />
                        </StyledMenuHeading>
                        <StyledMenuItems
                          flexWrap
                          className = {(() => {
                            return (this.state.isOpened[index] === true) ? 'is-active' : ''
                          })()}
                        >
                          {
                            (() => {
                              return category.dispItem.map((item, indexChild) => {
                                return (
                                  <StyledCheckbox
                                    key = { _.uniqueId() }
                                    id = { `progress_display_select_${index}_${indexChild}` }
                                    value = { `${item.id},${item.label}` }
                                    checked = {(() => {
                                      return (
                                        _.findIndex(this.state.checked, o => {
                                          return o.id === item.id
                                        }) > -1)
                                          ? 'checked'
                                          : '';
                                    })()}
                                    onChange = { selectItem }
                                  >
                                    { item.label }
                                  </StyledCheckbox>
                                )
                              });
                            })()
                          }
                        </StyledMenuItems>
                      </StyledMenuSection>
                    )
                  })
                })()
              }
            </StyledMenuIn>
          </Scrollbars>
        </StyledMenu>
        <DividerDottedVWide />
        <StyledSelected>
          <Scrollbars>
            <StyledSelectedIn>
              <StyledSelectedHeader>
                <HeadingS noMargin>
                  選択した表示項目
                </HeadingS>
                <SmallText>
                  ドラッグ&ドロップで並べ替え
                </SmallText>
              </StyledSelectedHeader>
              <Sortable
                onChange = {
                  (order, sortable, e) => {
                    sortItems(order)
                  }
                }
              >
                {
                  (() => {
                    // 選択済みの表示項目一覧を生成
                    return this.state.checked.map((item, index) => {
                      return (
                        <div
                          key = { _.uniqueId() }
                          data-id = { `${item.id},${item.label}` }
                        >
                          <Draggable
                            onClose = { (() => {
                              removeItem(item.id)
                            }) }
                          >
                            { item.label }
                          </Draggable>
                        </div>
                      )
                    });
                  })()
                }
              </Sortable>
            </StyledSelectedIn>
          </Scrollbars>
        </StyledSelected>
      </StyledMenuAndSelected>
    )
  }
}

export default SelectDispItems;
