import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import HeadingS from '../../../atoms/HeadingS';
import Radio from '../../../atoms/Radio';
import BarBottom from '../../../atoms/BarBottom';
import Button from '../../../atoms/Button';
import ModalErrorMessage from '../../../organisms/ModalErrorMessage';

import User from '../../../../utils/user';

const StyledRadio = styled(Radio)`
  margin-top: 8px;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

class ExternalDataClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientList: [],
      isErrorOpened: false,
      bodyText: '',
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    // クライアント一覧取得
    await axios.get(
      backendApi + 'client',
      {
        params: {
          ...User.apiParams()
        }
      }
    )
    .then((response) => {
      this.setState({
        clientList: response.data
      })
      //　プログレス非表示
      this.props.endProgressing();
    })
    .catch((error) => {
      this.setState({
        isErrorOpened: true,
        bodyText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
      });
      //　プログレス非表示
      this.props.endProgressing();
    })
  }

  render() {
    // エラーモーダル開閉
    const toggleErrorModal = (boolean) => {
      this.setState({
        isErrorOpened: boolean
      });
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isErrorOpened }
          close = { () => toggleErrorModal(false) }
          bodyText = { this.state.bodyText }
        />
        <HeadingS>クライアント（広告主）を選択してください。</HeadingS>
          {
            (() => {
              return this.state.clientList.map((item, i) => {
                return (
                  <StyledRadio
                    key = { 'radio_' + i }
                    name = { 'client' }
                    id = { `client-${i}` }
                    value = { item.id }
                    checked = { this.props.clientId === item.id }
                    onChange = { (() => this.props.changeClientId(item.id)) }
                  >
                    { item.clientName }
                  </StyledRadio>
                )
              });
            })()
          }
        <BarBottom>
          <Button
            as = { Link }
            to = '/setting/external-data'
          >
            キャンセル
          </Button>
          {
            (() => {
              if (this.props.clientId) {
                return (
                  <Button
                    as = { Link }
                    to = '/setting/external-data/ga'
                    color="orange"
                    className = 'm-l-8'
                  >
                    次に進む
                  </Button>
                )
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default ExternalDataClient;
