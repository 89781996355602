import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import styled from 'styled-components';
import IconAttention from '../../atoms/IconAttention';
import Pagination from '../../organisms/Pagination';
import ModalInfoMessage from '../../organisms/ModalInfoMessage';
import Button from '../../atoms/Button';
import FlexBox from '../../atoms/FlexBox';
import Search from '../../molecules/Search';
import alignSearchQuery from '../../_util/alignSearchQuery';

const StyledInfoItemDate = styled.dt`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  color: #b2b2b2;
  list-style: none;
  cursor:pointer;
`
const StyledInfoItemText = styled.dt`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  list-style: none;
  cursor:pointer;
`
const StyledLink = styled.a`
box-sizing: border-box;
display: block;
position: relative;
margin-top: 16px;
text-decoration: none;
`

class Information extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: [],
      data: [],
      offset: 0,
      postPerPage: 100,  // wp apiで100件取得しているので、それに合わせる
      searchQuery: '',
    };
    props.switchGlobalCatId(-1);
    props.switchContentTitle('インフォメーション一覧');
    props.toggleDatePicker(false);
  }
  componentDidMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    await this.getData();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getData() {
    // APIをリクエストしてデータを取得
    return axios.get('https://inf.mmerge.jp/wp-json/wp/v2/infos?per_page=100')
    .then((response) => {
      this.setState({
        dataOriginal: response.data,
        data: response.data,
      });
    })
    .catch(() => {
    });
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // ページャ条件による絞り込みにはArray.slice()を使う。
    //const arrangedDataTable = this.state.dataOriginal.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    // if(this.state.searchQuery !== ''){
    //   // 検索ワード入力があるなら
    //   // 元データを指定の条件でフィルタする
    //   const arrangedDataTable = this.state.dataOriginal.filter((item, index) => {
    //     const regexp = new RegExp(
    //       `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
    //     );

    //     return (
    //       (
    //         // フリーワード検索のクエリに合致するか
    //         this.state.searchQuery === ''
    //         || alignSearchQuery(item.title.rendered).match(regexp) !== null
    //       )
    //     )
    //   });
    //   this.setState({
    //     data: arrangedDataTable,
    //   });
    // }else{
    //   // 検索ワード入力がないなら、元からあるコードを実施
    //   const arrangedDataTable = this.state.dataOriginal.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    //   this.setState({
    //     data: arrangedDataTable,
    //   });
    // }

    // ページング機能をなくしたので、↓の処理でいけるはず
    // 元データを指定の条件でフィルタする
    const arrangedDataTable = this.state.dataOriginal.filter((item, index) => {
      const regexp = new RegExp(
        `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
      );

      return (
        (
          // フリーワード検索のクエリに合致するか
          this.state.searchQuery === ''
          || alignSearchQuery(item.title.rendered).match(regexp) !== null
        )
      )
    });
    this.setState({
      data: arrangedDataTable,
    });

  }
  render() {
    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.arrangeViewItems();
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.state.dataOriginal.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.arrangeViewItems();
    }

    // infoモーダルを開く
    const openInfoModal = (text) => {
      this.setState({
        isOpenedInfoModal: true,
        infoText: text
      });
    }
    // infoモーダルを閉じる
    const closeInfoModal = () => {
      this.setState({
        isOpenedInfoModal: false
      });
    }
    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        offset: 0,
        searchQuery: e.target['searchQuery'].value,
      });
      this.arrangeViewItems();
    }
    return (
      <div>
        <ModalInfoMessage
        heading = 'info'
        isOpened = { this.state.isOpenedInfoModal }
        close = { closeInfoModal }
        bodyText = { this.state.infoText }
        ></ModalInfoMessage>
        <FlexBox className="m-b-32">
          <Search
            name = "searchQuery"
            onSubmit = { setSearchQuery }
            value = { this.state.searchQuery }
          />
        </FlexBox>
        {
          (() => {
            return this.state.data.map(o => {
              if(o.acf.url !== ""){
                // リンク先があるならリンク表示にする
                return (
                  <React.Fragment key = { _.uniqueId() }>
                    <StyledInfoItemDate
                    onClick = {
                      (() => {
                        openInfoModal(o.content.rendered);
                      })
                    }
                    >
                      <IconAttention className="m-r-8" />
                      { o.acf.date }
                    </StyledInfoItemDate>
                    <StyledLink
                      href = {o.acf.url}
                      target = "_blank"
                    >
                      { o.title.rendered }
                    </StyledLink>
                  </React.Fragment>
                )
              }else{
                return (
                  <React.Fragment key = { _.uniqueId() }>
                    <StyledInfoItemDate
                    onClick = {
                      (() => {
                        openInfoModal(o.content.rendered);
                      })
                    }
                    >
                      <IconAttention className="m-r-8" />
                      { o.acf.date }
                    </StyledInfoItemDate>
                    <StyledInfoItemText
                    onClick = {
                      (() => {
                        openInfoModal(o.content.rendered);
                      })
                    }
                    >
                      { o.title.rendered }
                    </StyledInfoItemText>
                    {/* <Button
                    color="orange"
                    onClick = {
                      (() => {
                        openInfoModal(o.content.rendered);
                      })
                    }
                    className = 'm-l-8'
                    >
                    詳細
                    </Button> */}
                  </React.Fragment>
                )
              }

            })
          })()
        }
        {/* <Pagination
          offset = { this.state.offset }
          postPerPage = { this.state.postPerPage }
          dataCount = { this.state.dataOriginal.length }
          switchPostPerPage = { switchPostPerPage }
          goToPrevPagination = { goToPrevPagination }
          goToNextPagination = { goToNextPagination }
          className = 'm-t-24'
        /> */}
      </div>
    )
  }
}

export default Information;
