import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import axios from 'axios';

import Button from '../../../atoms/Button';
import BarBottom from '../../../atoms/BarBottom';
import StepNavi from '../../../molecules/StepNavi';
import HeadingS from '../../../atoms/HeadingS';
import TableDataCell from '../../../atoms/TableDataCell';
import TableBodyRow from '../../../atoms/TableBodyRow';
import TableHeader from '../../../atoms/TableHeader';
import TableHeaderCell from '../../../atoms/TableHeaderCell';
import InputText from '../../../atoms/InputText';
import PullDownArray from '../../../organisms/PullDownArray';
import ModalErrorMessage from '../../../organisms/ModalErrorMessage';

import User from '../../../../utils/user';

const FIXED_WIDTH = (100 + 160);
const FIXED_NUM = 2;
const TOTAL_NUM = 6;

const TABLE_HEADER_GA = [
  {
    id: 'sort',
    label: '画面表示の名称',
    width: '200px',
    hasSort: false,
  },
  {
    id: 'thirdPartyCvName',
    label: 'コンバージョン名を入力して下さい',
    width: `calc((100% - ${FIXED_WIDTH}px) / (${TOTAL_NUM} - ${FIXED_NUM}))`,
    hasSort: false,
  },
  {
    id: 'gaIndex',
    label: 'GAの指標名',//※GAによるデータ取得を行う場合のみ入力
    width: `calc((100% - ${FIXED_WIDTH}px) / (${TOTAL_NUM} - ${FIXED_NUM}))`,
    hasSort: false,
  },
  {
    id: 'gaUserType',
    label: 'GAの指標の種別',
    width: '160px',
    hasSort: false,
  },
];

const TABLE_HEADER = [
  {
    id: 'sort',
    label: '画面表示の名称',
    width: '200px',
    hasSort: false,
  },
  {
    id: 'thirdPartyCvName',
    label: 'コンバージョン名を入力して下さい',
    width: '500px',
    hasSort: false,
  },
];

const StyledTable = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  overflow: scroll;
  z-index: 1;
`;

const backendApi = process.env.REACT_APP_BACKEND_URI;
const gaData = process.env.REACT_APP_GA_DATA;

class ExternalDataEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sortId: '',
      sortedDataTable: [],
      sortOrder: 'desc',
      dataSetting: [],
      cvId: null,
      isErrorOpened: false,
      bodyText: '',
      addTable: [],
      addLength: 20,
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    if(this.props.clientId) {
      // 設定id　取得
      await axios.get(backendApi + 'thirdPartySetting', {
        params: {
          targetIds: this.props.clientId,
          ...User.apiParams(),
        }
      })
      .then((response) => {
        if(response.data.length > 0) {
          this.setState({
            cvId: response.data[0].id
          });
        }
      })
      .catch((error) => {
        this.setState({
          isErrorOpened: true,
          bodyText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
        })
        //　プログレス非表示
        this.props.endProgressing();
      })
      if(!this.state.isErrorOpened) {
        await axios.get(backendApi + 'thirdPartyMapping', {
          params: {
            id: this.state.cvId,
            ...User.apiParams(),
          }
        })
        .then((resMap) => {
          if(resMap.data) {
            let addTable = [];
            let addLength = 20 - resMap.data.length;
            if(addLength > 0) {
              if(this.props.isUsingGa) {
                addTable = [...Array(addLength).keys()].map(i => {
                  return {
                    thirdPartyDataSettingId: String(this.state.cvId),
                    sort: String(i + resMap.data.length + 1),
                    imcNo: String(i + resMap.data.length + 1),
                    thirdPartyCvName: '',
                    gaIndex: null,
                    gaUserType: null,
                    mediaCvName: ''
                  }
                });
              } else {
                addTable = [...Array(addLength).keys()].map(i => {
                  return {
                    thirdPartyDataSettingId: String(this.state.cvId),
                    sort: String(i + resMap.data.length + 1),
                    imcNo: String(i + resMap.data.length + 1),
                    thirdPartyCvName: '',
                    mediaCvName: '',
                  }
                });
              }
            }
            this.setState({
              data: resMap.data,
              sortedDataTable: resMap.data,
              addLength: addLength,
              addTable: addTable,
            });
          }
          if(!this.props.clientData.viewId) {
            //　プログレス非表示
            this.props.endProgressing();
          }
        })
        .catch((error) => {
          // エラーモーダルを開く
          this.setState({
            isErrorOpened: true,
            bodyText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
          })
          //　プログレス非表示
          this.props.endProgressing();
        })
      }
      if((!this.state.isErrorOpened) && this.props.clientData.viewId) {
        await axios.get( gaData, {
          params: {
            id: this.props.clientData.viewId,
          }
        })
        .then((resIndex) => {
          if(resIndex.data) {
            this.setState({
              dataSetting: resIndex.data.goalMetrics
            });
          }
          //　プログレス非表示
          this.props.endProgressing();
        })
        .catch((error) => {
          // エラーモーダルを開く
          this.setState({
            isErrorOpened: true,
            bodyText: error.response.data.errorMessage
          })
          //　プログレス非表示
          this.props.endProgressing();
        })
      }
    } else {
      // 表示データが取得できないため、TOP画面にリダイレクトする
      this.props.history.push('/setting/external-data');
      //　プログレス非表示
      this.props.endProgressing();
    }
  }

  componentDidUpdate = async (prevState) => {
    if(prevState.sortedDataTable) {
      if(prevState.sortedDataTable !== this.state.sortedDataTable) {
        await this.setState({
          sortedDataTable: this.state.data
        });
        this.arrangeViewItems();
      }
    }
  }

  handleToNextPage = async () => {
    // プログレス表示
    this.props.startProgressing();
    // データ保存
    const tableAll = this.state.sortedDataTable.concat(this.state.addTable);
    const table = _.filter(tableAll, 'thirdPartyCvName');
    await axios.get(backendApi + 'thirdPartyMappingModify', {
      params: {
        ...User.apiParams(),
        func: 1,
        id: this.state.cvId,
        dataCount: table.length,
        ...table,
      }
    })
    .then((response) => {
      //　プログレス非表示
      this.props.endProgressing();
      this.props.history.push('/setting/external-data/finish');
    })
    .catch((error) => {
      //　プログレス非表示
      this.props.endProgressing();
    })
  }

  handleToPrevPage = () => {
    this.props.history.goBack();
  }

  arrangeViewItems = () => {
    let sortedDataTable;
    sortedDataTable = _.orderBy(
      this.state.sortedDataTable,
      this.state.sortId,
      this.state.sortOrder
    );
    this.setState({
      sortedDataTable: sortedDataTable,
    });
  }

  render() {
    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    const switchGaIndex = (id, index) => {
      const arrayId = Array.from(this.state.sortedDataTable);
      arrayId[index].gaIndex = id;
      this.setState({
        sortedDataTable: arrayId
      });
    }

    const switchGaUserType = (id, index) => {
      const arrayId = Array.from(this.state.sortedDataTable);
      arrayId[index].gaUserType = id;
      this.setState({
        sortedDataTable: arrayId
      });
    }

    const switchAddGaIndex = (id, index) => {
      const arrayId = Array.from(this.state.addTable);
      arrayId[index].gaIndex = id;
      this.setState({
        addTable: arrayId
      });
    }

    const switchAddGaUserType = (id, index) => {
      const arrayId = Array.from(this.state.addTable);
      arrayId[index].gaUserType = id;
      this.setState({
        addTable: arrayId
      });
    }


    // エラーモーダル開閉
    const toggleErrorModal = (boolean) => {
      this.setState({
        isErrorOpened: boolean
      });
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isErrorOpened }
          close = { () => toggleErrorModal(false) }
          bodyText = { this.state.bodyText }
        />
        <div
          style = {{ display: `${this.props.isNewData ? 'block' : 'none'}` }}
          >
          <StepNavi
            step = { 3 }
            navi = {this.props.navi[`${this.props.isUsingGa ? 1 : 0}`]}
          />
        </div>
        <HeadingS
          className = 'm-t-16'
        >表示項目選択</HeadingS>
        <StyledTable
          windowHeight = { this.props.windowHeight }
          indexNum = { 15 }
        >
          <TableHeader>
            {
              (() => {
                let header = null;
                if(this.props.clientData.viewId) {
                  header = TABLE_HEADER_GA;
                } else {
                  header = TABLE_HEADER;
                }
                return header.map(o => {
                  return (
                    <TableHeaderCell
                      key = { _.uniqueId() }
                      style = { { width: `${o.width}` } }
                      sortState = {
                        (o.hasSort === false)
                          ? undefined
                          : (o.id !== this.state.sortId)
                            ? 0
                            : (this.state.sortOrder === 'asc')
                              ? 1
                              : 2
                      }
                      data-id = { o.id }
                      onClick = { (o.hasSort === true) ? sort : undefined }
                    >
                      { o.label }
                    </TableHeaderCell>
                  )
                })
              })()
            }
          </TableHeader>
        {
          (
            () => {
              if(this.state.sortedDataTable.length > 0) {
                return this.state.sortedDataTable.map((r, index) => {
                  return (
                    <TableBodyRow
                      key = { 'table_' + index }
                    >
                      {
                        (() => {
                          let header = null;
                          if(this.props.clientData.viewId) {
                            header = TABLE_HEADER_GA;
                          } else {
                            header = TABLE_HEADER;
                          }
                          return header.map((o, rIndex) => {
                            let cellKey = ['cell', index, rIndex];
                            return (
                              <TableDataCell
                                key = { cellKey.join('_') }
                                style = { { width: `${o.width}` } }
                              >
                                {
                                  (() => {
                                    switch(o.id) {
                                      case 'sort':
                                        return (
                                          '外部CV' + (index+1)
                                        );
                                      case 'gaIndex':
                                        return (
                                          <PullDownArray
                                            key = { 'gaIndex' + index }
                                            id = { this.state.sortedDataTable[index].gaIndex }
                                            items = { this.state.dataSetting.filter(o => o.label) }
                                            width = { '100%' }
                                            listWidth = { "150px" }
                                            onChange = { switchGaIndex }
                                            index = { index }
                                          />
                                        );
                                      case 'gaUserType':
                                        return (
                                          <PullDownArray
                                            key = { 'gaUserType' + index }
                                            id = { this.state.sortedDataTable[index].gaUserType }
                                            items = { [
                                              {id:'0', label:'全体'},
                                              {id:'1', label:'新規'},
                                              {id:'2', label:'既存'}
                                            ] }
                                            width = { '100%' }
                                            listWidth = { "150px" }
                                            onChange = { switchGaUserType }
                                            index = { index }
                                          />
                                        );
                                      default:
                                        return (
                                          <InputText
                                            key = { o.id + index }
                                            value = { this.state.sortedDataTable[index][o.id] }
                                            className = "m-r-8"
                                            style = {{ width : '100%' }}
                                            placeholder = "記入例：購入数、売上、登録、申込、資料請求 等"
                                            onChange = { (e) => {
                                              let arrayId = Array.from(this.state.sortedDataTable);
                                              arrayId[index][o.id] = e.currentTarget.value;
                                              this.setState({
                                                sortedDataTable: arrayId
                                              });
                                            } }
                                          />
                                        )
                                    }
                                  })()
                                }
                              </TableDataCell>
                            )
                          })
                        })()
                      }
                    </TableBodyRow>
                  )
                })
              }
            }
          )()
        }
        {
          (
            () => {
              if (20 - this.state.sortedDataTable.length > 0) {
                return this.state.addTable.map((r, index) => {
                     return (
                    <TableBodyRow
                      key = { 'add_' + index }
                    >
                      {
                        (() => {
                          let header = null;
                          if(this.props.clientData.viewId) {
                            header = TABLE_HEADER_GA;
                          } else {
                            header = TABLE_HEADER;
                          }
                          return header.map((o, i) => {
                            return (
                              <TableDataCell
                                key = { 'addCell' + o.id + '_' + index }
                                style = { { width: `${o.width}` } }
                              >
                                {
                                  (() => {
                                    switch(o.id) {
                                      case 'sort':
                                        return (
                                          '外部CV' + (index + 1 + this.state.sortedDataTable.length)
                                        );
                                      case 'gaIndex':
                                        return (
                                          <PullDownArray
                                            key = { 'addGaIndex' + index }
                                            id = { this.state.addTable[index].gaIndex }
                                            items = { this.state.dataSetting.filter(o => o.label) }
                                            width = { '100%' }
                                            listWidth = { "150px" }
                                            onChange = { switchAddGaIndex }
                                            index = { index }
                                          />
                                        );
                                      case 'gaUserType':
                                        return (
                                          <PullDownArray
                                            key = { 'addGaUserType' + index }
                                            id = { this.state.addTable[index].gaUserType }
                                            items = { [
                                              {id:'0', label:'全体'},
                                              {id:'1', label:'新規'},
                                              {id:'2', label:'既存'}
                                            ] }
                                            width = { '100%' }
                                            listWidth = { "150px" }
                                            onChange = { switchAddGaUserType }
                                            index = { index }
                                          />
                                        );
                                      default:
                                        let addKey = ['add', o.id, index];
                                        return (
                                          <InputText
                                            key = { addKey.join('_') }
                                            value = { this.state.addTable[index][o.id] }
                                            className = "m-r-8"
                                            style = {{ width : '100%' }}
                                            placeholder = "記入例：購入数、売上、登録、申込、資料請求 等"
                                            onChange = { (e) => {
                                              let arrayId = Array.from(this.state.addTable);
                                              arrayId[index][o.id] = e.currentTarget.value;
                                              this.setState({
                                                addTable: arrayId
                                              });
                                            } }
                                          />
                                        )
                                    }
                                  })()
                                }
                              </TableDataCell>
                            )
                          })
                        })()
                      }
                    </TableBodyRow>
                  )
                })
              }
            }
          )()
        }
        </StyledTable>
        <HeadingS
          className = 'm-t-32'
          >＜POINT＞
        </HeadingS>
          <p>こちらは進捗管理画面に表示する外部コンバージョンの名称を登録する画面です
          <br />
          ・飛ばして記載いただいた場合は詰めて保存されますのでご注意ください
          </p>
        <BarBottom>
          {
            (() => {
              return (
                <Button
                  onClick = { this.handleToPrevPage }
                >
                  前に戻る
                </Button>
              )
            })()
          }
          {
            (() => {
              // if (this.state.sortedDataTable.length > 0) {
              if (true) {
                return (
                  <Button
                    color="orange"
                    className = 'm-l-8'
                    onClick = { this.handleToNextPage }
                  >
                    保存する
                  </Button>
                )
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default ExternalDataEdit;
