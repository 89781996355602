export default function(v, type) {
  switch (type) {
    case 0: // 数値
      return v.toLocaleString();
    case 1: // 少数
      return `${v.toFixed(2)}`;
    case 2: // 通貨（￥）
      //return `\u00A5${v.toLocaleString()}-`;
      // 通貨の表示から￥マークを除去
      return v.toLocaleString();
    case 3: // 文字列
      return v;
    case 4: // 配列（文字列の配列）
      return v.join(', ');
    case 5: // 少数・割合（%）ゲージなし
      return `${v.toFixed(2)}%`;
    case 7: // 少数（小数点以下4位まで表示）
      return `${v.toFixed(4)}`;
    default:
      return v;
  }
}
