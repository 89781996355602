import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import FONTSIZE from '../_const/FONTSIZE'

import Dashboard          from '../views/Dashboard';
import ProgressClient     from '../views/progress/Client';
import ProgressAccount    from '../views/progress/Account';
import ProgressCampaign   from '../views/progress/Campaign';
import ProgressAdGroup    from '../views/progress/AdGroup';
import ProgressKeyword    from '../views/progress/Keyword';
import Label              from '../views/label/Label';
import LabelEdit          from '../views/label/LabelEdit';
import CustomReport       from '../views/customReport/CustomReport';
import CustomReportEdit   from '../views/customReport/CustomReportEdit';
import Budget             from '../views/setting/Budget';
import Alert              from '../views/setting/Alert';
import AlertEdit          from '../views/setting/AlertEdit';
import Schedule           from '../views/setting/Schedule';
import ScheduleSet           from '../views/setting/ScheduleSet';
import ExternalData       from '../views/setting/ExternalData';
// import ExternalDataClient from '../views/setting/ExternalDataClient';
// import ExternalDataUpload from '../views/setting/ExternalDataUpload';
import NewItem            from '../views/setting/NewItem';
import SmartPhone         from '../views/setting/SmartPhone';
import Activity           from '../views/setting/Activity';
import Authority          from '../views/setting/Authority';
import AuthorityUser      from '../views/setting/AuthorityUser';
import Tool               from '../views/tool/Tool';
import AlertNotification  from '../views/alertNotification/AlertNotification';
import Information        from '../views/information/Information';
import ReportSend         from '../views/tool/ReportSend';
import InvoiceSend        from '../views/tool/InvoiceSend';
import FileSend           from '../views/tool/FileSend';
import SeoCheck           from '../views/tool/SeoCheck';
import SnsCreative        from '../views/tool/SnsCreative';
import KeywordSearch      from '../views/tool/KeywordSearch';
import BudgetStop         from '../views/request/BudgetStop';
import Error404           from '../views/error/404';
import MediaCv            from '../views/setting/MediaCv';

const StyledContent = styled.main`
  position: relative;
  z-index: 10;
  margin-top: 104px;
  margin-right: 40px;
  margin-left: 200px;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: ${FONTSIZE.S}px;
  transition-duration: .2s;
  transition-property: margin;
  &.is-closed-sidemenu {
    margin-left: 90px;
  }
`

class Content extends React.Component {
  render() {
    return (
      <StyledContent
        className = { this.props.isOpenedSideMenu === true ? "is-opened-sidemenu" : "is-closed-sidemenu" }
      >
        <Switch>
          <Route
            exact
            path = '/'
            render = { (props) => (
              <Dashboard
                { ...props }
                { ...this.props }
              />
            )}
          />
          <Route
            exact
            path = '/progress/1/'
            render = { (props) => (
              <ProgressClient { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/progress/2/'
            render = { (props) => (
              <ProgressAccount { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/progress/3/'
            render = { (props) => (
              <ProgressCampaign { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/progress/4/'
            render = { (props) => (
              <ProgressAdGroup { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/progress/5/'
            render = { (props) => (
              <ProgressKeyword { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/label'
            render = { (props) => (
              <Label { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/label/edit'
            render = { (props) => (
              <LabelEdit { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/custom-report'
            render = { (props) => (
              <CustomReport { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/custom-report/edit'
            render = { (props) => (
              <CustomReportEdit { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/budget'
            render = { (props) => (
              <Budget { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/alert'
            render = { (props) => (
              <Alert { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/alert/edit'
            render = { (props) => (
              <AlertEdit { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/schedule'
            render = { (props) => (
              <Schedule { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/scheduleSet'
            render = { (props) => (
              <ScheduleSet { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/external-data'
            render = { (props) => (
              <ExternalData { ...props } { ...this.props } />
            )}
          />
          <Route
            path = '/setting/external-data/*'
            render = { (props) => (
              <ExternalData { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/new-item'
            render = { (props) => (
              <NewItem { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/smartphone'
            render = { (props) => (
              <SmartPhone { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/activity'
            render = { (props) => (
              <Activity { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/authority'
            render = { props => (
              <Authority { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/setting/authority/user'
            render = { props => (
              <AuthorityUser { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/tool'
            render = { props => (
              <Tool { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/alert-notification'
            render = { props => (
              <AlertNotification { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/information'
            render = { props => (
              <Information { ...props } { ...this.props } />
            )}
          />
          <Route
            exact
            path = '/tool/reportSend'
            render = { (props) => (
              <ReportSend
                { ...this.props }
              />
            )}
          />
          <Route
            exact
            path = '/tool/invoiceSend'
            render = { (props) => (
              <InvoiceSend
                { ...this.props }
              />
            )}
          />
          <Route
            exact
            path = '/tool/fileSend'
            render = { (props) => (
              <FileSend
                { ...this.props }
              />
            )}
          />
          <Route
            exact
            path = '/tool/seoCheck'
            render = { (props) => (
              <SeoCheck
                { ...this.props }
              />
            )}
          />
          <Route
            exact
            path = '/tool/snsCreative'
            render = { (props) => (
              <SnsCreative
                { ...this.props }
              />
            )}
          />
          <Route
            exact
            path = '/tool/keywordSearch'
            render = { (props) => (
              <KeywordSearch
                { ...this.props }
              />
            )}
          />
          <Route
            exact
            path = '/request/budgetStop'
            render = { (props) => (
              <BudgetStop
                { ...this.props }
              />
            )}
          />
          <Route
            exact
            path = '/login'
            render = { () => (
              <Redirect
                to = '/'
              />
            ) }
          />
          <Route
            exact
            path = '/setting/mediaCv'
            render = { (props) => (
              <MediaCv { ...props } { ...this.props } />
            )}
          />
          <Route
            component = { Error404 }
          />
        </Switch>
      </StyledContent>
    )
  }
};

export default Content;
