import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import classnames from 'classnames';
import queryString from 'query-string';
import InputText from '../../atoms/InputText';
import HeadingS from '../../atoms/HeadingS';

import Button from '../../atoms/Button';
import Checkbox from '../../atoms/Checkbox';
import FlexBox from '../../atoms/FlexBox';
import TableDataCell from '../../atoms/TableDataCell';
import TableBodyRow from '../../atoms/TableBodyRow';
import TableHeader from '../../atoms/TableHeader';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import BarConfirmData from '../../molecules/BarConfirmData';
import ModalErrorMessage from '../../organisms/ModalErrorMessage';

import User from '../../../utils/user';

const TABLE_HEADER = [
  {
    id: 'clientName',
    label: 'クライアント名',
    width: 'calc((100% - 320px) / 5)',
    hasSort: false,
  },
  {
    id: 'accountName',
    label: 'アカウント名',
    width: 'calc((100% - 320px) / 5)',
    hasSort: false,
  },
  {
    id: 'media',
    label: '媒体',
    width: '100px',
    hasSort: false,
  },
  {
    id: 'mediaId',
    label: '媒体ID',
    width: 'calc((100% - 320px) / 5)',
    hasSort: false,
  },
  {
    id: 'budget',
    label: '変更前予算',
    width: 'calc((100% - 320px) / 5)',
    hasSort: false,
  },
  {
    id: 'editBudget',
    label: '変更後予算',
    width: 'calc((100% - 320px) / 5)',
    hasSort: false,
  },
  {
    id: 'startDate',
    label: '開始日',
    width: '200px',
    hasSort: false,
  },
];

const TABLE_HEADER_CONFIRM = [
  {
    id: 'clientName',
    label: 'クライアント名',
    width: 'calc((100% - 320px) / 6)',
    hasSort: false,
  },
  {
    id: 'accountName',
    label: 'アカウント名',
    width: 'calc((100% - 320px) / 6)',
    hasSort: false,
  },
  {
    id: 'media',
    label: '媒体',
    width: '100px',
    hasSort: false,
  },
  {
    id: 'mediaId',
    label: '媒体ID',
    width: 'calc((100% - 320px) / 6)',
    hasSort: false,
  },
  {
    id: 'editBudget',
    label: '変更後予算',
    width: 'calc((100% - 320px) / 6)',
    hasSort: false,
  },
  {
    id: 'startDate',
    label: '開始日',
    width: '200px',
    hasSort: false,
  },
  {
    id: 'errorInfo',
    label: 'エラー',
    width: 'calc((100% - 320px) / 6)',
    hasSort: false,
  },
  {
    id: 'alertInfo',
    label: 'アラート',
    width: 'calc((100% - 320px) / 6)',
    hasSort: false,
  },
];

const StyledTable = styled.div`
  width: 100%;
  // max-height: ${ props => props.windowHeight - 120 - 106 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

class Budget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: [],
      dataFiltered: [],
      data: [],
      modifyData: [], // 予算変更データを格納する配列
      isError: false, // 変更する予算でエラーがあるか
      monthSelectValue: '', // 選択されたプルダウンのValue
      offset: 0,
      postPerPage: 5000,
      searchQuery: '',
      myAlert: 1,
      checked: [],
      targetMonth: [], // 対象年月プルダウン用
      targetIds: '',
      target: '',
      isOpenedErrorModal: false,
      errorText: '',
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('媒体予算管理');
    props.toggleDatePicker(false);
  }
  componentDidMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    await this.getData();
    this.arrangeViewItems();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getData = () => {
    // URIのクエリパラメータにclientId,accountIdが存在するかどうかを判断し、
    // 存在する場合は情報を取得してsetStateする。
    const queries = queryString.parse(window.location.search);

    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'mediaBudget', {
      params: {
        ...User.apiParams(),
        targetIds: queries.targetIds,
        target: queries.target,
      }
    })
    .then((response) => {
      this.setState({
        dataOriginal: response.data.mediaBudget,
        dataFiltered: response.data.mediaBudget,
        data: response.data.mediaBudget,
        modifyData: [],
        isError: false,
        monthSelectValue: response.data.selectMonth,
        targetMonth: response.data.targetMonth,
        targetIds: queries.targetIds,
        target: queries.target,
      });
    })
    .catch((error) => {
      this.setState({
        isOpenedErrorModal: true,
        errorText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
      });
    });
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 元データを指定の条件でフィルタする
    const filteredData = this.state.dataOriginal.filter((item, index) => {
      return (
        (
          // フリーワード検索のクエリに合致するか
          this.state.searchQuery === ''
          || item.name.indexOf(this.state.searchQuery) > -1
        )
      )
    });

    // データのソートには_.orderBy()を使う。
    const sortedDataTable = _.orderBy(
      filteredData,
      o => {
        return o[this.state.sortId];
      },
      this.state.sortOrder
    );

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      dataFiltered: filteredData,
      data: arrangedDataTable,
    });
  }
  render() {


    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    // 各行のチェック
    const checkItem = (e) => {
      const id = e.currentTarget.value;
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        checked.splice(checked.indexOf(id), 1)
      } else {
        checked.push(id);
      }
      this.setState({
        checked: checked
      });
    }

    // 同じ行のチェックボックスにチェックを付ける
    const checkboxChecked = (id) => {
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        // 既にあるなら何もしない
      } else {
        // ないなら追加
        checked.push(id);
      }
      this.setState({
        checked: checked
      });
    }

    // 対象年月の選択変更
    const switchTargetMonth = async (e) => {
      // プログレス表示
      this.props.startProgressing();

      await axios.get(backendApi + 'mediaBudget', {
        params: {
          ...User.apiParams(),
          targetIds: this.state.targetIds,
          target: this.state.target,
          targetMonth: e.target.value,
        }
      })
      .then((response) => {
        this.setState({
          dataOriginal: response.data.mediaBudget,
          dataFiltered: response.data.mediaBudget,
          data: response.data.mediaBudget,
          modifyData: [],
          targetMonth: response.data.targetMonth,
          monthSelectValue:response.data.selectMonth,
        });
      })
      .catch(() => {
      });

      //　プログレス非表示
      this.props.endProgressing();
    }

    // 確認処理
    const confirm = async () => {
      // 変更対象データを一度初期化
      this.setState({
        modifyData: [],
      });

      // チェックがついているデータをmodifyDataへ格納する
      let modifyDataList = [];
      let isErr = false;
      for (var i = 0; i < this.state.data.length; i++) {
        if(this.state.checked.indexOf(this.state.data[i].mediaId) > -1){
          // エラー・アラートもチェックを行う
          let selectMonth = this.state.monthSelectValue;
          // エラーチェック
          let errorInfo = '';
          // 予算が空ならアラート
          if(!this.state.data[i].editBudget){
            errorInfo += '変更後予算を記入してください。';
          }else{
            let budgetVal = this.state.data[i].editBudget;
            if(budgetVal !== "無制限" && !budgetVal.match(/^[0-9]+$/)){
              errorInfo += "予算には半角数字もしくは無制限を入力してください";
            }else{
              // 1000円未満の単位を設定していないか
              if(budgetVal !== "無制限"){
                if(this.state.data[i].media.indexOf('Google') === -1){
                  // SS,YDNのみチェック(1000で割り切れるか)
                  if(budgetVal % 1000 !== 0){
                    errorInfo += "予算には1000円以上の単位を設定してください";
                  }
                }
              }
            }
          }

          if(this.state.data[i].media.indexOf('Google') !== -1){
            // Googleの場合
            if(!this.state.data[i].startDate){
              errorInfo += '開始日を記入してください。';
            }else{
              let dt1 = new Date(selectMonth.substring(0,4), selectMonth.substring(5,7) - 1, 1);
              let dt2 = new Date(this.state.data[i].startDate.substring(0,4), this.state.data[i].startDate.substring(5,7) - 1, 1);

              // 開始日が設定対象月と同じでないならならアラート
              if(dt1.getTime() !== dt2.getTime()) {
                errorInfo += '予算開始日には対象月の日付を設定してください。';
              }
            }
          }

          let alertInfo = '';
          let budgetVal = parseInt(this.state.data[i].editBudget);
          let previousBudgetVal = parseInt(this.state.data[i].budget);

          if( (budgetVal >= (previousBudgetVal + 1000000)) || (budgetVal <= (previousBudgetVal - 1000000)) ){
            alertInfo += "前月予算と100万円以上乖離がありますが問題ありませんか？";
          }
          // 変更後が変更前より小さいなら
          if(previousBudgetVal > budgetVal){
            alertInfo += "変更後予算が変更前より少ない額になっていますが問題ありませんか？";
          }

          if(this.state.data[i].media.indexOf('Google') > -1){
            // Googleの場合
            // 変更前予算がない（=予算新規追加の）かつ開始日が入力されている場合、予算開始日が翌月以降かどうか
            if(this.state.data[i].budget === 0 && this.state.data[i].startDate){
              let date = new Date();
              let year  = date.getFullYear();
              let month = date.getMonth() + 1;
              let day   = date.getDate();
              let tomorrow = String(year) + "-" + String(month) + "-" + String(day);
              let date_tomorrow = new Date(Number(tomorrow.substring(0,4)), Number(tomorrow.substring(5,7)) - 1 ,Number(tomorrow.substring(8,10)));
              let date_startDate = new Date(Number(this.state.data[i].startDate.substring(0,4)), Number(this.state.data[i].startDate.substring(5,7)) - 1 ,Number(this.state.data[i].startDate.substring(8,10)));
              if(date_startDate.getTime() < date_tomorrow.getTime()){
                alertInfo += "予算の開始日が過ぎていますが、よろしいですか？設定エラーとなるため、どうしても本日中に稼働させたい場合でなければ明日以降にしてください。";
              }
            }
          }

          if(errorInfo !== '') isErr = true;

          this.state.data[i].errorInfo = errorInfo;
          this.state.data[i].alertInfo = alertInfo;
          modifyDataList.push(this.state.data[i])
        }
      }

      this.setState({
        modifyData: modifyDataList,
        isError: isErr,
      });
    }

    // 予算変更処理
    const modify = async () => {
      if(this.state.isError){
        this.setState({
          isOpenedErrorModal: true,
          errorText: 'エラーを修正してから確定してください',
        });
      }else{
        // プログレス表示
        this.props.startProgressing();

        let modifyDataList = [];
        for (var i = 0; i < this.state.modifyData.length; i++) {
            let modifyParam = {
              clientName: this.state.modifyData[i].clientName,
              accountName: this.state.modifyData[i].accountName,
              media: this.state.modifyData[i].media,
              mediaId:  this.state.modifyData[i].mediaId,
              budget:  this.state.modifyData[i].editBudget,
              startDate:  this.state.modifyData[i].startDate,
            };
            modifyDataList.push(modifyParam);
        }
        await axios.get(backendApi + 'mediaBudgetModify', {
          params: {
            func: 1,
            ...User.apiParams(),
            ...modifyDataList,
            dataCount: modifyDataList.length,
          }
        })
        .then((response) => {
          this.setState({
            dataOriginal: response.data.mediaBudget,
            dataFiltered: response.data.mediaBudget,
            data: response.data.mediaBudget,
            modifyData: [],
            checked: [],
            isError: false,
            monthSelectValue: response.data.selectMonth,
            targetMonth: response.data.targetMonth,
          });

          //　プログレス非表示
          this.props.endProgressing();

          // 確認メッセージを出す
          alert("予算変更を受理しました。");
          // 一覧へ戻る
          //returnList();
        });
      }

    }
    // 一覧へ戻る処理
    const returnList = async () => {
      this.setState({
        modifyData: []
      });
    }

    // エラーモーダルを閉じる
    const closeErrorModal = () => {
      this.setState({
        isOpenedErrorModal: false
      });
    }

    if(this.state.modifyData.length > 0){
      // 確認画面
      return (
        <div>
          <ModalErrorMessage
            heading = 'エラー'
            isOpened = { this.state.isOpenedErrorModal }
            close = { closeErrorModal }
            bodyText = { this.state.errorText }
          ></ModalErrorMessage>
          <FlexBox className="m-b-32" justify="space-between">
            <FlexBox>
              <Button
                color="orange"
                onClick = { modify }
                className = 'm-l-8'
              >
                確定
              </Button>
              <Button
                onClick = { returnList }
                className = 'm-l-8'
              >
                戻る
              </Button>
            </FlexBox>

          </FlexBox>

          <StyledTable
            windowHeight = { this.props.windowHeight }
          >
            <TableHeader>
              {
                (() => {
                  return TABLE_HEADER_CONFIRM.map(o => {
                    return (
                      <TableHeaderCell
                        key = { _.uniqueId() }
                        style = { { width: `${o.width}` } }
                        sortState = {
                          (o.hasSort === false)
                            ? undefined
                            : (o.id !== this.state.sortId)
                              ? 0
                              : (this.state.sortOrder === 'asc')
                                ? 1
                                : 2
                        }
                        data-id = { o.id }
                        onClick = { sort }
                      >
                        { o.label }
                      </TableHeaderCell>
                    )
                  })
                })()
              }
            </TableHeader>
            <div>
              {
                (() => {
                  return this.state.modifyData.map((r, i) => {
                    return (
                      <TableBodyRow
                        key = { `modifyBugetTableRow_${i}` }
                      >
                        <TableDataCell
                          className = { 'has-checkbox' }
                        >
                          <Checkbox
                            value = { r.mediaId }
                            onClick = { checkItem }
                            isChecked = { this.state.checked.indexOf(r.mediaId) > -1 }
                          />
                        </TableDataCell>
                        {
                          (() => {
                            return TABLE_HEADER_CONFIRM.map(o => {
                              const label = (o.id === 'category') ? r[o.id].label : r[o.id];

                              return (
                                <TableDataCell
                                  key = { _.uniqueId() }
                                  style = { { width: `${o.width}` } }
                                  // className = {
                                  //   classnames({
                                  //     'jc-c': o.id === 'editBudget' || o.id === 'startDate'
                                  //   })
                                  // }
                                >
                                  {
                                    (() => {
                                      return label;
                                    })()
                                  }
                                </TableDataCell>
                              )
                            })
                          })()
                        }

                      </TableBodyRow>
                    )
                  })
                })()
              }
            </div>
          </StyledTable>
        </div>
      )
    }else{
      // 一覧画面
      return (
        <div>
          <ModalErrorMessage
            heading = 'エラー'
            isOpened = { this.state.isOpenedErrorModal }
            close = { closeErrorModal }
            bodyText = { this.state.errorText }
          ></ModalErrorMessage>
          <HeadingS>
            <p>請求の〆日を変更したい場合はアカウントの個別設定が必要なのでマーケ部までご相談ください</p>
            <p>翌月分の予算の変更は翌月に入ってから行ってください</p>
            <p>Googleの場合、終了日は開始日の月末日が設定されます(月途中〆切の場合を除いて)</p>
            <p>※※管理費抜きで入力※※</p>
          </HeadingS>

          <FlexBox className="m-b-32" justify="space-between">
            <FlexBox>
              対象年月
              <select id="monthSelect" onChange = { switchTargetMonth } value={this.state.monthSelectValue}>
              {
                (() => {
                  return this.state.targetMonth.map(item => {
                    return (
                      <option
                        className = 'm-r-24'
                        key = { _.uniqueId() }
                        id = { String(item.id) }
                        value = { String(item.id) }
                        selected = { item.selected ? 'selected' : '' }
                      >
                        { item.label }
                      </option>
                    )
                  });
                })()
              }
              </select>
            </FlexBox>

          </FlexBox>

          <BarConfirmData
            count = { this.state.checked.length }
            onClickConfirm = { confirm }
          />
          <StyledTable
            windowHeight = { this.props.windowHeight }
          >
            <TableHeader>
              <TableHeaderCell
                className = { 'has-checkbox' }
              />
              {
                (() => {
                  return TABLE_HEADER.map(o => {
                    return (
                      <TableHeaderCell
                        key = { _.uniqueId() }
                        style = { { width: `${o.width}` } }
                        sortState = {
                          (o.hasSort === false)
                            ? undefined
                            : (o.id !== this.state.sortId)
                              ? 0
                              : (this.state.sortOrder === 'asc')
                                ? 1
                                : 2
                        }
                        data-id = { o.id }
                        onClick = { sort }
                      >
                        { o.label }
                      </TableHeaderCell>
                    )
                  })
                })()
              }
            </TableHeader>
            <div>
              {
                (() => {
                  return this.state.data.map((r, i) => {
                    return (
                      <TableBodyRow
                        // key = { _.uniqueId() }
                        key = { `bugetTableRow_${i}` }
                      >
                        <TableDataCell
                          className = { 'has-checkbox' }
                        >
                          <Checkbox
                            value = { r.mediaId }
                            onClick = { checkItem }
                            isChecked = { this.state.checked.indexOf(r.mediaId) > -1 }
                          />
                        </TableDataCell>
                        {
                          (() => {
                            return TABLE_HEADER.map(o => {
                              const label = (o.id === 'category') ? r[o.id].label : r[o.id];
                              return (
                                <TableDataCell
                                  style = { { width: `${o.width}` } }
                                  className = {
                                    classnames({
                                      'jc-c': o.id === 'editBudget' || o.id === 'startDate'
                                    })
                                  }
                                >
                                  {
                                    (() => {
                                      if (o.id === 'editBudget') {
                                        return (
                                          <FlexBox>
                                          <InputText
                                            type = "number"
                                            value = { this.state.data[i].editBudget }
                                            className = "m-r-8"
                                            onChange = { ((e) => {
                                              // let targetObject = _.find(this.state.data, function(o) { return o.mediaId === r.mediaId; });
                                              // targetObject.editBudget = e.currentTarget.value;
                                              let targetObject = this.state.data;
                                              targetObject[i].editBudget = e.currentTarget.value;
                                              checkboxChecked(r.mediaId);

                                              this.setState({
                                                data: targetObject,
                                              });
                                              //r.editBudget = e.currentTarget.value;
                                            }) }
                                          />
                                          円
                                        </FlexBox>
                                        )
                                      } else if (o.id === 'startDate' && r.media.indexOf('Google') !== -1) {
                                        return (
                                          <FlexBox>
                                          <InputText
                                            type = "date"
                                            value = { r.startDate }
                                            className = "m-r-8"
                                            disabled = { r.media.indexOf('Google') === -1  ? 'disabled' : '' }
                                            //readonly = { r.media.indexOf('Google') === -1  ? 'readonly' : '' }
                                            onChange = { ((e) => {
                                              this.setState({
                                                requestParam: {
                                                  ...this.state.requestParam,
                                                  startDate: e.currentTarget.value,
                                                }
                                              });
                                              r.startDate = e.currentTarget.value;
                                            }) }
                                          />
                                        </FlexBox>
                                        )
                                      } else if(o.id === 'budget'){
                                        // 変更前予算の表示形式を通貨にする
                                        return `\u00A5${r.budget.toLocaleString()}`;
                                      } else {
                                        return label;
                                      }
                                    })()
                                  }
                                </TableDataCell>
                              )
                            })
                          })()
                        }

                      </TableBodyRow>
                    )
                  })
                })()
              }
            </div>
          </StyledTable>
        </div>
      )
    }



  }
}

export default Budget;
