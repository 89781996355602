import styled from 'styled-components'

const TableSimple = styled.table`
  max-width: 1000px;
  box-sizing: border-box;
  border-collapse: collapse;
  th {
    text-align: left;
  }
  td {
    text-align: center;
  }
  th, td {
    padding: 8px;
    box-sizing: border-box;
    border: solid 1px #AAA;
  }
  thead {
    background: black;
    color: white;
    box-sizing: border-box;
    th {
      text-align: center;
      width: ${props => ((1000 - props.widthTh) / (props.colNum - 1))}px;
    }
    th:first-child {
      width: ${props => props.widthTh}px;
    }
  }
`;

export default TableSimple;
