import React from 'react';
import styled from 'styled-components'
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import queryString from 'query-string';

import REPORT_TARGETS from '../../_const/REPORT_TARGETS'

import BarBottom from '../../atoms/BarBottom';
import Button from '../../atoms/Button';
import DividerContent from '../../atoms/DividerContent';
import FlexBox from '../../atoms/FlexBox';
import HeadingS from '../../atoms/HeadingS';
import InputText from '../../atoms/InputText';
import Radio from '../../atoms/Radio';
import Textarea from '../../atoms/Textarea';
import PullDownCommon from '../../organisms/PullDownCommon';
import SelectDispItems from '../../organisms/SelectDispItems';
import SelectTargetItems from '../../organisms/SelectTargetItems';
import SelectUsers from '../../organisms/SelectUsers';
import TitleEdit from '../../organisms/TitleEdit';

import User from '../../../utils/user';
import AlertEdit from '../setting/AlertEdit';

const BLANK_TITLE_LABEL = '無題ラベル';

const StyledProgressSection = styled.div`
  display: none;
  &.is-shown {
    display: block;
  }
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

class LabelEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targetId: 0,
      targetData: [],
      alertData: [],
      checkedTargets: [],
      checkedDispItems: [],
      checkedUsers: [],
      requestParam: {
        id: 0, // ラベルデータのID 1 ※新規追加の場合は0もしくは空
        name: '',
        clientIds:      null, //クライアントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        accountIds:     null, //アカウントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        campaignIds:    null, //キャンペーンID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        adgroupIds:     null, //広告グループID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        keywordIds:     null, //キーワードID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        target:         0,    // ラベル設定対象とするレポートの種類 1:クライアント、2:アカウント、3:キャンペーン、4:広告グループ、5:キーワード
        budget:         0, // 設定予算
        alertSettingId: 0, // 設定したアラート情報ID	アラート情報を設定するなら該当するアラート情報のIDをセット。設定しない場合は0
        dispItemData:   '', // 各表示項目の設定を記載。表示項目が複数ある場合は配列（,区切り） （例）imp,click,cv,CTR
        note:           '', // 備考
        shareSetting:   0, // 公開設定 0:自身、1:グループ、2:部署、3:全社
      },
      dataType:0,
    };
    props.switchGlobalCatId(3);
    props.switchContentTitle('ラベル編集');
    props.toggleDatePicker(false);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    // データを取得
    await this.getExistingData();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getExistingData = async () => {
    // URIのクエリパラメータにidが存在するかどうかを判断し、
    // 存在する場合は既存カスタムレポートの情報を取得してsetStateする。
    const queries = queryString.parse(window.location.search);

    if (
      // idが存在する場合はAPIを実行
      queries.id !== undefined
    ) {
      let labelData = [];
      await axios.get(backendApi + 'label', {
        params: {
          ...User.apiParams(),
          id: parseInt(queries.id),
        }
      })
      .then((response) => {
        if (response.data.length > 0) labelData = response.data[0];
        if (labelData.name === BLANK_TITLE_LABEL) labelData.name = '';
      })
      .catch(() => {
      });

      // responseのdata配列が0件の場合はreturn
      if (labelData.length === 0) {
        return;
      };

      let targetId = labelData.target;
      let checkedTargets = '';
      if (targetId === 1) {
        // client
        checkedTargets = labelData.clientIds;
      } else if (targetId === 2) {
        // account
        checkedTargets = labelData.accountIds;
      } else if (targetId === 3) {
        // campaign
        checkedTargets = labelData.campaignIds;
      } else if (targetId === 4) {
        // adgroup
        checkedTargets = labelData.adgroupIds;
      } else if (targetId === 5) {
        // keyword
        checkedTargets = labelData.keywordIds;
      }
      if (checkedTargets !== '') {
        checkedTargets = checkedTargets.split(',').map(o => {
          return parseInt(o);
        })
      } else {
        checkedTargets = [];
      }

      let checkedUsers = [];
      if (labelData.userIds) {
        checkedUsers = labelData.userIds.split(',').map(o => {
          return o;
        })
      }

      await this.setState({
        targetId: targetId,
        checkedTargets: checkedTargets,
        checkedDispItems: labelData.dispItem,
        checkedUsers: checkedUsers,
        requestParam: {
          ...this.state.requestParam,
          ...labelData,
        },
      });
    } else if (
      // target/targetIdsがいずれも存在する場合は
      queries.target !== undefined
      && queries.targetIds !== undefined
    ) {
      await this.setState({
        targetId: parseInt(queries.target),
        checkedTargets: queries.targetIds.split(',').map(n => { return parseInt(n) }),
      })
    } else {
      // id/target/targetIdsがいずれも存在しない場合はreturn
      return;
    }

    await Promise.all([
      this.getTargetData(),
      this.getAlertData(),
    ]);

    return;
  }
  getTargetData = () => {
    // 各階層の要素一覧の取得
    let apiName = "report";
    // if(this.state.dataType === 1){
    //   apiName = "reportTarget";
    // }
    return axios.get(backendApi + apiName, {
      params: {
        ...User.apiParams(),
        target: this.state.targetId,
        timeSpan: 8,
        dataExist: 1,
      }
    })
    .then((response) => {
      this.setState({
        targetData: response.data.reportJsonList,
      });
    })
    .catch(() => {
    });
  }
  getAlertData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'alert', {
      params: {
        ...User.apiParams(),
        myAlert: 1,
      }
    })
    .then((response) => {
      this.setState({
        alertData: [
          {
            id: 0,
            label: '選択しない',
          },
          ...response.data.map((item) => {
            return {
              id: item.id,
              label: item.name,
            }
          })
        ],
      });
    })
    .catch(() => {
    });
  }
  render() {
    // タイトル編集
    const editTitle = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          name: e.currentTarget.value,
        },
      });
    }

    // targetの階層を選択
    const switchTargetId = async (e) => {
      // プログレス表示
      this.props.startProgressing();

      await this.setState({
        targetId: parseInt(e.target.value),
        checkedTargets: [],
      });
      await Promise.all([
        this.getTargetData(),
        this.getAlertData(),
      ]);

      //　プログレス非表示
      this.props.endProgressing();
    }

    // targetの各要素をチェック
    const checkTargetItem = (e) => {
      const id = parseInt(e.target.value);
      const { checkedTargets } = this.state;

      if (checkedTargets.indexOf(id) > -1) {
        checkedTargets.splice(checkedTargets.indexOf(id), 1)
      } else {
        checkedTargets.push(id);
      }
      this.setState({
        checkedTargets: checkedTargets
      });
    }

    // targetのチェックボックスをすべて選択
    const toggleCheckTargetsAll = (checkedFiltered, bool) => {
      const { checkedTargets } = this.state;

      if (bool) {
        this.setState({
          checkedTargets: _.union(
            checkedTargets,
            checkedFiltered
          )
        });
      } else {
        this.setState({
          checkedTargets: _.without(
            checkedTargets,
            ...checkedFiltered
          )
        });
      }
    }

    // targetのチェックボックスをすべてクリア
    const clearCheckTargetAll = () => {
      this.setState({
        checkedTargets: []
      })
    }

    // アラートを選択
    const switchAlertId = async (id) => {
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          alertSettingId: id
        }
      });
    }

    // メモの編集
    const editNote = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          note: e.target.value,
        }
      });
    }

    // 公開範囲のラジオボタンを制御
    const switchShareSetting = async (e) => {
      const id = parseInt(e.target.value);

      this.setState({
        checkedUsers: (id === 4) ? this.state.checkedUsers : [],
        requestParam: {
          ...this.state.requestParam,
          shareSetting: id,
        }
      });
    }

    // ユーザーの各要素をチェック
    const checkUser = (e) => {
      const id = e.target.value;
      const { checkedUsers } = this.state;

      if (checkedUsers.indexOf(id) > -1) {
        checkedUsers.splice(checkedUsers.indexOf(id), 1)
      } else {
        checkedUsers.push(id);
      }
      this.setState({
        checkedUsers: checkedUsers,
      });
    }

    // ユーザーのチェックボックスをすべて選択
    const toggleCheckUsersAll = (checkedFiltered, bool) => {
      const { checkedUsers } = this.state;

      if (bool) {
        this.setState({
          checkedUsers: _.union(
            checkedUsers,
            checkedFiltered
          )
        });
      } else {
        this.setState({
          checkedUsers: _.without(
            checkedUsers,
            ...checkedFiltered
          )
        });
      }
    }

    // ユーザーのチェックボックスをすべてクリア
    const clearCheckUsersAll = () => {
      this.setState({
        checkedUsers: []
      })
    }

    // ラベルを保存する。
    const save = () => {
      // プログレス表示
      this.props.startProgressing();

      // 選択したIDを含むデータを抽出
      let keyname = '';
      switch(this.state.targetId) {
        case 1:
          keyname = 'clientId';
          break;
        case 2:
          keyname = 'accountId';
          break;
        case 3:
          keyname = 'campaignId';
          break;
        case 4:
          keyname = 'adgroupId';
          break;
        case 5:
          keyname = 'keywordId';
          break;
        default:
      }
      // let checkedData = this.state.checkedTargets.map(x =>
      //   _.find(this.state.targetData, (o) => {
      //     //alert(o[keyname]);
      //     //alert(x);
      //     return o[keyname] === parseInt(x);
      //   })
      // );

      let checkedDataFilter = []; // チェック対象かつ取得したデータの中にあるIDを保持する変数
      let checkedData = this.state.checkedTargets.map(x =>
        _.find(this.state.targetData, (o) => {
          if(o[keyname] === parseInt(x)){
            // チェックされたIDかつ取得データにあるデータのみ保持する（チェックされたIDでも取得データにない場合エラーになるので ※登録時は取得データにあったが、変更時にはないパターンもあるため）
            // オブジェクトなのでtargetデータのオブジェクトであるoを入れる必要がある
            checkedDataFilter.push(o);
          }
        })
      );

      axios.get(backendApi + 'labelModify', {
        params: {
          ...User.apiParams(),
          ...this.state.requestParam,
          func        : 1,
          name        : (this.state.requestParam.name === '') ? BLANK_TITLE_LABEL : this.state.requestParam.name,
          clientIds   : _.uniq(checkedDataFilter.map(x => x.clientId)).join(),
          accountIds  : (this.state.targetId > 1) ? _.uniq(checkedDataFilter.map(x => x.accountId)).join() : null,
          campaignIds : (this.state.targetId > 2) ? _.uniq(checkedDataFilter.map(x => x.campaignId)).join() : null,
          adgroupIds  : (this.state.targetId > 3) ? _.uniq(checkedDataFilter.map(x => x.adgroupId)).join() : null,
          keywordIds  : (this.state.targetId > 4) ? _.uniq(checkedDataFilter.map(x => x.keywordId)).join() : null,
          target      : this.state.targetId,
          dispItemData: this.state.checkedDispItems.map(o => { return o.id }).join(','),
          userIds     : this.state.checkedUsers.join(','),
        }
      })
      .then((response) => {
        this.props.history.push('/label/');

        //　プログレス非表示
        this.props.endProgressing();
      });
    }

    return (
      <div>
        <TitleEdit
          name = 'labelTitle'
          value = { this.state.requestParam.name }
          blankLabel = { BLANK_TITLE_LABEL }
          placeholder = 'ラベル名を入力してください。'
          onChange = { editTitle }
        />

        {/* <HeadingS>
          データ対象を選んでください　
        </HeadingS>
        <FlexBox
          className = 'm-t-16'
        >
          {
            (() => {
              const items = [
              'アクティブのみ',
              'すべて',
              ];
              return items.map((item, index) => {
                return (
                  <Radio
                    key = { _.uniqueId() }
                    name = "dataType"
                    id = { `dataType-${index}` }
                    value = { index }
                    checked = {  this.state.dataType === index }
                    onChange = { (() => {
                      // if (
                      //   (this.state.targetId > 2 && index === 1)
                      //   || ((this.state.targetId < 2 || this.state.targetId > 3) && index === 6)
                      // ) {
                      //   // キャンペーン以下の階層を選択したあとに日分割を選択しなおした場合は各プロパティをリセットする
                      //   this.setState({
                      //     targetId: 0,
                      //     targetData: [],
                      //     checkedTargets: [],
                      //     checkedDispItems: defaultDispItems,
                      //     requestParam: {
                      //       ...this.state.requestParam,
                      //       division: index,
                      //     }
                      //   });
                      // } else {
                      //   this.setState({
                      //     requestParam: {
                      //       ...this.state.requestParam,
                      //       division: index,
                      //     }
                      //   })
                      // }
                      this.setState({
                        dataType: index,
                      })
                    }) }
                    className = 'm-r-16'
                  >
                    { item }
                  </Radio>
                )
              });
            })()
          }
        </FlexBox> */}

        <HeadingS>
          ラベルを設定するカテゴリーを選んでください　
        </HeadingS>
        <FlexBox>
          {
            (() => {
              return REPORT_TARGETS.map(item => {
                return (
                  <Radio
                    className = 'm-r-24'
                    key = { _.uniqueId() }
                    name = 'target-category'
                    id = { `target-category-${item.id}` }
                    value = { String(item.id) }
                    checked = { this.state.targetId === item.id ? 'checked' : '' }
                    onChange = { switchTargetId }
                  >
                    { item.label }
                  </Radio>
                )
              });
            })()
          }
        </FlexBox>

        <StyledProgressSection
          className = {
            classnames({
              'is-shown': this.state.targetId > 0
            })
          }
        >
          <HeadingS
            className = 'm-t-40'
          >
            ラベルを設定する対象を選んでください　
          </HeadingS>
          <SelectTargetItems
            targetId = { this.state.targetId }
            targetData = { this.state.targetData }
            checked = { this.state.checkedTargets }
            checkItem = { checkTargetItem }
            toggleCheckAll = { toggleCheckTargetsAll }
            clearCheckAll　= { clearCheckTargetAll }
          />

          <HeadingS
            className = 'm-t-40'
          >
            ラベルのヨミ予算を入力してください
          </HeadingS>

          <div
            className = 'm-t-8'
          >
            <InputText
              type = "number"
              value = { this.state.requestParam.budget }
              min = { 0 }
              step = { 1 }
              className = "m-r-8"
              onChange = { ((e) => {
                this.setState({
                  requestParam: {
                    ...this.state.requestParam,
                    budget: e.currentTarget.value,
                  }
                });
              }) }
            />
            円
          </div>

          <HeadingS
            className = 'm-t-40'
          >
            ラベルに設定したいアラートを選択してください
          </HeadingS>
          <FlexBox>
            <PullDownCommon
              id = { this.state.requestParam.alertSettingId }
              items = { this.state.alertData }
              listWidth = { '30em' }
              onChange = { switchAlertId }
            />
          </FlexBox>

          <HeadingS
            className = 'm-t-40'
          >
            ラベルに設定したい表示項目を選択してください
          </HeadingS>
          <SelectDispItems
            selectableDispItems = { this.props.selectableDispItems }
            checked = { this.state.checkedDispItems }
            hasBorder = { true }
            updateChecked = {
              ((checked) => {
                this.setState({
                  checkedDispItems: checked
                })
              })
            }
          />

          <HeadingS
            className = 'm-t-40'
          >
            メモを記入してください
            <small
              className = 'm-l-8'
            >
              (任意)
            </small>
          </HeadingS>
          <Textarea
            placeholder = "メモを記入してください。"
            value = { this.state.requestParam.note }
            onChange = { editNote }
          />

          <DividerContent />

          <HeadingS>
            公開範囲を指定してください。
          </HeadingS>
          <FlexBox>
            {
              (() => {
                const items = [
                  '自身',
                  'グループ',
                  '部署',
                  '全社',
                  '特定のユーザー',
                ]
                return items.map((o, i) => {
                  return (
                    <Radio
                      className = 'm-r-24'
                      key = { _.uniqueId() }
                      name = 'share-setting'
                      id = { `share-setting-${i}` }
                      value = { String(i) }
                      checked = { this.state.requestParam.shareSetting === i ? 'checked' : '' }
                      onChange = { switchShareSetting }
                    >
                      { o }
                    </Radio>
                  )
                });
              })()
            }
          </FlexBox>
          {
            (() => {
              // 特定のユーザーを選択した場合はユーザー一覧から対象を選択するためのUIを出す。
              if (this.state.requestParam.shareSetting === 4) {
                return (
                  <SelectUsers
                    users = { this.props.users }
                    checked = { this.state.checkedUsers }
                    check = { checkUser }
                    toggleCheckAll = { toggleCheckUsersAll }
                    clearCheckAll = { clearCheckUsersAll }
                    className = 'm-t-24'
                  />
                )
              }
            })()
          }
        </StyledProgressSection>

        <BarBottom>
          <Button
            as = { Link }
            to = '/label/'
          >
            キャンセル
          </Button>
          {
            (() => {
              if (this.state.targetId > 0) {
                return (
                  <Button
                    color="orange"
                    onClick = { save }
                    className = 'm-l-8'
                  >
                    ラベルを保存する
                  </Button>
                )
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default LabelEdit;
