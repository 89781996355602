import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import Cookies from 'js-cookie';

import Checkbox from '../atoms/Checkbox';
import IconExpand from '../atoms/IconExpand';
import ReportTableCellInner from '../atoms/ReportTableCellInner';
import TableDataCell from '../atoms/TableDataCell';
import TableBodyRow from '../atoms/TableBodyRow';
import TableFooter from '../atoms/TableFooter';
import TableHeader from '../atoms/TableHeader';
import TableHeaderCell from '../atoms/TableHeaderCell';
import TableHeaderCellHasCompare from '../atoms/TableHeaderCellHasCompare';
import Alert from '../molecules/Alert';
import Pagination from '../organisms/Pagination';

import COLOR from '../_const/COLOR';
import DISPITEM_TYPE from '../_const/DISPITEM_TYPE';

const StyledTable = styled.div`
  width: 100%;
  max-height: ${ props => props.windowHeight - 120 - 96 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`
const StyledTableIn = styled.div`
  min-width: 100%;
`
const StyledTableGroup = styled.div`
  display: flex;
  align-items: stretch;
`
const StyledTableStickyGroup = styled(StyledTableGroup)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`
const StyledTableDataCompareWrap = styled.div`
  display: flex;
`
const StyledTableDataCompareCell = styled(TableDataCell)`
  width: 25%;
`
const StyledTableBodyRowDivided = styled.div`
  border-top: 1px solid #ccc;
  &:first-child {
    border-top: 0;
  }
`
const StyledTableCellInner = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const StyledLink = styled(Link)`
  color: ${ COLOR.ORANGE };
  text-decoration: none;
`
const StyledWidthChanger = styled.div`
  width: 10px;
  height: 100%;
  cursor: col-resize;
  position: absolute;
  top: 0;
  right: 0;
`

let startX = 0;
let targetCellWidthPrev = 0;
let dragIndexParent = -1;
let dragIndexChild = -1;
let isDragging = false;

class ReportTable extends React.Component {
  constructor(props) {
    super(props)

    const cookiePostPerPage = Cookies.getJSON('rakuda-reporttable-postperpage');

    this.state = {
      cellWidth: [[], []],
      cellUnfoldState: [[], []],
      cellHighlightWidth: 0,
      sortId: props.sortId, // 呼び出し時に指定された項目でsort
      sortCompareId: 'src',
      sortOrder: 'desc',
      dividedDataTableHeader: [[], []],
      sortedDataTable: [],
      arrangedDataTable: [],
      toggleBool: {},
      offset: 0,
      postPerPage: (cookiePostPerPage) ? cookiePostPerPage : 50,
      isDivided: props.isDivided, // レポート構造の変更に対応するため、分割フラグは二重で持つ
    }
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // レポートデータ、表示項目が差し替わった場合の処理
    if (
      prevProps.dataTable !== this.props.dataTable
      || prevProps.dataTableHeader !== this.props.dataTableHeader
      || prevProps.isEnabledCompare !== this.props.isEnabledCompare
    ) {
      await this.setState({
        cellWidth: [[], []],
        cellUnfoldState: [[], []],
        sortedDataTable: this.props.dataTable,
      });
      this.arrangeViewItems();
    }

    // セル幅変更用のイベントをwindowに追加
    window.addEventListener('mousemove', this.dragToChangeCellWidth);
    window.addEventListener('mouseup', this.endToChangeCellWidth);
  }
  componentWillUnmount() {
    // セル幅変更用のイベントをwindowから削除
    window.removeEventListener('mousemove', this.dragToChangeCellWidth);
    window.removeEventListener('mouseup', this.endToChangeCellWidth);
  }
  dragToChangeCellWidth = (e) => {
    if (isDragging === true) {
      const cellWidth = this.state.cellWidth;

      cellWidth[dragIndexParent][dragIndexChild] = targetCellWidthPrev + e.clientX - startX;
      if (cellWidth[dragIndexParent][dragIndexChild] < 100) {
        cellWidth[dragIndexParent][dragIndexChild] = 100;
      }

      this.setState({
        cellWidth: cellWidth,
      });
    }
  }
  endToChangeCellWidth = (e) => {
    if (isDragging === true) {
      document.body.style.cursor = '';
      startX = 0;
      targetCellWidthPrev = 0;
      dragIndexParent = -1;
      dragIndexChild = -1;
      isDragging = false;
    }
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 表示項目ごと、セル固定/非固定のグループに分割する
    // ここに記載されているカラムは表示枠が固定される
    const dividedDataTableHeader = _.partition(this.props.dataTableHeader, o => {
      return (
        o.id === 'clientName'
        || o.id === 'accountName'
        || o.id === 'campaignName'
        || o.id === 'adgroupName'
        || o.id === 'keywordName'
        || o.id === 'division'
        || o.id === 'media'
      )
    });

    // セル幅を定義
    const cellWidth = dividedDataTableHeader.map((arr, i) => {
      return arr.map((o, j) => {
        if (this.state.cellWidth[i][j]) {
          return this.state.cellWidth[i][j];
        } else if (o.id.indexOf('Compare') > -1 && this.state.cellUnfoldState[i][j] === true) {
          // 比較状態を持つセルの幅
          return 480;
        } else if (DISPITEM_TYPE[o.id] === 6) {
          // ゲージを持つセルの幅
          return 170;
        } else {
          return 140;
        }
      });
    });

    // セルの開閉状態を定義
    const cellUnfoldState = dividedDataTableHeader.map((arr, i) => {
      return arr.map((o, j) => {
        if (_.isBoolean(this.state.cellUnfoldState[i][j])) {
          return this.state.cellUnfoldState[i][j];
        } else if (o.id.indexOf('Compare') > -1) {
          return false;
        } else {
          return undefined;
        }
      });
    });

    // データのソートには_.orderBy()を使う。
    let sortedDataTable;
    if (this.state.sortId === 'division') {
      sortedDataTable = this.state.sortedDataTable.map(o => {
        return {
          amount: o.amount,
          itemsDivided: _.orderBy(
            o.itemsDivided,
            (d) => {
              return d[this.state.sortId];
            },
            this.state.sortOrder
          )
        }
      });
    } else {
      sortedDataTable = _.orderBy(
        this.state.sortedDataTable,
        // 分割状態にある場合は合計行の値でソートする
        (this.props.isDivided === true)
          ? (o) => { return o.amount[this.state.sortId] }
          // 媒体をソートした場合はオブジェクトのlabel値でソートする
          : (this.state.sortId.indexOf('Compare') > -1)
            ? (o) => {
              // 比較して比較項目でsortした後に比較を解除するとo[this.state.sortId]がundefinedのエラーになるので、if文でチェックしておく
              if(o[this.state.sortId] !== undefined){ 
                switch (this.state.sortCompareId) {
                  case 'src':
                  default:
                    return o[this.state.sortId].src;
                  case 'dest':
                    return o[this.state.sortId].dest;
                  case 'diff':
                    return o[this.state.sortId].src - o[this.state.sortId].dest;
                  case 'ratio':
                    return o[this.state.sortId].src / o[this.state.sortId].dest;
                }
              }
            }
            // 媒体をソートした場合はオブジェクトのlabel値でソートする
            : (this.state.sortId === 'media')
              ? (o) => { return o[this.state.sortId].label }
              // それ以外
              : this.state.sortId,
        this.state.sortOrder
      );
    }

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      sortedDataTable: sortedDataTable,
      arrangedDataTable: arrangedDataTable,
      dividedDataTableHeader: dividedDataTableHeader,
      cellWidth: cellWidth,
      cellUnfoldState: cellUnfoldState,
      isDivided: this.props.isDivided,
    });
  }
  sort = async (e) => {
    // セルの並び替え
    const id = e.currentTarget.dataset.id;
    const compareId = (e.currentTarget.dataset.compareId) ? e.currentTarget.dataset.compareId : 'src';
    const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

    await this.setState({
      sortId: id,
      sortCompareId: compareId,
      sortOrder: order,
    })
    this.arrangeViewItems();
  }
  render() {
    // 比較セルを開閉する
    const unfoldCompareCell = (index, i) => {
      const cellWidth = this.state.cellWidth;
      const cellUnfoldState = this.state.cellUnfoldState;
      cellUnfoldState[index][i] = !cellUnfoldState[index][i];
      cellWidth[index][i] = (cellUnfoldState[index][i]) ? 480 : 140 ;
      this.setState({
        cellWidth: cellWidth,
        cellUnfoldState: cellUnfoldState,
      });
    }

    // セルの幅をドラッグで変更する
    const startToChangeCellWidth = (index, i, e) => {
      e.preventDefault();
      document.body.style.cursor = 'col-resize';
      startX = e.clientX;
      targetCellWidthPrev = this.state.cellWidth[index][i];
      dragIndexParent = index;
      dragIndexChild = i;
      isDragging = true;
    }

    // テーブルヘッダのセルを生成
    const renderTableHeaderCell = (index) => {
      return this.state.dividedDataTableHeader[index].map((o, i) => {
        if (o.id.indexOf('Compare') > -1) {
          // 比較状態を持つセル
          return (
            <TableHeaderCellHasCompare
              key = { _.uniqueId() }
              style = { { width: `${this.state.cellWidth[index][i]}px` } }
              cellId = { o.id }
              sortId = { this.state.sortId }
              sortCompareId = { this.state.sortCompareId }
              sortOrder = { this.state.sortOrder }
              isUnfolded = { this.state.cellUnfoldState[index][i] }
              sort = { this.sort }
              unfold = { (() => {
                unfoldCompareCell(index, i)
              }) }
            >
              <StyledTableCellInner>
                { o.label }
              </StyledTableCellInner>
              <StyledWidthChanger
                onMouseDown = { ((e) => {
                  startToChangeCellWidth(index, i, e)
                }) }
              />
            </TableHeaderCellHasCompare>
          )
        } else {
          return (
            <TableHeaderCell
              key = { _.uniqueId() }
              style = { { width: `${this.state.cellWidth[index][i]}px` } }
              sortState = {
                (o.id !== this.state.sortId)
                  ? 0
                  : (this.state.sortOrder === 'asc')
                    ? 1
                    : 2
              }
              data-id = { o.id }
              onClick = { this.sort }
            >
              <StyledTableCellInner>
                { o.label }
              </StyledTableCellInner>
              <StyledWidthChanger
                onMouseDown = { ((e) => {
                  startToChangeCellWidth(index, i, e)
                }) }
              />
            </TableHeaderCell>
          )
        }
      });
    }

    // テーブル本文とフッタのセルを生成（共通部分）
    const renderTableCell = (dispItemIndex, data, {
      // オプションの初期値を定義
      rowIndex,
      itemsDivided,
      isParent = false,
      isChildren = false,
      isFooter = false,
    } = {}) => {
      return this.state.dividedDataTableHeader[dispItemIndex].map((o, i) => {
        const width = this.state.cellWidth[dispItemIndex][i];
        const isUnfolded = this.state.cellUnfoldState[dispItemIndex][i];

        if (data[o.id] !== undefined) {
          // 値がundefinedではない
          if (
            // 該当の階層名のセルは次の階層へのリンクにする
            o.id === this.props.targetName
            && this.props.target !== 5
            && isFooter === false
          ) {
            let search = `?filter=${data[this.props.targetId]}`;

            if (this.props.division) search += `&division=${this.props.division}`;

            return (
              <TableDataCell
                key = { _.uniqueId() }
                style = { { width: `${width}px` } }
                isParent = { isParent }
                isChildren = { isChildren }
                isFooter = { isFooter }
              >
                <ReportTableCellInner
                  type = { 3 }
                >
                  <StyledLink
                    to = { {
                      pathname: `/progress/${this.props.target + 1}/`,
                      search: search
                    } }
                  >
                    { data[o.id] }
                  </StyledLink>
                </ReportTableCellInner>
              </TableDataCell>
            )
          } else if (
            // アラート
            o.id === 'alertInfo'
          ) {
            return (
              <TableDataCell
                key = { _.uniqueId() }
                style = { { width: `${width}px` } }
                className = { 'jc-c' }
                isParent = { isParent }
                isChildren = { isChildren }
                isFooter = { isFooter }
              >
                {
                  (() => {
                    if (data[o.id].length > 0) {
                      return (
                        <Alert
                          alertInfo = { data[o.id] }
                        />
                      )
                    }
                  })()
                }
              </TableDataCell>
            )
          } else if (
            // 分割セル
            isParent === true && o.id === 'division'
          ) {
            if (itemsDivided) {
              return (
                <TableDataCell
                  key = { _.uniqueId() }
                  style = { { width: `${width}px` } }
                  className = { 'is-division' }
                  isParent = { isParent }
                  isChildren = { isChildren }
                  isFooter = { isFooter }
                  onClick = {
                    (() => {
                      const toggleBool = this.state.toggleBool;
                      toggleBool[data[this.props.targetId]] = !toggleBool[data[this.props.targetId]];
                      this.setState({
                        toggleBool: toggleBool
                      })
                    })
                  }
                >
                  <IconExpand
                    className = {
                      (this.state.toggleBool[data[this.props.targetId]] === true) ? 'is-expand' : ''
                    }
                  />
                </TableDataCell>
              )
            } else {
              return (
                <TableDataCell
                  key = { _.uniqueId() }
                  style = { { width: `${width}px` } }
                  isParent = { isParent }
                  isChildren = { isChildren }
                  isFooter = { isFooter }
                >
                  <ReportTableCellInner
                    value = { '分割データなし' }
                    type = { 3 }
                  />
                </TableDataCell>
              )
            }
          } else if (
            // 分割された行の各階層名
            isChildren === true
            && (
              o.id === 'clientName'
              || o.id === 'accountName'
              || o.id === 'campaignName'
              || o.id === 'adgroupName'
              || o.id === 'keywordName'
            )
          ) {
            return (
              <TableDataCell
                key = { _.uniqueId() }
                style = { { width: `${width}px` } }
                isParent = { isParent }
                isChildren = { isChildren }
                isFooter = { isFooter }
              >
                <ReportTableCellInner
                  value = { '' }
                  type = { 0 }
                />
              </TableDataCell>
            )
          } else if (
            // データが比較状態を持つ
            o.id.indexOf('Compare') > -1
          ) {
            if (isUnfolded === true) {
              // 比較セルが展開している
              return (
                <StyledTableDataCompareWrap
                  key = { _.uniqueId() }
                  style = { { width: `${width}px` } }
                  isUnfolded = { isUnfolded }
                >
                  <StyledTableDataCompareCell
                    isParent = { isParent }
                    isFooter = { isFooter }
                  >
                    <ReportTableCellInner
                      value = { data[o.id].src }
                      type = { DISPITEM_TYPE[o.id] }
                    />
                  </StyledTableDataCompareCell>
                  <StyledTableDataCompareCell
                    isParent = { isParent }
                    isFooter = { isFooter }
                  >
                    <ReportTableCellInner
                      value = { data[o.id].dest }
                      type = { DISPITEM_TYPE[o.id] }
                    />
                  </StyledTableDataCompareCell>
                  <StyledTableDataCompareCell
                    isParent = { isParent }
                    isFooter = { isFooter }
                  >
                    <ReportTableCellInner
                      value = { data[o.id].src - data[o.id].dest }
                      type = { 99 }
                    />
                  </StyledTableDataCompareCell>
                  <StyledTableDataCompareCell
                    isParent = { isParent }
                    isFooter = { isFooter }
                  >
                    <ReportTableCellInner
                      value = {
                        (() => {
                          if (data[o.id].dest !== 0) {
                            return data[o.id].src / data[o.id].dest * 100
                          } else {
                            return 0;
                          }
                        })()
                      }
                      type = { 5 }
                    />
                  </StyledTableDataCompareCell>
                </StyledTableDataCompareWrap>
              );
            } else {
              // 比較セルが展開していない
              return (
                <TableDataCell
                  key = { _.uniqueId() }
                  style = { { width: `${width}px` } }
                  isParent = { isParent }
                  isFooter = { isFooter }
                >
                  <ReportTableCellInner
                    value = { data[o.id].src }
                    type = { DISPITEM_TYPE[o.id] }
                  />
                </TableDataCell>
              );
            }
          } else {
            // データが比較状態を持たない
            return (
              <TableDataCell
                key = { _.uniqueId() }
                style = { { width: `${width}px` } }
                isParent = { isParent }
                isFooter = { isFooter }
              >
                <ReportTableCellInner
                  value = { data[o.id] }
                  type = { DISPITEM_TYPE[o.id] }
                />
              </TableDataCell>
            );
          }
        } else {
          // 値がundefined
          return (
            <TableDataCell
              key = { _.uniqueId() }
              style = { { width: `${width}px` } }
              isParent = { isParent }
              isFooter = { isFooter }
            >
              undefined
            </TableDataCell>
          );
        }
      })
    }

    // テーブル本文のセルを生成
    const renderTableBodyCell = (dispItemIndex, reportData, opts) => {
      return renderTableCell(
        dispItemIndex,
        reportData,
        opts
      );
    }

    // テーブルフッタのセルを生成
    const renderTableFooterCell = (dispItemIndex) => {
      return renderTableCell(
        dispItemIndex,
        this.props.dataTableFooter,
        {
          isFooter: true
        }
      );
    }

    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.props.clearCheckItems();
      this.arrangeViewItems();

      // Cookieに選択中の表示項目設定IDをセット
      Cookies.set('rakuda-reporttable-postperpage', num);
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.props.clearCheckItems();
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.props.dataTable.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.props.clearCheckItems();
      this.arrangeViewItems();
    }

    return (
      <div>
        <StyledTable
          windowHeight = { this.props.windowHeight }
        >
          <StyledTableIn
            style = {
              {
                width: `
                  ${
                    this.state.cellWidth[0].reduce((a, b) => { return a + b }, 0)
                    + this.state.cellWidth[1].reduce((a, b) => { return a + b }, 0)
                    + ((this.props.checked) ? 40 : 0)
                  }px`
              }
            }
          >
            <TableHeader>
              <StyledTableStickyGroup>
                {
                  (() => {
                    if (this.props.checked) {
                      return (
                        <TableHeaderCell
                          className = { 'has-checkbox' }
                        >
                          <Checkbox
                            onClick = {
                              (() => {
                                this.props.toggleCheckAll(this.state.arrangedDataTable);
                              })
                            }
                            isChecked = {
                              this.props.checked.length === this.state.arrangedDataTable.length
                              && this.state.arrangedDataTable.length > 0
                            }
                          />
                        </TableHeaderCell>
                      )
                    }
                  })()
                }
                {
                  // テーブルヘッダの固定セルグループを生成
                  renderTableHeaderCell(0)
                }
              </StyledTableStickyGroup>
              <StyledTableGroup>
                {
                  // テーブルヘッダの非固定セルグループを生成
                  renderTableHeaderCell(1)
                }
              </StyledTableGroup>
            </TableHeader>
            <div>
              {
                (() => {
                  // テーブル本文の行を生成
                  if (this.state.isDivided) {
                    // 分割あり
                    return this.state.arrangedDataTable.map((data, rowIndex) => {
                      // 分割の親
                      return (
                        <StyledTableBodyRowDivided
                          key = { `reportTableRow_${rowIndex}` }
                        >
                          <TableBodyRow>
                            <StyledTableStickyGroup>
                              {
                                (() => {
                                  if (this.props.checked) {
                                    return (
                                      <TableDataCell
                                        className = { 'has-checkbox' }
                                        isParent = { true }
                                      >
                                        <Checkbox
                                          value = { data.amount[this.props.targetId] }
                                          onClick = { this.props.checkItem }
                                          isChecked = { this.props.checked.indexOf(data.amount[this.props.targetId]) > -1 }
                                        />
                                      </TableDataCell>
                                    )
                                  }
                                })()
                              }
                              {
                                // テーブル本文の固定セルグループを生成
                                renderTableBodyCell(0, data.amount, {
                                  rowIndex: rowIndex,
                                  itemsDivided: data.itemsDivided,
                                  isParent: true,
                                })
                              }
                            </StyledTableStickyGroup>
                            <StyledTableGroup>
                              {
                                // テーブル本文の非固定セルグループを生成
                                renderTableBodyCell(1, data.amount, {
                                  rowIndex: rowIndex,
                                  itemsDivided: data.itemsDivided,
                                  isParent: true
                                })
                              }
                            </StyledTableGroup>
                          </TableBodyRow>
                          {
                            (() => {
                              // 分割の子どもたち
                              if (data.itemsDivided) {
                                return data.itemsDivided.map((child) => {
                                  return (
                                    <TableBodyRow
                                      key = { _.uniqueId() }
                                      isOpened = { (this.state.toggleBool[child[this.props.targetId]]) ? true : false }
                                    >
                                      <StyledTableStickyGroup>
                                        <TableDataCell
                                          className = { 'has-checkbox' }
                                        />
                                        {
                                          // テーブル本文の固定セルグループを生成
                                          renderTableBodyCell(0, child, {
                                            isChildren: true
                                          })
                                        }
                                      </StyledTableStickyGroup>
                                      <StyledTableGroup>
                                        {
                                          // テーブル本文の非固定セルグループを生成
                                          renderTableBodyCell(1, child, {
                                            isChildren: true
                                          })
                                        }
                                      </StyledTableGroup>
                                    </TableBodyRow>
                                  )
                                });
                              }
                            })()
                          }
                        </StyledTableBodyRowDivided>
                      )
                    })
                  } else {
                    // 分割なし
                    return this.state.arrangedDataTable.map((r, rowIndex) => {
                      return (
                        <TableBodyRow
                          key = { `reportTableRow_${rowIndex}` }
                        >
                          <StyledTableStickyGroup>
                            {
                              (() => {
                                if (this.props.checked) {
                                  return (
                                    <TableDataCell
                                      className = { 'has-checkbox' }
                                    >
                                      <Checkbox
                                        value = { r[this.props.targetId] }
                                        onClick = { this.props.checkItem }
                                        isChecked = { this.props.checked.indexOf(r[this.props.targetId]) > -1 }
                                      />
                                    </TableDataCell>
                                  )
                                }
                              })()
                            }
                            {
                              // テーブル本文の固定セルグループを生成
                              renderTableBodyCell(0, r)
                            }
                          </StyledTableStickyGroup>
                          <StyledTableGroup>
                            {
                              // テーブル本文の非固定セルグループを生成
                              renderTableBodyCell(1, r)
                            }
                          </StyledTableGroup>
                        </TableBodyRow>
                      )
                    })
                  }
                })()
              }
            </div>
            {
              (() => {
                if (this.props.dataTableFooter) {
                  return (
                    <TableFooter>
                      <StyledTableStickyGroup>
                        {
                          (() => {
                            if (this.props.checked) {
                              return (
                                <TableDataCell
                                  className = { 'has-checkbox' }
                                  isFooter = { true }
                                />
                              )
                            }
                          })()
                        }
                        {
                          // テーブルの合計行の固定セルグループを生成
                          renderTableFooterCell(0)
                        }
                      </StyledTableStickyGroup>
                      <StyledTableGroup>
                        {
                          // テーブルの合計行の非固定セルグループを生成
                          renderTableFooterCell(1)
                        }
                      </StyledTableGroup>
                    </TableFooter>
                  )
                }
              })()
            }
          </StyledTableIn>
        </StyledTable>

        <div className="m-t-24">
          <Pagination
            offset = { this.state.offset }
            postPerPage = { this.state.postPerPage }
            dataCount = { this.props.dataTable.length }
            switchPostPerPage = { switchPostPerPage }
            goToPrevPagination = { goToPrevPagination }
            goToNextPagination = { goToNextPagination }
          />
        </div>
      </div>
    )
  }
}

export default ReportTable;
