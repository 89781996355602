import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import COLOR from '../../_const/COLOR';

import Button from '../../atoms/Button';
import ButtonEdit from '../../atoms/ButtonEdit';
import ButtonDuplicate from '../../atoms/ButtonDuplicate';
import Checkbox from '../../atoms/Checkbox';
import FlexBox from '../../atoms/FlexBox';
import IconPlus from '../../atoms/IconPlus';
import TableDataCell from '../../atoms/TableDataCell';
import TableBodyRow from '../../atoms/TableBodyRow';
import TableHeader from '../../atoms/TableHeader';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import BarEditData from '../../molecules/BarEditData';
import Memo from '../../molecules/Memo';
import Search from '../../molecules/Search';
import Pagination from '../../organisms/Pagination';
import PullDownExportAndImport from '../../organisms/PullDownExportAndImport';
import ModalFileExport from '../../organisms/ModalFileExport';
import ModalFileImport from '../../organisms/ModalFileImport';
import ModalErrorMessage from '../../organisms/ModalErrorMessage';
import DividerDottedV from '../../atoms/DividerDottedV';

import alignSearchQuery from '../../_util/alignSearchQuery';

import User from '../../../utils/user';

// sum fixed header width (checkbox, note, edit, copy, repeat)
const FIXED_WIDTH = (40 + 80 + 80 + 80 + 100);

const TABLE_HEADER = [
  {
    id: 'name',
    label: 'カスタムレポート名',
    width: `calc((100% - ${FIXED_WIDTH}px) / 5)`,
    hasSort: true,
  },
  {
    id: 'createDate',
    label: '作成日',
    width: `calc((100% - ${FIXED_WIDTH}px) / 5)`,
    hasSort: true,
  },
  {
    id: 'lastModified',
    label: '最終更新日時',
    width: `calc((100% - ${FIXED_WIDTH}px) / 5)`,
    hasSort: true,
  },
  {
    id: 'range',
    label: '対象期間',
    width: `calc((100% - ${FIXED_WIDTH}px) / 5)`,
    hasSort: true,
  },
  {
    id: 'userName',
    label: '作成者',
    width: `calc((100% - ${FIXED_WIDTH}px) / 5)`,
    hasSort: true,
  },
  {
    id: 'note',
    label: 'メモ',
    width: '80px',
    hasSort: false,
  },
  {
    id: 'edit',
    label: '編集',
    width: '80px',
    hasSort: false,
  },
  {
    id: 'copy',
    label: 'コピー',
    width: '80px',
    hasSort: false,
  },
  {
    id: 'repeatSetting',
    label: '繰り返し',
    width: '100px',
    hasSort: true,
  },
];

const StyledTable = styled.div`
  width: 100%;
  max-height: ${ props => props.windowHeight - 120 - 106 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`
const StyledEditLink = styled(Link)`
  color: ${COLOR.ORANGE};
  text-decoration: none;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

const ITEMS = [
  { type: 'importModal', label: '新しいデータをインポートする' },
  { type: 'export', label: '現在のカスタムレポート一覧をエクスポートする', url: backendApi + 'customReport', param: { dl: 1 } },
  { type: 'export', label: 'クライアントデータをエクスポートする', url: backendApi + 'client', param: { dl: 1 } },
  { type: 'export', label: 'アカウントデータをエクスポートする', url: backendApi + 'account', param: { dl: 1 } },
  { type: 'exportModal', label: 'クライアントを指定してエクスポートする' },
];

const EXPORT_MODAL = [
  { type: 'input', label: 'ID', heading: 'クライアントID' },
  { type: 'radio', heading: 'ターゲット', list: [
    { label: 'キャンペーン', id: 1, value: 3 },
    { label: '広告グループ', id: 2, value: 4 },
    { label: 'キーワード', id: 3, value: 5 }
  ]},
];

const EXPORT_URL = backendApi + 'camAdgKw';
const IMPORT_URL = backendApi + 'fileUpload';

class CustomReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: [],
      dataFiltered: [],
      data: [],
      offset: 0,
      postPerPage: 50,
      searchQuery: '',
      checked: [],
    };
    props.switchGlobalCatId(2);
    props.switchContentTitle('カスタムレポート');
    props.toggleDatePicker(false);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    await this.getData();
    this.arrangeViewItems();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'customReport', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        dataOriginal: response.data,
        dataFiltered: response.data,
        data: response.data,
      });
    })
    .catch(() => {
    });
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 元データを指定の条件でフィルタする
    const filteredData = this.state.dataOriginal.filter((item, index) => {
      const regexp = new RegExp(
        `^(?=.*${alignSearchQuery(this.state.searchQuery)}).*$`
      );

      return (
        (
          // フリーワード検索のクエリに合致するか
          this.state.searchQuery === ''
          || alignSearchQuery(item.name).match(regexp) !== null
        )
      )
    });

    // データのソートには_.orderBy()を使う。
    const sortedDataTable = _.orderBy(
      filteredData,
      o => {
        return (this.state.sortId === 'range')
          ? o['startDate']
          : o[this.state.sortId];
      },
      this.state.sortOrder
    );

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      dataFiltered: filteredData,
      data: arrangedDataTable,
    });
  }
  render() {
    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();
      await this.setState({
        offset: 0,
        searchQuery: e.target['searchQuery'].value,
        checked: [],
      });
      this.arrangeViewItems();
    }

    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    // 各行のチェック
    const checkItem = (e) => {
      const id = parseInt(e.currentTarget.value);
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        checked.splice(checked.indexOf(id), 1)
      } else {
        checked.push(id);
      }
      this.setState({
        checked: checked
      });
    }

    // 削除
    const deleteCustomReport = async () => {
      // プログレス表示
      this.props.startProgressing();

      let repeatIds = _.without(this.state.checked.map(x => {
        return _.find(this.state.data, (o) => {
          return (o.id === x) && (o.repeatId);
        })
      }), undefined).map(y => y.repeatId);

      await axios.get(backendApi + 'customReportModify', {
        params: {
          ...User.apiParams(),
          func: 2,
          ids: this.state.checked.join(','),
        }
      })
      .then((response) => {
        this.setState({
          dataOriginal: response.data,
          dataFiltered: response.data,
          data: response.data,
          checked: [],
        });
      })
      .catch(() => {
      });

      if(repeatIds.length > 0) {
        await axios.get(backendApi + 'customReportRepeatModify', {
          params: {
            ...User.apiParams(),
            func: 2,
            ids : repeatIds.join(','),
          }
        })
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        })
      }

      this.arrangeViewItems();

      //　プログレス非表示
      this.props.endProgressing();
    }

    // ページャの表示件数を変更
    const switchPostPerPage = async (num) => {
      await this.setState({
        postPerPage: num
      })
      this.arrangeViewItems();
    }

    // ページャの戻るイベント
    const goToPrevPagination = async () => {
      if (this.state.offset <= 0) return;
      await this.setState({
        offset: this.state.offset - 1
      });
      this.arrangeViewItems();
    }

    // ページャの進むイベント
    const goToNextPagination = async () => {
      if (this.state.offset >= Math.ceil(this.state.dataFiltered.length / this.state.postPerPage) - 1) return;
      await this.setState({
        offset: this.state.offset + 1
      });
      this.arrangeViewItems();
    }

    // コピー
    const duplicateCustomReport = async (report) => {
      // プログレス表示
      this.props.startProgressing();
      await axios.get(backendApi + 'customReportModify', {
        params: {
          ...User.apiParams(),
          func: 3,
          id: report.id,
        }
      })
      .then((response) => {
        this.setState({
          dataOriginal: response.data,
          dataFiltered: response.data,
          data: response.data,
        });
        this.arrangeViewItems();
        //　プログレス非表示
        this.props.endProgressing();
      })
      .catch(() => {
        //　プログレス非表示
        this.props.endProgressing();
      });
    }

        // エクスポートモーダルを開く
        const openExportModal = () => {
          this.setState({
            isOpenedExportModal: true
          });
        }
        // エクスポートモーダルを閉じる
        const closeExportModal = () => {
          this.setState({
            isOpenedExportModal: false
          });
        }
        // インポートモーダルを開く
        const openImportModal = () => {
          this.setState({
            isOpenedImportModal: true
          });
        }
        // インポートモーダルを閉じる
        const closeImportModal = () => {
          this.setState({
            isOpenedImportModal: false
          });
        }
        // エラーモーダルを開く
        const openErrorModal = () => {
          this.setState({
            isOpenedErrorModal: true
          });
        }
        // エラーモーダルを閉じる
        const closeErrorModal = () => {
          this.setState({
            isOpenedErrorModal: false
          });
        }
        // エラーモーダルの本文を書き換える
        const changeErrorText = (text) => {
          this.setState({
            errorText: text
          });
        }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isOpenedErrorModal }
          close = { closeErrorModal }
          bodyText = { this.state.errorText }
        ></ModalErrorMessage>
        <ModalFileExport
          heading = 'クライアントを指定してエクスポートする'
          isOpened = { this.state.isOpenedExportModal }
          closeExportModal = { closeExportModal }
          modalContents = { EXPORT_MODAL }
          apiUrl = { EXPORT_URL }
          apiParams = { User.apiParams() }
          changeErrorText = { changeErrorText }
          openErrorModal = { openErrorModal }
        ></ModalFileExport>
        <ModalFileImport
          heading = '新しいファイルをインポートする'
          isOpened = { this.state.isOpenedImportModal }
          closeImportModal = { closeImportModal }
          apiUrl = { IMPORT_URL }
          apiParams = { User.apiParams() }
          changeErrorText = { changeErrorText }
          openErrorModal = { openErrorModal }
          funcId = { 3 }
        ></ModalFileImport>
        <FlexBox className="m-b-32" justify="space-between">
          <FlexBox>
            <Search
              name = "searchQuery"
              onSubmit = { setSearchQuery }
              value = { this.state.searchQuery }
            />
            <DividerDottedV hasMargin />
            <PullDownExportAndImport
              items = { ITEMS }
              apiParams = { User.apiParams() }
              openExportModal = { openExportModal }
              openImportModal = { openImportModal }
            />
          </FlexBox>
          <Button
            as = { Link }
            to = '/custom-report/edit'
          >
            新規カスタムレポート作成
            <IconPlus className="m-l-8" />
          </Button>
        </FlexBox>
        <BarEditData
          count = { this.state.checked.length }
          onClickDelete = { deleteCustomReport }
        />
        <StyledTable
          windowHeight = { this.props.windowHeight }
        >
          <TableHeader>
            <TableHeaderCell
              className = { 'has-checkbox' }
            />
            {
              (() => {
                return TABLE_HEADER.map(o => {
                  return (
                    <TableHeaderCell
                      key = { _.uniqueId() }
                      style = { { width: `${o.width}` } }
                      sortState = {
                        (o.hasSort === false)
                          ? undefined
                          : (o.id !== this.state.sortId)
                            ? 0
                            : (this.state.sortOrder === 'asc')
                              ? 1
                              : 2
                      }
                      data-id = { o.id }
                      onClick = { (o.hasSort === true) ? sort : undefined }
                    >
                      { o.label }
                    </TableHeaderCell>
                  )
                })
              })()
            }
          </TableHeader>
          <div>
            {
              (() => {
                return this.state.data.map(r => {
                  return (
                    <TableBodyRow
                      key = { _.uniqueId() }
                    >
                      <TableDataCell
                        className = { 'has-checkbox' }
                      >
                        <Checkbox
                          value = { r.id }
                          onClick = { checkItem }
                          isChecked = { this.state.checked.indexOf(r.id) > -1 }
                        />
                      </TableDataCell>
                      {
                        (() => {
                          return TABLE_HEADER.map(o => {
                            return (
                              <TableDataCell
                                key = { _.uniqueId() }
                                style = { { width: `${o.width}` } }
                                className = {
                                  classnames({
                                    'jc-c': o.id === 'note'
                                  })
                                }
                              >
                                {
                                  (() => {
                                    let search = '';
                                    switch (o.id) {
                                      case 'name':
                                        if (r.division) search += `&division=${r.division}`;
                                        return (
                                          <StyledEditLink
                                            to = { {
                                              pathname: `/progress/${r.target}/`,
                                              search: `customReport=${r.id}` + search
                                            } }
                                          >
                                            { r[o.id] }
                                          </StyledEditLink>
                                        )
                                      case 'range':
                                        return (r.compare === 0)
                                          ? `${r.startDate} ～ ${r.endDate}`
                                          : (
                                            <div>
                                              <div>{r.startDate} ～ {r.endDate}</div>
                                              <div>比較：{r.startDateC} ～ {r.endDateC}</div>
                                            </div>
                                          );
                                      case 'edit':
                                        return (
                                          <FlexBox
                                            width = '100%'
                                            justify = 'center'
                                          >
                                            <ButtonEdit
                                              tag = { Link }
                                              to = { `/custom-report/edit/?id=${r.id}` }
                                            />
                                          </FlexBox>
                                        )
                                      case 'copy':
                                        return (
                                          <FlexBox
                                            width = '100%'
                                            justify = 'center'
                                          >
                                            <ButtonDuplicate
                                              className = 'm-l-8'
                                              onClick = {
                                                (() => {
                                                  duplicateCustomReport(r);
                                                })
                                              }
                                            />
                                          </FlexBox>
                                        )
                                      case 'note':
                                        return (r[o.id] !== '')
                                          ? (
                                            <Memo str = { r[o.id] } />
                                          )
                                          : r[o.id]
                                      case 'repeatSetting':
                                        if(r[o.id]) {
                                          switch(r[o.id].termUnit) {
                                            case 1:
                                              return '日次';
                                            case 2:
                                              return '週次';
                                            case 3:
                                              return '月次';
                                            default:
                                              return '';
                                          }
                                        } else {
                                          return '1回のみ';
                                        }
                                      default:
                                        return r[o.id];
                                    }
                                  })()
                                }
                              </TableDataCell>
                            )
                          })
                        })()
                      }
                    </TableBodyRow>
                  )
                })
              })()
            }
          </div>
        </StyledTable>

        <div className="m-t-24">
          <Pagination
            offset = { this.state.offset }
            postPerPage = { this.state.postPerPage }
            dataCount = { this.state.dataFiltered.length }
            switchPostPerPage = { switchPostPerPage }
            goToPrevPagination = { goToPrevPagination }
            goToNextPagination = { goToNextPagination }
          />
        </div>
      </div>
    )
  }
}

export default CustomReport;
