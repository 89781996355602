import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';

import Button from '../atoms/Button';
import CheckboxWithLabel from '../atoms/CheckboxWithLabel';
import FlexBox from '../atoms/FlexBox';
import HeadingS from '../atoms/HeadingS';
import IconFilter from '../atoms/IconFilter';
import MediaBadge from '../atoms/MediaBadge';
import Radio from '../atoms/Radio';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownS from '../molecules/PullDownS';

const MEDIAS = [
  {
    id: 1,
    label: 'Google広告',
    value: 'aw',
  },
  {
    id: 2,
    label: 'Yahoo!SS',
    value: 'ss',
  },
  {
    id: 3,
    label: 'YDN',
    value: 'ydn',
  },
  {
    id: 4,
    label: 'Xリスティング',
    value: 'zeus',
  },
  {
    id: 5,
    label: 'DV360',
    value: 'dbm',
  },
  // {
  //   id: 6,
  //   label: 'D2C',
  //   value: '',
  // },
  {
    id: 7,
    label: 'Criteo',
    value: 'cr',
  },
  // {
  //   id: 8,
  //   label: 'YCD',
  //   value: '',
  // },
  {
    id: 9,
    label: 'Facebook',
    value: 'fb',
  },
  {
    id: 10,
    label: 'Twitter',
    value: 'tw',
  },
  {
    id: 11,
    label: 'Indeed',
    value: 'indeed',
  },
  {
    id: 12,
    label: 'LINE',
    value: 'line',
  },
];

const StyledPullDownProgressFilter = styled.div`
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  width: 552px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10;
`
const StyledPullDownListWrapIn1 = styled(PullDownListWrapIn)`
  padding: 32px;
`
const StyledPullDownListWrapIn2 = styled(PullDownListWrapIn)`
  padding: 8px;
  border-top: 1px solid #e6e6e6;
`
const StyledRadio = styled(Radio)`
  width: 120px;
  margin-top: 8px;
`
const StyledCheckbox = styled(CheckboxWithLabel)`
  ${
    ({col, index}) => {
      return `
        width: calc((100% - ${16 * (col - 1)}px) / ${ col });
        margin-left: ${(index % col !== 0) ? '16px' : 0}
      `
    }
  }
  margin-top: 8px;
`

class PullDownProgressFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      param: {
        // 部署・グループ
        sectionId: null,
        groupId:   null,
        // ステータス
        dataExist: 1,
        // 媒体
        aw:        null,
        ss:        null,
        ydn:       null,
        cr:        null,
        tw:        null,
        fb:        null,
        zeus:      null,
        line:      null,
        dbm:       null,
        // デバイス
        device:    null,
        // アラート
        alNot:     null,
        al1:       null,
        al2:       null,
        al3:       null,
        al4:       null,
        // 外部CV
        ex: 0,
        // ラベル
        label:     null,
      },
      isOpenedPullDown: false,
      labelData: [],
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // ポップアップを開いた際の処理
    if (
      prevProps.isOpenedPullDown === false
      && this.props.isOpenedPullDown === true
    ) {
      const requestParam = this.props.requestParam;
      const param = this.state.param;
      _.forEach(param, (value, key) => {
        param[key] = requestParam[key];
      });
      const labelData = this.props.labelData;
      await this.setState({
        param: param,
        labelData: labelData
      });
    }
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  render() {
    const RadioHasProps = ({ name, id, value, keyValue, children }) => (
      <StyledRadio
        name = { name }
        id = { id }
        value = { String(value) }
        checked = { this.state.param[keyValue] === value ? 'checked' : '' }
        onChange = {
          ((e) => {
            const param = this.state.param;
            param[keyValue] = value;
            this.setState({
              param: param
            });
          })
        }
      >
        { children }
      </StyledRadio>
    );

    const CheckboxHasProps = ({ name, id, value, keyValue, children, index, col }) => (
      <StyledCheckbox
        name = { name }
        id = { id }
        value = { String(value) }
        checked = { this.state.param[keyValue] === value ? 'checked' : '' }
        index = { index }
        col = { col }
        onChange = {
          ((e) => {
            const param = this.state.param;
            param[keyValue] = (e.target.checked) ? value : null;
            this.setState({
                param: param
            });
          })
        }
      >
        { children }
      </StyledCheckbox>
    );

    const submit = () => {
      this.props.setDefaultParam(this.state.param);
      this.closePullDown();
    }

    return (
      <StyledPullDownProgressFilter
        className = { this.props.className }
      >
        <PullDownS
          onClick = {this.togglePullDown}
        >
          <FlexBox>
            <IconFilter
              className = 'm-r-8'
            />
            フィルタ
          </FlexBox>
        </PullDownS>
        <StyledPullDownListWrap
          isOpenedPullDown = { this.state.isOpenedPullDown }
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn1>
            <HeadingS noMargin>ステータス</HeadingS>
            <FlexBox flexWrap className="m-b-32">
              <RadioHasProps
                name = 'dataExist'
                id = 'dataExist-0'
                value = { 0 }
                keyValue = 'dataExist'
              >
                すべて
              </RadioHasProps>
              <RadioHasProps
                name = 'dataExist'
                id = 'dataExist-1'
                value = { 1 }
                keyValue = 'dataExist'
              >
                アクティブのみ
              </RadioHasProps>
            </FlexBox>
            <HeadingS noMargin>媒体</HeadingS>
            <FlexBox flexWrap className = "m-b-32">
              {
                (() => {
                  return MEDIAS.map((m, i) => {
                    return (
                      <CheckboxHasProps
                        key = { _.uniqueId() }
                        name = 'media'
                        id = { `media-${m.value}` }
                        value = { 1 }
                        keyValue = { m.value }
                        index = { i }
                        col = { 3 }
                      >
                        <MediaBadge
                          id = { m.id }
                          label = { m.label }
                        />
                      </CheckboxHasProps>
                    )
                  })
                })()
              }
            </FlexBox>
            <HeadingS noMargin>デバイス</HeadingS>
            <FlexBox flexWrap className = "m-b-32">
              <RadioHasProps
                name = 'device'
                id = 'device0'
                value = { null }
                keyValue = 'device'
              >
                全デバイス
              </RadioHasProps>
              <RadioHasProps
                name = 'device'
                id = 'device1'
                value = { 1 }
                keyValue = 'device'
              >
                パソコン
              </RadioHasProps>
              <RadioHasProps
                name = 'device'
                id = 'device4'
                value = { 4 }
                keyValue = 'device'
              >
                モバイル
              </RadioHasProps>
              <RadioHasProps
                name = 'device'
                id = 'device8'
                value = { 8 }
                keyValue = 'device'
              >
                タブレット
              </RadioHasProps>
            </FlexBox>
            <HeadingS noMargin>外部データ</HeadingS>
            <FlexBox flexWrap className="m-b-32">
              <RadioHasProps
                name = 'ex'
                id = 'ex-0'
                value = { 0 }
                keyValue = 'ex'
              >
                なし
              </RadioHasProps>
              <RadioHasProps
                name = 'ex'
                id = 'ex-1'
                value = { 1 }
                keyValue = 'ex'
              >
                外部CVのみ
              </RadioHasProps>
              <RadioHasProps
                name = 'ex'
                id = 'ex-2'
                value = { 2 }
                keyValue = 'ex'
              >
                すべて
              </RadioHasProps>
            </FlexBox>
            <HeadingS noMargin>ラベル</HeadingS>
            <FlexBox flexWrap className = "m-b-32">
              <RadioHasProps
                name = 'label'
                id = 'label-0'
                value = { null }
                keyValue = 'label'
              >
                なし
              </RadioHasProps>
              {
                (() => {
                  return this.props.labelData.map((m, i) => { // stateだと値がなかったので、とりあえずpropsで
                    return (
                    <RadioHasProps
                      name = 'label'
                      id = { `label-${m.id}` }
                      value = { m.id }
                      keyValue = 'label'
                    >
	                  { m.name }
	                  </RadioHasProps>
                    )
                  })
                })()
              }
            </FlexBox>
          </StyledPullDownListWrapIn1>
          <StyledPullDownListWrapIn2>
            <Button
              width = "100%"
              justify = "center"
              onClick = { submit }
            >
              適用
            </Button>
          </StyledPullDownListWrapIn2>
        </StyledPullDownListWrap>
      </StyledPullDownProgressFilter>
    )
  }
}

export default onClickOutside(PullDownProgressFilter);
