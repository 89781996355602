import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import classnames from 'classnames';

import COLOR from '../_const/COLOR';

import IconArrow from '../atoms/IconArrow';
import IconArrowDouble from '../atoms/IconArrowDouble';
import ButtonSmall from '../atoms/ButtonSmall';
import ButtonToggle from '../atoms/ButtonToggle';

const StyledWrap = styled.div`
  width: 160px;
  position: fixed;
  top: 104px;
  bottom: 0;
  left: 0;
  z-index: 200;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  transition-duration: .2s;
  transition-property: transform;
  &.is-closed {
    transform: translateX(-110px)
  }
`
const StyledWrapIn = styled.div`
  padding-top: 36px;
  padding-bottom: 36px;
`
const StyledToggle = styled.button`
  width: 160px;
  height: 36px;
  cursor: pointer;
  position: absolute;
  top: -36px;
  left: 0;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0;
  border: 0;
  border-radius: 0 2px 0 0;
  background-color: ${COLOR.BLACK};
`
const StyledToggleArrow = styled(IconArrowDouble)`
  position: absolute;
  top: 14px;
  right: 22px;
  &.is-closed-sidemenu {
    transform: rotate(180deg)
  }
`
const StyledItems = styled.ul`
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  transition-duration: .2s;
  transition-property: opacity;
  &.is-closed {
    height: 0;
  }
  &.is-closed-sidemenu {
    opacity: 0;
    pointer-events: none;
  }
`
const StyledItem = styled.li`
  position: relative;
  margin-top: 22px;
  &:first-child {
    margin-top: 0;
  }
  &:before {
    width: 0;
    height: 16px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${COLOR.ORANGE};
    transition-duration: .2s;
    transition-property: width;
  }
  &.is-current {
    &:before {
      width: 8px;
    }
  }
`
const CssItemLabel = css`
  width: 142px;
  box-sizing: border-box;
  display: block;
  position: relative;
  padding-right: 20px;
  padding-left: 22px;
  color: ${COLOR.BLACK};
  font-size: 12px;
  text-decoration: none;
`
const StyledItemLabel = styled.div`
  cursor: pointer;
  ${CssItemLabel}
`
const StyledItemLink = styled(Link)`
  ${CssItemLabel}
`
const StyledChildItemLink = styled(Link)`
  width: 142px;
  box-sizing: border-box;
  display: block;
  position: relative;
  margin-top: 16px;
  padding-right: 20px;
  padding-left: 36px;
  color: ${COLOR.BLACK};
  font-size: 12px;
  text-decoration: none;
`
const StyledChildItemIconArrow = styled(IconArrow)`
  position: absolute;
  top: 50%;
  right: 6px;
  margin-top: -2px;
`
const StyledButtonSmall = styled(ButtonSmall)`
  position: absolute;
  top: 0;
  right: 0;
`
const StyledButtonToggle = styled(ButtonToggle)`
  position: absolute;
  top: 0;
  right: 0;
`
const StyledLink = styled.a`
width: 142px;
box-sizing: border-box;
display: block;
position: relative;
margin-top: 16px;
padding-right: 20px;
padding-left: 36px;
color: ${COLOR.BLACK};
font-size: 12px;
text-decoration: none;
`
const outsideSales = process.env.REACT_APP_OUTSIDE_SALES;

const MENU = [
  {
    label: 'ダッシュボード',
    to: '/',
  },
  {
    label: '進捗管理',
    children: [
      {
        label: 'クライアント',
        to: '/progress/1/',
      },
      {
        label: 'アカウント',
        to: '/progress/2/',
      },
      {
        label: 'キャンペーン',
        to: '/progress/3/',
      },
      {
        label: '広告グループ',
        to: '/progress/4/',
      },
      {
        label: 'キーワード/ターゲティング',
        to: '/progress/5/',
      },
    ],
  },
  {
    label: 'カスタムレポート',
    to: '/custom-report',
  },
  {
    label: 'ラベル',
    to: '/label',
  },
  {
    label: '設定&管理',
    children: [
      {
        label: '媒体予算管理',
        to: '/setting/budget',
      },
      {
        label: 'アラート',
        to: '/setting/alert',
      },
      {
        label: 'スケジュール管理',
        to: '/setting/schedule',
      },
      {
        label: 'スケジュール設定',
        to: '/setting/scheduleSet',
      },
      {
        label: '外部コンバージョン設定',
        to: '/setting/external-data',
      },
      {
        label: '媒体コンバージョン設定',
        to: '/setting/mediaCv',
      },
      {
        label: '目標設定',
        to: '/setting/new-item',
      },
      // {
      //   label: 'スマートフォン表示設定',
      //   to: '/setting/smartphone',
      // },
      {
        label: 'アクティビティ履歴',
        to: '/setting/activity',
      },
      {
        label: '権限管理',
        to: '/setting/authority',
      },
    ],
  },
  {
    label: '運用ツール',
    children: [
      {
        label: 'iMCレポート自動送付',
        to: '/tool/reportSend',
      },
      {
        label: '請求書自動送付',
        to: '/tool/invoiceSend',
      },
      {
        label: 'ファイル自動送付',
        to: '/tool/fileSend',
      },
      {
        label: 'SEO競合比較調査（京香ちゃん）',
        to: '/tool/seoCheck',
      },
      {
        label: 'Twitterクリエイティブ取得',
        to: '/tool/snsCreative',
      },
      {
        label: 'Keywordサーチ',
        to: '/tool/keywordSearch',
      },
      {
        label: 'サスペンド解除補助用・自動検索ツール(外)',
        url: 'http://54.250.121.144/kw_cross/kw/ReleaseKw.htm',
      },
    ],
  },
  {
    label: '依頼・申請',
    children: [
      {
        label: 'Google次月予算停止',
        to: '/request/budgetStop',
      },
      {
        label: '依頼BOX(外)',
        url: 'https://ibox.medix-inc.co.jp/medix_innovator/orderBox/Login.htm',
      },
      {
        label: 'お願いフォーム(外)',
        url: 'https://dsb2.medix-inc.co.jp/medix_innovator/orderForm/Top.htm',
      },
    ],
  },
]

const MENU_OUTSIDE_SALES = [
  {
    label: 'ダッシュボード',
    to: '/',
  },
  {
    label: '進捗管理',
    children: [
      {
        label: 'クライアント',
        to: '/progress/1/',
      },
      {
        label: 'アカウント',
        to: '/progress/2/',
      },
      {
        label: 'キャンペーン',
        to: '/progress/3/',
      },
      {
        label: '広告グループ',
        to: '/progress/4/',
      },
      {
        label: 'キーワード/ターゲティング',
        to: '/progress/5/',
      },
    ],
  },
  {
    label: 'カスタムレポート',
    to: '/custom-report',
  },
  {
    label: 'ラベル',
    to: '/label',
  },
  {
    label: '設定&管理',
    children: [
      {
        label: 'アラート',
        to: '/setting/alert',
      },
      {
        label: '外部コンバージョン設定',
        to: '/setting/external-data',
      },
      {
        label: '媒体コンバージョン設定',
        to: '/setting/mediaCv',
      },
      {
        label: '目標設定',
        to: '/setting/new-item',
      },
      {
        label: 'アクティビティ履歴',
        to: '/setting/activity',
      },
      {
        label: '権限管理',
        to: '/setting/authority',
      },
    ],
  },
]

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openId: -1,
    };
  }
  render = () => {
    const open = (e) => {
      e.preventDefault();
      const id = parseInt(e.target.dataset.id);
      this.setState({
        openId: this.state.openId === id ? -1 : id
      });
    }

    return (
      <StyledWrap className={this.props.isOpenedSideMenu === true ? "is-opened" : "is-closed"}>
        <StyledToggle onClick={this.props.toggleSideMenu}>
          <StyledToggleArrow className={this.props.isOpenedSideMenu === true ? "is-opened-sidemenu" : "is-closed-sidemenu"} />
        </StyledToggle>
        <Scrollbars>
          <StyledWrapIn>

            <StyledItems
              className = {
                (this.props.isOpenedSideMenu === true)
                  ? "is-opened-sidemenu" : "is-closed-sidemenu"
              }
            >
              {
                (() => {
                  let menuList = MENU;
                  if(outsideSales === "1"){
                    menuList = MENU_OUTSIDE_SALES;
                  }
                  return menuList.map((o, i) => {
                    if (o.children) {
                      // 階層を持つメニュー
                      return (
                        <StyledItem
                          key = { _.uniqueId() }
                          className = { classnames({
                            'is-current': this.props.openGlobalCatId === i
                          }) }
                        >
                          <StyledItemLabel
                            onClick = { open }
                            data-id = { i }
                            className = { classnames({
                              'is-active': this.state.openId === i
                            }) }
                          >
                            { o.label }
                            <StyledButtonToggle
                              tag = 'div'
                              isActive = { this.state.openId === i }
                            />
                          </StyledItemLabel>
                          <StyledItems
                            className = { (this.state.openId === i) ? 'is-opened' : 'is-closed' }
                          >
                            {
                              (() => {
                                // 子供の要素を生成
                                return o.children.map(c => {
                                  if(c.to){
                                    return (
                                      <li
                                        key = { _.uniqueId() }
                                      >
                                        <StyledChildItemLink
                                          to = { c.to }
                                        >
                                          { c.label }
                                          <StyledChildItemIconArrow />
                                        </StyledChildItemLink>
                                      </li>
                                    )
                                  }else{
                                    return (
                                      <li
                                        key = { _.uniqueId() }
                                      >
                                        <StyledLink
                                          href = {c.url}
                                          target = "_blank"
                                        >
                                          { c.label }
                                          <StyledChildItemIconArrow />
                                        </StyledLink>
                                      </li>
                                    )
                                  }
                                })
                              })()
                            }
                          </StyledItems>
                        </StyledItem>
                      )
                    } else {
                      // 階層を持たないメニュー
                      return (
                        <StyledItem
                          key = { _.uniqueId() }
                          className = { classnames({
                            'is-current': this.props.openGlobalCatId === i
                          }) }
                        >
                          <StyledItemLink
                            to = { o.to }
                            className = { classnames({
                              'is-active': this.state.openId === i
                            }) }
                          >
                            { o.label }
                            <StyledButtonSmall
                              as = 'div'
                            >
                              <IconArrow />
                            </StyledButtonSmall>
                          </StyledItemLink>
                        </StyledItem>
                      )
                    }
                  });
                })()
              }
            </StyledItems>
          </StyledWrapIn>
        </Scrollbars>
      </StyledWrap>
    )
  }
};

export default SideMenu;
