import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

import IconDisplay from '../atoms/IconDisplay';
import ButtonClose from '../atoms/ButtonClose';
import ButtonEdit from '../atoms/ButtonEdit';
import DividerDottedV from '../atoms/DividerDottedV';
import FlexBox from '../atoms/FlexBox';
import PullDownListItem from '../atoms/PullDownListItem';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownS from '../molecules/PullDownS';

import COLOR from '../_const/COLOR';

const StyledPullDownProgressItem = styled.div`
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  min-width: 272px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  margin-top: 10px;
  padding-top: 24px;
  padding-bottom: 24px;
  &:first-child {
    margin-top: 0;
  }
`
const StyledPullDownListItem = styled(PullDownListItem)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
const StyledPullDownLabel = styled.button`
  cursor: pointer;
  margin-right: 16px;
  padding: 0;
  border: 0;
  color: ${COLOR.ORANGE};
  font-weight: 600;
  text-align: left;
  background: none;
  &.is-selected {
    color: ${COLOR.DARKGRAY};
  }
`
const StyledDividerDottedV = styled(DividerDottedV)`
  margin-right: 8px;
  margin-left: 8px;
`

class PullDownProgressItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }
  render() {
    if(this.props.isDisplay === false){
      return (<div></div>)
    }else{
      return (
        <StyledPullDownProgressItem
          className = { this.props.className }
        >
          <PullDownS
            onClick = { this.togglePullDown }
          >
            <FlexBox>
              <IconDisplay
                className = 'm-r-8'
              />
              表示項目
            </FlexBox>
          </PullDownS>
          <StyledPullDownListWrap
            className = {
              this.state.isOpenedPullDown === true
                ? `is-opened`
                : `is-closed`
            }
          >
            <StyledPullDownListWrapIn>
              {
                (() => {
                  return this.props.dispItems.map(o => {
                    return (
                      <StyledPullDownListItem
                        key = { _.uniqueId() }
                      >
                        <StyledPullDownLabel
                          value = { o.id }
                          className = {
                            classNames({
                              'is-selected': o.id === this.props.resultListNo
                            })
                          }
                          onClick = { (e) => {
                            this.closePullDown();
                            this.props.setDisplayItem(e);
                          } }
                        >
                          {
                            (() => {
                              return (o.name) ? o.name : `無題${o.id}`
                            })()
                          }
                        </StyledPullDownLabel>
                        <FlexBox>
                          <ButtonEdit
                            value = { o.id }
                            onClick = { (e) => {
                              this.closePullDown();
                              this.props.openDisplayModal(e);
                            } }
                          />
                          <StyledDividerDottedV />
                              <ButtonClose
                                value = { o.id }
                                onClick = { (e) => {
                              this.closePullDown();
                              this.props.initDisplayItem(e);
                            } }
                          />
                        </FlexBox>
                      </StyledPullDownListItem>
                    );
                  });
                })()
              }
            </StyledPullDownListWrapIn>
          </StyledPullDownListWrap>
        </StyledPullDownProgressItem>
      )
    }

  }
}

export default onClickOutside(PullDownProgressItem);
