import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Button from '../../../atoms/Button';
import BarBottom from '../../../atoms/BarBottom';
import StepNavi from '../../../molecules/StepNavi';
import HeadingS from '../../../atoms/HeadingS';
import IconImport from '../../../atoms/IconImport';
import ModalErrorMessage from '../../../organisms/ModalErrorMessage';

import User from '../../../../utils/user';

class ExternalDataUpload2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFile: false,
      funcId: 2,
      isOpenedErrorModal: '',
      errorText: '',
      isImported: false,
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = () => {
    if(!this.props.clientId) {
      this.props.history.push('/setting/external-data');
    }
  }

  handleToPrevPage = () => {
    this.props.history.goBack();
  }

  render() {
    const backendApi = process.env.REACT_APP_BACKEND_URI;
    const IMPORT_URL = backendApi + 'thirdPartyUploadModify';

    // エラーモーダル開閉
    const toggleErrorModal = (boolean) => {
      this.setState({
        isOpenedErrorModal: boolean
      });
    }
    // エラーモーダルの本文を書き換える
    const changeErrorText = (text) => {
      this.setState({
        errorText: text
      });
    }
    const upload = async (e, url, apiParams) => {
      let formElement = document.getElementById("importForm");
      let formdata = new FormData(formElement);
      let cvId;

      e.preventDefault();
      // 外部CV設定IDを取得
      await axios.get(backendApi + 'thirdPartySetting', {
        params: {
          targetIds: this.props.clientId,
          ...apiParams
        }
      })
      .then((response) => {
        if(response.data) {
          cvId = response.data[0].id;
        }
      })
      .catch((error) => {
        console.log(error);
      })

      // アップロード処理
      await axios.post(
        url,
        formdata,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
          params: {
            func: this.state.funcId,
            id: cvId,
            ...apiParams
          }
        }
      )
      .then((response) => {
        this.setState({
          hasFile: false,
          isImported: true
        });
        setTimeout(() => {
          document.importForm.reset();
          formElement = null;
          formdata = null;
        }, 100);
      })
      .catch((error) => {
        if(error.response) {
          let text = (error.response.data.errorMessage ? error.response.data.errorMessage : error.message);
          changeErrorText(text);
          toggleErrorModal(true);
        }
      })
    }

    const checkFile = () => {
      if (document.getElementById("importFormFile").value === '') {
        this.setState({
          hasFile: false
        });
      } else {
        this.setState({
          hasFile: true
        });
      }
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isOpenedErrorModal }
          close = { () => toggleErrorModal(false) }
          bodyText = { this.state.errorText }
        ></ModalErrorMessage>
        <div>
          <StepNavi
            step = { 1 }
            navi = {this.props.navi[`${this.props.isUsingGa ? 1 : 0}`]}
          />
        </div>
        <HeadingS
          className = 'm-t-16'
          >コンバージョン実績取り込み</HeadingS>
        コンバージョン実績を取り込みます。<br />テンプレートに従ってアップロードして下さい。
        <HeadingS
          className = 'm-t-32'
          >●コンバージョン実績をアップロードする。</HeadingS>
        <form
          id = 'importForm'
          encType = 'multipart/form-data'
          name = 'importForm'
          onSubmit = {
            (e) => {
              upload(e, IMPORT_URL, User.apiParams());
            }
          }
        >
          <input
            type = "file"
            name = "file"
            id = "importFormFile"
            className = "m-b-16"
            onChange = { (() => { checkFile() }) }
          />
          <Button
            type = "submit"
            width = "200px"
            justify = "center"
            disabled = { !this.state.hasFile }
          >
            <IconImport
              className = "m-r-8"
            />アップロード
          </Button>
        </form>
        <HeadingS
          className = 'm-t-32'
          >コンバージョン実績のテンプレートは<a href="https://mmerge.jp/cv.csv">こちら</a></HeadingS>
        <HeadingS
          className = 'm-t-32'
          >&lt;POINT&gt;</HeadingS>
        <p>ファイルを間違えてアップロードした場合は<br />再度アップロードして下さい。上書きします。</p>
        <HeadingS
          className = 'm-t-32'
          >&lt;正しくアップロードできなかった場合&gt;</HeadingS>
        <p>ファイルが正しくないです。<br />テンプレートの内容、ファイル形式が<br />間違っていないか確認して下さい。</p>
        <BarBottom>
          <Button
            onClick = { this.handleToPrevPage }
          >
            前に戻る
          </Button>
          {
            (() => {
              if (this.state.isImported) {
                return (
                  <Button
                    as = { Link }
                    to = { '/setting/external-data/edit' }
                    color="orange"
                    className = 'm-l-8'
                  >
                    次に進む
                  </Button>
                )
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default ExternalDataUpload2;
