import React from 'react'
import axios from 'axios';
import styled from 'styled-components'
import { Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import User from '../../../utils/user'
import ModalAlertLogout from '../../organisms/ModalAlertLogout';
import logo_medix from '../../../img/logo_medix.svg'
import logo from '../../../img/logo.png'
import img from '../../../img/bg.jpg'
import imgOutsideSales from '../../../img/bg_outsidesales.jpg'
import imgContact from '../../../img/contact_logo.png'
import InputText from '../../atoms/InputText';
import ModalErrorMessage from '../../organisms/ModalErrorMessage';

// const StyledSection = styled.section`
//   margin-top: 28vh;
//   margin-left: auto;
//   margin-right: auto;
//   width: 50vw;
// `

// const list = [
//   {id: 0, userId: 'hasebe', rcode: 53135458969141},
//   {id: 1, userId: 'kamijo', rcode: 53135458969142},
//   {id: 2, userId: 'miura', rcode: 53135458969143},
// ]

const StyledLogin = styled.section`
.wrapper {

  width: 100%;
  min-width: 1000px;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* ログインフォーム */
.form_group {
  text-align: center;
}

.form_area {
  width: 380px;
  height: 140px;
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
}

/* ログインボタン */
.login_btn {
  width: 346px;
  height: 58px;
  border: 1px solid #d25553;
  border-radius: 5px;
  color: #fff;
  background-color: #e36f70;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
}

.login_btn:hover {
  opacity: .7;
}

.version_text {
  margin-top:15px;
  color: #fff;
  font-size: 14px;
  text-align:center;
}
`

const StyledLoginOutsideSales = styled.section`
.wrapper {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* 背景画像 */
.mv_bg {
  width: 50%;
  height: 100vh;
  background-image: url(${imgOutsideSales});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.form_group {
  width: 50%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form_area {
  width: 460px;
  text-align: left;
}

.form_area .rakuda_log {
  text-align: center;
}

.form_area .textarea_gray, .form_area .login_btn {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

/* チェックボックス */
.checkbox-input {
  display: none;
}

.checkbox-parts {
  font-size: 14px;
  padding-left: 25px;
  position: relative;
}

.checkbox-parts::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #999;
  border-radius: 2px;
  background-color: #f5f5f5;
}

.checkbox-input:checked + .checkbox-parts::after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 4px;
  width: 5px;
  height: 9px;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  border-bottom: 3px solid #4d4d4d;
  border-right: 3px solid #4d4d4d;
}

/* テキストエリア */
input[type=text]:focus {
  color: #000;
}

.form_text {
  font-size: 20px;
  font-weight: bold;
  color: #4d4d4d;
}

.textarea_gray {
  width: 100%;
  padding: 10px 0.5em;
  border-bottom: solid 1px #d9d9d9;
  background-color: #f5f5f5;
  font-size: 15px;
}

.textarea_gray::-webkit-input-placeholder {
  color: #c4c4c4;
}

.textarea_gray:-ms-input-placeholder {
  color: #c4c4c4;
}

.textarea_gray::-ms-input-placeholder {
  color: #c4c4c4;
}

.textarea_gray::placeholder {
  color: #c4c4c4;
}

/* ログインボタン */
.login_btn {
  display: block;
  margin: 0 auto 20px;
  width: 300px;
  height: 50px;
  border-bottom: solid 2px #e18868;
  border-radius: 3px;
  color: #fff;
  background-color: #f79572;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
}

.login_btn:hover {
  opacity: .7;
}

/* お問い合わせテキスト */
.title-border {
  margin: 0 auto 8px;
  width: 300px;
  font-size: 14px;
  color: #9b9b9b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.title-border:before, .title-border:after {
  border-top: 1px solid;
  content: "";
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.title-border:before {
  margin-right: 1rem;
}

.title-border:after {
  margin-left: 1rem;
}

/* お問い合わせボタン */
.contact_btn {
  margin: 0 auto;
  width: 300px;
  padding: 0.8em;
  display: block;
  color: #000 !important;
  text-decoration: none;
  border: thin solid #4d4d4d;
  font-size: 14px;
  border-radius: 3px;
  text-align: center;
}

.contact_btn:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px 0 0;
  background: url(${imgContact}) no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.contact_btn:hover {
  background: #f5f5f5;
}
.mgb-10 {
  margin-bottom: 10px !important;
}

.mgb-15 {
  margin-bottom: 15px !important;
}

.mgb-20 {
  margin-bottom: 20px !important;
}

.mgb-25 {
  margin-bottom: 25px !important;
}

.mgb-30 {
  margin-bottom: 30px !important;
}

.mgb-40 {
  margin-bottom: 40px !important;
}
.version_text {
  margin-top:15px;
  color: #000;
  font-size: 14px;
  text-align:center;
}
`

const ssoApi = process.env.REACT_APP_REDIRECT_URI;
const backendApi = process.env.REACT_APP_BACKEND_URI;
const outsideSales = process.env.REACT_APP_OUTSIDE_SALES;

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      canLogin: false,
      id: '',
      code: 0,
      isOpenedModal: true,
      hasCode: false,
      loginId: '',
      password: '',
    }
  }

  componentDidMount = async () => {
    await this.getData();
  }

  // モーダルを閉じる
  closeModal = () => {
    this.setState({
      isOpenedModal: false
    });
  }
  // エラーモーダルを閉じる
  closeErrorModal = () => {
    this.setState({
      isOpenedErrorModal: false
    });
  }
  
  // ログインURLへ
  login = () => {
    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=638637836124-gb80jboa86pfpafmbnah71dk550gfm10.apps.googleusercontent.com&response_type=code&scope=email%20profile&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT}`;
  }

  // ログインURLへ
  loginOutsideSales = () => {
    if(this.state.loginId === "" || this.password === ""){
      this.setState({
        errorText: "ID／パスワードを入力してください",
        isOpenedErrorModal: true
      });
      return;
    }
        // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'login', {
      params: {
        userId: this.state.loginId,
        password: this.state.password,
      }
    })
    .then((response) => {
      // ユーザーデータが取得できた羅local strageに保存してトップ画面へ
      // ssoのようにバックエンドAPIからフロントのトップへリダイレクトさせようとするとAccess-Control-Allow-Originのエラーになったのでこの方法にした
      let user  = User.featch()
      user.id   = response.data.userId
      user.code = response.data.authCode
      User.save(user)
      this.setState({
        hasCode: true
      });
      
      window.location.href = '/';
    })
    .catch((error) => {
      if(error.response) {
        //console.log(error.response.data.errorMessage);
        this.setState({
          errorText: error.response.data.errorMessage,
          isOpenedErrorModal: true
        });
      }
    });
  }

  // switchUser = e => {
  //   const i = e.target.value
  //   let user  = User.featch()
  //   user.id   = list[i].userId
  //   user.code = list[i].rcode
  //   User.save(user)
  //   this.setState({canLogin:true, id: user.id, code: user.code})
  //   // Redirect
  // }

  getData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(ssoApi, {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      // クエリパラメータがある場合、認証情報を取得してlocal strageにセット
      const queries = queryString.parse(window.location.search);
      if(queries.userId) {
        let user  = User.featch()
        user.id   = queries.userId
        user.code = queries.rcode
        User.save(user)
        this.setState({
          hasCode: true
        });
      }
    })
    .catch(() => {
    });
  }

  logOut = e => {
    e.preventDefault()
    User.detach()
    this.setState({canLogin:false, id:'', code:''})
  }

  render() {
    const queries = queryString.parse(window.location.search);

    // クエリパラメータがないならログインリンク、あるなら進捗画面へのリンクを表示
    if(outsideSales === "0"){
      // 社内用
      if(!queries.rcode){
        return (
          <StyledLogin>
            <ModalAlertLogout
              heading = 'ログアウトされました'
              isOpened = { this.state.isOpenedModal && this.props.isForceLogout }
              close = { this.closeModal }
              bodyText = 'もう一度ログインしてください'
            />
            <ModalAlertLogout
              heading = '未ログイン状態です'
              isOpened = { this.state.isOpenedModal && this.props.isNoticeLogout && !this.props.isForceLogout }
              close = { this.closeModal }
              bodyText = 'ログインしてください'
            />
  
              <div className="wrapper" id="anchor-wrapper">
                <div className="form_group">
                  <figure className="mgb-25">
                    <img src={logo_medix} alt="メディックス" />
                  </figure>
                  <div className="form_area">
                    <figure className="mgb-30">
                      <img src={logo} alt="MMerge" />
                    </figure>
                    <form method="post" action="">
                      <button type="button" onClick={ this.login } className="login_btn">Googleアカウントでログイン</button>
                    </form>
                  </div>
                  <div className="version_text">Ver 1.00 推奨環境：Google Chrome,Safari,Firefox,Edge最新バージョン</div>
                </div>
                {/*<p><a href={`https://accounts.google.com/o/oauth2/auth?client_id=638637836124-gb80jboa86pfpafmbnah71dk550gfm10.apps.googleusercontent.com&response_type=code&scope=email%20profile&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT}`}>Googleアカウントでログイン</a></p> */}
              </div>
              {/* <p><a href="https://accounts.google.com/o/oauth2/auth?client_id=638637836124-gb80jboa86pfpafmbnah71dk550gfm10.apps.googleusercontent.com&response_type=code&scope=email%20profile&redirect_uri=http://localhost:8080/medix_innovator/sso">Googleアカウントでログイン(local)</a></p> */}
          </StyledLogin>
        )
      }else if(this.state.hasCode) {
        return (  
          <Route
            render = { () => {
              return (
                <Redirect
                  to = {{
                    pathname: '/',
                  }}
                />
              )
            } }
          />
        )
      }else{
        return (<div></div>);
      }
    }else{
      // 外販用
      if(!queries.rcode){
        return (
          <StyledLoginOutsideSales>
            <ModalAlertLogout
              heading = 'ログアウトされました'
              isOpened = { this.state.isOpenedModal && this.props.isForceLogout }
              close = { this.closeModal }
              bodyText = 'もう一度ログインしてください'
            />
            <ModalAlertLogout
              heading = '未ログイン状態です'
              isOpened = { this.state.isOpenedModal && this.props.isNoticeLogout && !this.props.isForceLogout }
              close = { this.closeModal }
              bodyText = 'ログインしてください'
            />
            <ModalErrorMessage
              heading = 'エラー'
              isOpened = { this.state.isOpenedErrorModal }
              close = { this.closeErrorModal }
              bodyText = { this.state.errorText }
            ></ModalErrorMessage>

              <div className="wrapper" id="anchor-wrapper">
                <div className="form_group">
                  <div className="form_area">
                    <figure className="mgb-30">
                      <img src={logo} alt="MMerge" />
                    </figure>
                    <form method="post" action="">
                      <p className="form_text">ID</p>
                      <InputText
                        type = "text"
                        value = { this.state.loginId }
                        className = "textarea_gray mgb-20"
                        placeholder="ID"
                        onChange = { ((e) => {
                          let targetObject = this.state.loginId;
                          targetObject = e.currentTarget.value;
                          this.setState({
                            loginId: targetObject
                          })
                        }) }
                      />
                      <p className="form_text">Password</p>
                      <InputText
                        type = "password"
                        value = { this.state.password }
                        className = "textarea_gray mgb-20"
                        placeholder="Password"
                        onChange = { ((e) => {
                          let targetObject = this.state.password;
                          targetObject = e.currentTarget.value;
                          this.setState({
                            password: targetObject
                          })
                        }) }
                      />
                      {/* <div className="mgb-40">
                          <label>
                              <input type="checkbox" name="login" className="checkbox-input" />
                              <span className="checkbox-parts">ログイン情報を保持する</span>
                          </label>
                      </div> */}
                      <button type="button" onClick={ this.loginOutsideSales } className="login_btn">ログイン</button>
                    </form>
                    <p className="title-border">パスワードを忘れた方へ</p>
                    <a href="#" className="contact_btn">お問い合わせ</a>
                    <div className="version_text">Ver 1.00 推奨環境：Google Chrome,Safari,Firefox,Edge最新バージョン</div>
                  </div>
                </div>
                <div className="mv_bg"></div>
              </div>
          </StyledLoginOutsideSales>
        )
      }else if(this.state.hasCode) {
        return (
          <Route
            render = { () => {
              return (
                <Redirect
                  to = {{
                    pathname: '/',
                  }}
                />
              )
            } }
          />
        )
      }else{
        return (<div></div>);
      }

    }


    // return (
    //   <StyledSection>
    //     <select name="" onChange={this.switchUser}>
    //       {list.map((user, i) => {
    //         return <option value={user.id} key={i + user.id + user.userId}>{user.userId}</option>
    //       })}
    //     </select>
    //     <h4>
    //       <button
    //         onClick = {this.logOut}
    //       >
    //         Logout
    //       </button>
    //     </h4>
    //     {this.state.canLogin? <p><Link to="/progress/1">進捗管理へ</Link></p>:<p>you need to select.</p>}
    //     <p>user: {this.state.id}</p>
    //     <p>code: {this.state.code}</p>
    //   </StyledSection>
    // )
  }
}

export default Login
