import React from 'react';
import styled from 'styled-components';

import FlexBox from '../atoms/FlexBox';

import COLOR from '../_const/COLOR'

const StyledArrow = styled.span`
  display: block;
  margin: 0 8px;
`;
const StyledLabel = styled.span`
  display: block;
  font-weight: ${props => props.current ? 'bold' : 'normal'};
  position: relative;
  &::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    height: 4px;
    width: 100%;
    content: '';
    background: ${props => props.current ? `${COLOR.ORANGE}` : 'none'};
  }
`;

const StepNavi = (props) => (
  <FlexBox
    justify = 'flex-end'
    style = {{ paddingBottom: '4px' }}
  >
    {
      (() => {
        return props.navi.map((label,step) => {
          let arrow = '';
          if(step !== 0) arrow = '>';
          return (
            <FlexBox
              key = { `nav${step}` }
            >
              <StyledArrow>{ arrow }</StyledArrow>
              <StyledLabel
                current = { step === props.step }
              >{ label }</StyledLabel>
            </FlexBox>
          )
        })
      })()
    }
  </FlexBox>
);

export default StepNavi;
