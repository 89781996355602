import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';
import Url from 'url-parse';

import FlexBox from '../atoms/FlexBox';
import PullDownListItem from '../atoms/PullDownListItem';
import PullDownListWrap from '../atoms/PullDownListWrap';
import PullDownListWrapIn from '../atoms/PullDownListWrapIn';
import PullDownMore from '../molecules/PullDownMore';
import IconExport from '../atoms/IconExport';
import IconImport from '../atoms/IconImport';

const StyledPullDownWrap = styled.div`
  position: relative;
`
const StyledPullDownListWrap = styled(PullDownListWrap)`
  white-space: nowrap;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 20;
`
const StyledPullDownListWrapIn = styled(PullDownListWrapIn)`
  padding-top: 24px;
  padding-bottom: 24px;
`

class PullDownExportAndImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenedPullDown: false,
    };
  }
  handleClickOutside(e) {
    this.closePullDown(e);
  }
  togglePullDown = () => {
    this.setState({
      isOpenedPullDown: !this.state.isOpenedPullDown
    });
  }
  closePullDown = () => {
    this.setState({
      isOpenedPullDown: false
    });
  }


  render() {
    // ダウンロード
    const download = async (e, baseUrl, params) => {
      e.preventDefault();
      const url = new Url(baseUrl, true);
      url.set('query', params);
      var element = document.createElement('a');
      element.href = url.toString();
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    return (
      <StyledPullDownWrap
        className = { this.props.className }
      >
        <PullDownMore
          onClick = {this.togglePullDown}
        />
        <StyledPullDownListWrap
          className = {
            this.state.isOpenedPullDown === true
              ? `is-opened`
              : `is-closed`
          }
        >
          <StyledPullDownListWrapIn>
            {
              (() => {
                return this.props.items.map((item) => {
                  return (
                    <PullDownListItem
                      key = { _.uniqueId() }
                      onClick = {
                        ((e) => {
                          if(item.type === 'export') {
                            download(e,item.url, Object.assign(this.props.apiParams, item.param));
                          } else if(item.type === 'importModal') {
                            this.props.openImportModal();
                          } else if(item.type === 'exportModal'){
                            this.props.openExportModal();
                          }
                          this.closePullDown();
                        })
                      }
                    >
                      <FlexBox>
                        { item.label }{
                          item.type === 'import'
                          ? <IconImport
                              className = "m-l-8"
                            />
                          : item.type === 'export'
                            ?  <IconExport
                                  className = "m-l-8"
                                />
                            : ''
                        }
                      </FlexBox>
                    </PullDownListItem>
                  )
                });
              })()
            }
          </StyledPullDownListWrapIn>
        </StyledPullDownListWrap>
      </StyledPullDownWrap>
    )
  }
}

export default onClickOutside(PullDownExportAndImport);
