import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import classnames from 'classnames';
import queryString from 'query-string';
import InputText from '../../atoms/InputText';
import Textarea from '../../atoms/Textarea';
import IconAttention from '../../atoms/IconAttention';

import Checkbox from '../../atoms/Checkbox';
import FlexBox from '../../atoms/FlexBox';
import TableDataCell from '../../atoms/TableDataCell';
import TableBodyRow from '../../atoms/TableBodyRow';
import TableHeader from '../../atoms/TableHeader';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import BarModifyData from '../../molecules/BarModifyData';
import ModalInfoMessage from '../../organisms/ModalInfoMessage';

import User from '../../../utils/user';

const TABLE_HEADER = [
  {
    id: 'clientName',
    label: 'クライアント名',
    width: '300px',
    hasSort: false,
  },
  {
    id: 'accountName',
    label: 'アカウント名',
    width: '300px',
    hasSort: false,
  },
  {
    id: 'media',
    label: '媒体',
    width: '150px',
    hasSort: false,
  },
  {
    id: 'mediaId',
    label: '媒体ID',
    width: '300px',
    hasSort: false,
  },
  {
    id: 'multiCvNames',
    label: '取り込む媒体コンバージョンポイント',
    width: '400px',
    hasSort: false,
  },
  {
    id: 'imcCvNames',
    label: 'コンバージョンポイント',
    width: '400px',
    hasSort: false,
  },
];

const StyledTable = styled.div`
  width: 2300px;
  overflow: auto;
  position: relative;
  z-index: 1;
`

const backendApi = process.env.REACT_APP_BACKEND_URI;

class MediaCv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOriginal: [],
      dataFiltered: [],
      data: [],
      modifyData: [], // 変更データを格納する配列
      offset: 0,
      postPerPage: 5000,
      searchQuery: '',
      checked: [],
      targetIds: '',
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('媒体コンバージョン設定');
    props.toggleDatePicker(false);
  }
  componentDidMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    await this.getData();
    this.arrangeViewItems();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getData = () => {
    // URIのクエリパラメータにclientIdが存在するかどうかを判断し、
    // 存在する場合は情報を取得してsetStateする。
    const queries = queryString.parse(window.location.search);

    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'dispItemMgmt', {
      params: {
        ...User.apiParams(),
        targetIds: queries.targetIds,
        mediaCv: 1,
      }
    })
    .then((response) => {
      this.setState({
        dataOriginal: response.data,
        dataFiltered: response.data,
        data: response.data,
        modifyData: [],
        targetIds: queries.targetIds,
      });
    })
    .catch((error) => {
      this.setState({
        isOpenedErrorModal: true,
        errorText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
      });
    });
  }
  arrangeViewItems = () => {
    // ソートやページャの条件に従って表示するレポートデータを別途作成する。
    const { offset, postPerPage } = this.state;

    // 元データを指定の条件でフィルタする
    const filteredData = this.state.dataOriginal.filter((item, index) => {
      return (
        (
          // フリーワード検索のクエリに合致するか
          this.state.searchQuery === ''
          || item.name.indexOf(this.state.searchQuery) > -1
        )
      )
    });

    // データのソートには_.orderBy()を使う。
    const sortedDataTable = _.orderBy(
      filteredData,
      o => {
        return o[this.state.sortId];
      },
      this.state.sortOrder
    );

    // ページャ条件による絞り込みにはArray.slice()を使う。
    const arrangedDataTable = sortedDataTable.slice(offset * postPerPage, offset * postPerPage + postPerPage);

    this.setState({
      dataFiltered: filteredData,
      data: arrangedDataTable,
    });
  }
  render() {

    // 並び替え
    const sort = async (e) => {
      // セルの並び替え
      const id = e.currentTarget.dataset.id;
      const order = (id === this.state.sortId && this.state.sortOrder === 'desc') ? 'asc' : 'desc';

      await this.setState({
        sortId: id,
        sortOrder: order,
      })
      this.arrangeViewItems();
    }

    // 各行のチェック
    const checkItem = (e) => {
      const id = e.currentTarget.value;
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        checked.splice(checked.indexOf(id), 1)
      } else {
        checked.push(id);
      }
      this.setState({
        checked: checked
      });
    }

    // 同じ行のチェックボックスにチェックを付ける
    const checkboxChecked = (id) => {
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        // 既にあるなら何もしない
      } else {
        // ないなら追加
        checked.push(id);
      }
      this.setState({
        checked: checked
      });
    }

    // 予算変更処理
    const modify = async () => {
      // プログレス表示
      this.props.startProgressing();

      // チェックのついているデータを処理する
      let modifyDataList = [];
      for (var i = 0; i < this.state.data.length; i++) {
        if(this.state.checked.indexOf(this.state.data[i].mediaId) > -1){
          modifyDataList.push(this.state.data[i]);
        }
      }

      await axios.get(backendApi + 'dispItemMgmtModify', {
        params: {
          func: 1,
          ...User.apiParams(),
          ...modifyDataList,
          dataCount: modifyDataList.length,
        }
      })
      .then((response) => {
        //　プログレス非表示
        this.props.endProgressing();
        // 一覧へ戻る
        returnList();
      });

    }
    // 一覧へ戻る処理
    const returnList = async () => {
      this.setState({
        modifyData: [],
        checked: [],
      });
    }
    // infoモーダルを開く
    const openInfoModal = (text) => {
      this.setState({
        isOpenedInfoModal: true,
        infoText: text
      });
    }
    // infoモーダルを閉じる
    const closeInfoModal = () => {
      this.setState({
        isOpenedInfoModal: false
      });
    }

      // 一覧画面
      return (
        <div>
        <ModalInfoMessage
          heading = 'info'
          isOpened = { this.state.isOpenedInfoModal }
          close = { closeInfoModal }
          bodyText = { this.state.infoText }
        ></ModalInfoMessage>

          <div>
            <p>こちらは媒体提供の計測タグで複数ポイントを計測している場合の、レポートに取り込む範囲を決める画面です</p>
            <p>コンバージョンの表記は媒体側で取得しているものがそのまま踏襲されます</p>
            <p>各媒体で取得しているコンバージョンポイントが表示されていますので、</p>
            <p>レポートに入れたい項目をコピーして記載してください</p>
          </div>
          <BarModifyData
            count = { this.state.checked.length }
            onClickConfirm = { modify }
          />
          <StyledTable
            windowHeight = { this.props.windowHeight }
          >
            <TableHeader>
              <TableHeaderCell
                className = { 'has-checkbox' }
              />
              {
                (() => {
                  return TABLE_HEADER.map(o => {
                    return (
                      <TableHeaderCell
                        key = { _.uniqueId() }
                        style = { { width: `${o.width}` } }
                        sortState = {
                          (o.hasSort === false)
                            ? undefined
                            : (o.id !== this.state.sortId)
                              ? 0
                              : (this.state.sortOrder === 'asc')
                                ? 1
                                : 2
                        }
                        data-id = { o.id }
                        onClick = { sort }
                      >
                        { o.label }
                      </TableHeaderCell>
                    )
                  })
                })()
              }
            </TableHeader>
            <div>
              {
                (() => {
                  return this.state.data.map((r, i) => {
                    return (
                      <TableBodyRow
                        // key = { _.uniqueId() }
                        key = { `itemTableRow_${i}` }
                      >
                        <TableDataCell
                          className = { 'has-checkbox' }
                        >
                          <Checkbox
                            value = { r.mediaId }
                            onClick = { checkItem }
                            isChecked = { this.state.checked.indexOf(r.mediaId) > -1 }
                          />
                        </TableDataCell>
                        {
                          (() => {
                            return TABLE_HEADER.map(o => {
                              const label = (o.id === 'category') ? r[o.id].label : r[o.id];
                              return (
                                <TableDataCell
                                  style = { { width: `${o.width}` } }
                                  className = {
                                    classnames({
                                      'jc-c': o.id === 'editBudget' || o.id === 'startDate'
                                    })
                                  }
                                >
                                  {
                                    (() => {
                                      if (o.id === 'budget') {
                                        return (
                                          <FlexBox>
                                          <InputText
                                            type = "number"
                                            value = { this.state.data[i].budget }
                                            className = "m-r-8"
                                            onChange = { ((e) => {
                                              // let targetObject = _.find(this.state.data, function(o) { return o.mediaId === r.mediaId; });
                                              // targetObject.editBudget = e.currentTarget.value;
                                              let targetObject = this.state.data;
                                              targetObject[i].budget = e.currentTarget.value;
                                              checkboxChecked(r.mediaId);

                                              this.setState({
                                                data: targetObject,
                                              });
                                            }) }
                                          />
                                          円
                                        </FlexBox>
                                        )
                                      } else if (o.id === 'targetCv') {
                                        return (
                                          <FlexBox>
                                          <InputText
                                            type = "number"
                                            value = { this.state.data[i].targetCv }
                                            className = "m-r-8"
                                            onChange = { ((e) => {
                                              let targetObject = this.state.data;
                                              targetObject[i].targetCv = e.currentTarget.value;
                                              checkboxChecked(r.mediaId);
                                              
                                              this.setState({
                                                data: targetObject,
                                              });
                                            }) }
                                          />
                                        </FlexBox>
                                        )
                                      } else if (o.id === 'targetCpa') {
                                        return (
                                          <FlexBox>
                                          <InputText
                                            type = "number"
                                            value = { this.state.data[i].targetCpa }
                                            className = "m-r-8"
                                            onChange = { ((e) => {
                                              let targetObject = this.state.data;
                                              targetObject[i].targetCpa = e.currentTarget.value;
                                              checkboxChecked(r.mediaId);

                                              this.setState({
                                                data: targetObject,
                                              });
                                            }) }
                                          />
                                          円
                                        </FlexBox>
                                        )
                                      } else if (o.id === 'costOfSales') {
                                        return (
                                          <FlexBox>
                                          <InputText
                                            type = "number"
                                            value = { this.state.data[i].costOfSales }
                                            className = "m-r-8"
                                            onChange = { ((e) => {
                                              let targetObject = this.state.data;
                                              targetObject[i].costOfSales = e.currentTarget.value;
                                              checkboxChecked(r.mediaId);
                                              
                                              this.setState({
                                                data: targetObject,
                                              });
                                            }) }
                                          />
                                          円
                                        </FlexBox>
                                        )
                                      } else if (o.id === 'mediaId') {
                                        if(this.state.data[i].media === '-'){
                                          // クライアントの場合
                                          return '-';
                                        }else{
                                          // アカウントの場合
                                          return label;
                                        }
                                      } else if (o.id === 'multiCvNames') {
                                        if(this.state.data[i].media === '-'){
                                          // クライアントの場合
                                          return (
                                            <FlexBox>
                                            <Textarea
                                              disabled="disabled"
                                              placeholder="右のコンバージョンポイントより、取得したい項目をコピーし入力してください(複数ある場合は改行して入力)※最大20個まで。クライアントには入力不可"
                                              width="400"
                                            />
                                            </FlexBox>
                                          )
                                        }else{
                                          // アカウントの場合
                                          return (
                                            <FlexBox>
                                            {/* <StyledInfoItemDate
                                            onClick = {
                                              (() => {
                                                openInfoModal(r.imcCvNames);
                                              })
                                            }
                                            >
                                              <IconAttention className="m-r-8" />
                                              
                                            </StyledInfoItemDate> */}
                                            <Textarea
                                              value = { this.state.data[i].multiCvNames }
                                              className = "m-r-8"
                                              width="400"
                                              placeholder="右のコンバージョンポイントより、取得したい項目をコピーし入力してください(複数ある場合は改行して入力)※最大20個まで"
                                              onChange = { ((e) => {
                                                let targetObject = this.state.data;
                                                targetObject[i].multiCvNames = e.currentTarget.value;
                                                checkboxChecked(r.mediaId);
                                                
                                                this.setState({
                                                  data: targetObject,
                                                });
                                              }) }
                                            />
                                            </FlexBox>
                                          )
                                        }
                                      } else if (o.id === 'imcCvNames') {
                                        return (
                                          <FlexBox>
                                          <Textarea
                                            value = { this.state.data[i].imcCvNames }
                                            className = "m-r-8"
                                            width="400"
                                            disabled="disabled"
                                          />
                                          </FlexBox>
                                        )
                                      } else {
                                        return label;
                                      }
                                    })()
                                  }
                                </TableDataCell>
                              )
                            })
                          })()
                        }

                      </TableBodyRow>
                    )
                  })
                })()
              }
            </div>
          </StyledTable>
        </div>
      )
  }
}

export default MediaCv;
