import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import Radio from '../../../atoms/Radio';
import BarBottom from '../../../atoms/BarBottom';
import Button from '../../../atoms/Button';
import HeadingS from '../../../atoms/HeadingS';
import TableSimple from '../../../atoms/TableSimple'

import User from '../../../../utils/user';

const StyledRadio = styled(Radio)`
  margin-top: 8px;
`;
const StyledRadioWrap = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

const backendApi = process.env.REACT_APP_BACKEND_URI;

class ExternalDataGa extends React.Component {
  constructor(props) {
    super(props);
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = () => {
    if(!this.props.clientId) {
      this.props.history.push('/setting/external-data');
    }
  }

  handleToPrevPage = () => {
    this.props.history.goBack();
  }

  handleToNextPage  = async () => {
    this.props.setNewData(true);
    if(this.props.isUsingGa) {
      // gaを使用する場合はGA指標選択へ
      this.props.history.push('/setting/external-data/ga-id')
    } else {
      // gaを使用しない場合はID登録してからデータマッピングへ
      await axios.get(backendApi + 'thirdPartySettingModify', {
        params: {
          func: 1,
          clientId: this.props.clientId,
          ...User.apiParams(),
        }
      }).then((response) => {
        this.props.history.push('/setting/external-data/edit');
      }).catch(error => {
        console.log(error);
      })
    }
  }

  render() {
    return (
      <div>
        <HeadingS>外部データ連携の方法について</HeadingS>
        <p>外部データの連携を設定するにあたり、最初にGoogleAnalyticsAPIを用いるかどうかを設定します</p>
        <p>以下の2つからご利用のケースを選択してください</p>
        <StyledRadioWrap>
          <StyledRadio
            key = { 'radio_ga_use_true' }
            name = { 'ga-use' }
            id = { `ga-use-use_true` }
            value = { true }
            checked = { this.props.isUsingGa === true }
            onChange = { (() => {
              this.props.setUsingGa(true);
            }) }
          >GoogleAnalyticsからコンバージョン実績を【自動で取得】し、レポート反映したい
          </StyledRadio>
          <StyledRadio
            key = { 'radio_ga_use_false' }
            name = { 'ga-use' }
            id = { `ga-use-use_false` }
            value = { true }
            checked = { this.props.isUsingGa === false }
            onChange = { (() => {
              this.props.setUsingGa(false);
            }) }
          >上記以外のケース。GoogleAnalyticsを活用してるがコンバージョン実績を【手動で任意の形】でアップロードしたい。または【GoogleAnalytics以外】の効果測定ツールを利用している
          </StyledRadio>
        </StyledRadioWrap>

        {/* <HeadingS
          className = 'm-t-32'
          >＜POINT＞
        </HeadingS>
        <div>外部データ連携は、2パターンあります。<br />ご自分の運用状況に合ったものを選択して下さい。</div>
        <TableSimple
          widthTh = { '200' }
          colNum = { 3 }
          className = 'm-t-16'
          >
          <thead><tr><th></th><th>GA連携</th><th>管理表マスター連携</th></tr></thead>
          <tbody>
            <tr><th>広告情報の紐づけ</th><td>自動</td><td>手動</td></tr>
            <tr><th>CV実績の紐づけ</th><td>自動</td><td>手動</td></tr>
            <tr><th>メリット</th><td>すべて自動処理</td><td>カスタマイズができる</td></tr>
            <tr><th>デメリット</th><td>自動処理のため、決められたルールで計測用パラメーターをふる必要がある。</td><td>広告情報やCV実績を手動で上げる必要がある。</td></tr>
          </tbody>
        </TableSimple> */}
        <BarBottom>
          <Button
            onClick = { this.handleToPrevPage }
          >
            前に戻る
          </Button>
          {
            (() => {
              if (this.props.isUsingGa !== null) {
                return (
                  <Button
                    color="orange"
                    className = 'm-l-8'
                    onClick = { this.handleToNextPage }
                  >
                    次に進む
                  </Button>
                )
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default withRouter(ExternalDataGa);
