import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { format, isEqual } from 'date-fns';

import DividerContent from '../atoms/DividerContent';
import DividerDottedVWide from '../atoms/DividerDottedVWide';
import DividerDoubleH from '../atoms/DividerDoubleH';
import FlexBox from '../atoms/FlexBox';
import HeadingS from '../atoms/HeadingS';
import IconApps from '../atoms/IconApps';
import IconAttention from '../atoms/IconAttention';
import LabelCorrect from '../atoms/LabelCorrect';
import LabelIncorrect from '../atoms/LabelIncorrect';
import OccurredAlertItem from '../atoms/OccurredAlertItem';
import Graph from '../organisms/Graph';
import ReportTable from '../organisms/ReportTable';
import ModalInfoMessage from '../organisms/ModalInfoMessage';

import User from '../../utils/user';

const outsideSales = process.env.REACT_APP_OUTSIDE_SALES;

const dateFormat = 'YYYY-MM-DD';

const StyledColumnWrap = styled.div`
  display: flex;
  margin-top: -40px;
  margin-bottom: -32px;
`
const StyledColumn = styled.div`
  width: calc(50% - 40px);
  position: relative;
  margin-top: 40px;
  margin-bottom: 32px;
`
const StyledLinkLower = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  color: #000;
  text-decoration: none;
`
const StyledInfoItem = styled.dl`
  margin-top: 16px;
  dd{
    margin-left: 0;
  }
`
const StyledInfoItemDate = styled.dt`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  color: #b2b2b2;
  list-style: none;
  cursor:pointer;
`
const StyledInfoItemText = styled.dt`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  list-style: none;
  cursor:pointer;
`

const StyledStatusItem = styled.div`
  width: calc(100% / 6 - 24px * 5 / 6);
  margin-top: 24px;
  margin-left: calc(24px * 5 / 6);
  &:nth-of-type(6n+1) {
    margin-left: 0;
  }
`
const StyledLink = styled.a`
box-sizing: border-box;
display: block;
position: relative;
margin-top: 16px;
text-decoration: none;
`

const backendApi = process.env.REACT_APP_BACKEND_URI;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
      information: [],
      dataTableHeader: [ // 表示項目
        { id: 'clientName', label: 'クライアント名' },
        { id: 'alertInfo', label: 'アラート' },
        { id: 'cost_with_fee', label: '費用(込)' },
        { id: 'estimateCost_with_fee', label: '着地予測(込)' },
        { id: 'mediaBudget_with_fee', label: '媒体予算(込)' },
        { id: 'mediaBugetDigestibility_with_fee', label: '媒体予算消化率(込)' },
        { id: 'budget_with_fee', label: 'ヨミ予算(込)' },
        { id: 'bugetDigestibility_with_fee', label: 'ヨミ予算消化率(込)' },
        { id: 'cv', label: 'CV数' },
        { id: 'cvr', label: 'CVR' },
        { id: 'cpa_with_fee', label: 'CPA(込)' },
      ],
      dataTableOriginal: [], // レポートデータ（Table用）
      dataTable: [], // レポートデータ（Table用）を表示用に間引きしたもの
      dataTableFooter: null, // 比較元データの合計行（Table用）
      dataGraph: [], // 比較元データ（グラフ用）
      dataGraphCompare: [], // 比較データ（グラフ用）
      graphTargetId: 1, // 1:クライアント、2:アカウント
      graphItemId: 0, // グラフを描画するクライアントorアカウントのID
      graphDivision: 1, // グラフの分割ID 1:日別、2:週別、3:月別
      graphCandidate: [],
      requestParam: {
        // 必須項目
        // ---
        target:  1, // レポートデータの対象 1:クライアント、2:アカウント、3:キャンペーン、4:広告グループ、5:キーワード
        sDate:   format(props.dateRange.startDate, dateFormat),        // レポートデータ取得開始日 2018-01-01
        eDate:   format(props.dateRange.endDate, dateFormat),          // レポートデータ取得終了日 2018-01-01
        sDateC:  format(props.dateRangeCompare.startDate, dateFormat), // レポートデータ取得開始日（比較用）※比較する場合は必須 2018-01-01
        eDateC:  format(props.dateRangeCompare.endDate, dateFormat),   // レポートデータ取得終了日（比較用）※比較する場合は必須 2018-01-01
        compare: (props.isEnabledCompare) ? 1 : null, // 比較設定 過去データを比較するなら1、しないなら0もしくは空
      },
      thirdPartySetting: [],
      dataStatus: [],
      infos: [],
      checked: [],
    };
    props.switchGlobalCatId(0);
    props.switchContentTitle('ダッシュボード');
    props.toggleDatePicker(true);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    if(outsideSales === "1"){
      // 外販の場合、着地予測エリアの表示項目を変える
      //【費用】【着地予測】【ヨミ予算】【ヨミ予算消化率】【CV数】【CVR】【CPA】
      this.setState({
        dataTableHeader: [ // 表示項目
          { id: 'clientName', label: 'クライアント名' },
          { id: 'alertInfo', label: 'アラート' },
          { id: 'cost', label: '費用' },
          { id: 'estimateCost', label: '着地予測' },
          { id: 'budget', label: 'ヨミ予算' },
          { id: 'bugetDigestibility', label: 'ヨミ予算消化率' },
          { id: 'cv', label: 'CV数' },
          { id: 'cvr', label: 'CVR' },
          { id: 'cpa', label: 'CPA' },
        ]
      });
    }

    await Promise.all([
      this.getAlerts(),
      this.getReportDataForTable(),
      this.getReportDataForGraph(),
      this.getDataStatus(),
      this.getInformationFromWP()
    ]);

    //　プログレス非表示
    this.props.endProgressing();
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // 日付が切り替わったときにレポートデータを更新する。
    if (
      !isEqual(prevProps.dateRange.startDate, this.props.dateRange.startDate)
      || !isEqual(prevProps.dateRange.endDate, this.props.dateRange.endDate)
      || !isEqual(prevProps.dateRangeCompare.startDate, this.props.dateRangeCompare.startDate)
      || !isEqual(prevProps.dateRangeCompare.endDate, this.props.dateRangeCompare.endDate)
      || prevProps.isEnabledCompare !== this.props.isEnabledCompare
    ) {
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          sDate: format(this.props.dateRange.startDate, dateFormat),
          eDate: format(this.props.dateRange.endDate, dateFormat),
          sDateC: format(this.props.dateRangeCompare.startDate, dateFormat),
          eDateC: format(this.props.dateRangeCompare.endDate, dateFormat),
          compare: (this.props.isEnabledCompare) ? 1 : null,
        }
      });
      this.getReportDataForTable();
      this.getReportDataForGraph();
    }
  }
  getAlerts = () => {
    // アラートデータの取得
    return axios.get(backendApi + 'alertNotification', {
      params: {
        ...User.apiParams(),
        number: 5,
      }
    })
    .then((response) => {
      this.setState({
        alerts: response.data,
      });
    })
    .catch(() => {
    });
  }
  getReportData = (purposeId = 0) => {
    // purposeIdはレポートデータの利用目的ごとにデータの取得方法を振り分けるためのIDです
    // 0: Table用
    // 1: 比較元のグラフデータ
    // 2: 比較のグラフデータ

    // 比較オフ時に purposeId: 2 のリクエストがあった場合、空の配列を返す。
    if (purposeId === 2 && this.props.isEnabledCompare === false) {
      return {
        'dataGraphCompare': [],
      }
    }

    // リクエストパラーメタ用オブジェクトをマージ
    let param = {}

    // グラフ用のデータを取得する場合は一部パラメータを書き換える。
    switch (purposeId) {
      case 0:
        // 表に使用するレポートデータ取得の場合
        param = {
          division: this.state.division,
          dataExist: 1 // データのあるもののみ
        };
        break;
      case 1:
        // 比較元のグラフデータ取得関連の処理
        param = {
          graph: 1,
          target: this.state.graphTargetId,
          division: this.state.graphDivision,
          compare: null,
        }
        break;
      case 2:
        // 比較のグラフデータ取得関連の処理
        param = {
          graph: 1,
          target: this.state.graphTargetId,
          division: this.state.graphDivision,
          compare: null,
          sDate: format(this.props.dateRangeCompare.startDate, dateFormat),
          eDate: format(this.props.dateRangeCompare.endDate, dateFormat),
        }
        break;
      default:
        break;
    }

    return axios.get(
      backendApi + 'report', {
        params: {
          ...User.apiParams(),
          ...this.state.requestParam,
          ...param,
        }
      }
    )
    .then((response) => {
      if (response.data.errorCode) {
        // APIリクエストエラー
      } else {
        // 成功
        // setStateはPromise.allでまとめて行うので、
        // ここではstateを更新したい内容のオブジェクトを返す。
        switch (purposeId) {
          case 0:
            // 表に使用するレポートデータ取得の場合
            return {
              dataTableOriginal: response.data.reportJsonList,
              dataTable: response.data.reportJsonList,
              dataTableFooter: response.data.total,
            };
          case 1:
          // グラフデータ(比較元)取得の場合
            const objGraph = {};
            objGraph['dataGraph'] = response.data.data;
            objGraph.graphCandidate = response.data.candidate;
            return objGraph;
          case 2:
            // グラフデータ(比較先)取得の場合
            const objGraphCompare = {};
            objGraphCompare['dataGraphCompare'] = response.data.data;
            return objGraphCompare;
          default:
            break;
        }
      }
    })
    .catch(() => {
      // サーバエラー
    });
  }
  getReportDataForTable = () => {
    // テーブルに使うレポートデータの取得
    return Promise.all([
      this.getReportData(0),
    ]).then((response) => {
      this.setState(_.assign(...response));
    });
  }
  getReportDataForGraph = () => {
    // グラフに使うレポートデータの取得
    return Promise.all([
      this.getReportData(1),
      this.getReportData(2),
    ]).then((response) => {
      this.setState(_.assign(...response));
    });
  }
  getThirdPartySetting = () => {
    // 3rdPartyデータ紐付けの取得
    return axios.get(backendApi + 'thirdPartySetting', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        thirdPartySetting: response.data,
      });
    })
    .catch(() => {
    });
  }
  getDataStatus = () => {
    // データ取得状況の取得
    return axios.get(backendApi + 'dataStatus', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        dataStatus: response.data,
      });
    })
    .catch(() => {
    });
  }
  getInformationFromWP = () => {
    return axios.get('https://inf.mmerge.jp/wp-json/wp/v2/infos')
    .then((response) => {
      this.setState({
        infos: response.data,
      })
    })
    .catch(() => {
    });
  }
  render() {
    const switchTarget = async (id) => {
      await this.setState({
        graphTargetId: id,
      });
      await this.getReportDataForGraph();
    }
    const switchGraphSplit = async (division) => {
      await this.setState({
        graphDivision: division,
      });
      this.getReportDataForGraph();
    }
    // infoモーダルを開く
    const openInfoModal = (text) => {
      this.setState({
        isOpenedInfoModal: true,
        infoText: text
      });
    }
    // infoモーダルを閉じる
    const closeInfoModal = () => {
      this.setState({
        isOpenedInfoModal: false
      });
    }
    const createInfomationItems = () => {
      return (
        <StyledInfoItem>
          { (() => {
            const sorted = this.state.infos.sort((a, b) => {
              return a.acf.date < b.acf.date? 1 : -1
            })
            const important = sorted.filter(item => {
              return item.acf.important && item.acf.visible
            })
            const unimportant = sorted.filter(item => {
              return !item.acf.important && item.acf.visible
            })
            // Max5件表示 - importantが5件以上あれば最新情報は非表示
            return [...important, ...unimportant].slice(0,5).map(item => {
              if(item.acf.url !== ""){
                // リンク先があるならリンク表示にする
                return (
                  <React.Fragment key = { _.uniqueId() }>
                    <StyledInfoItemDate
                    onClick = {
                      (() => {
                        openInfoModal(item.content.rendered);
                      })
                    }
                    >
                      <IconAttention className="m-r-8" />
                      { item.acf.date }
                    </StyledInfoItemDate>
                    <StyledLink
                      href = {item.acf.url}
                      target = "_blank"
                    >
                      { item.title.rendered }
                    </StyledLink>
                  </React.Fragment>
                )
              }else{
                return (
                  <React.Fragment key = { _.uniqueId() }>
                    <StyledInfoItemDate
                    onClick = {
                      (() => {
                        openInfoModal(item.content.rendered);
                      })
                    }
                    >
                      <IconAttention className="m-r-8" />
                      { item.acf.date }
                    </StyledInfoItemDate>
                    <StyledInfoItemText
                    onClick = {
                      (() => {
                        openInfoModal(item.content.rendered);
                      })
                    }
                    >
                      { item.title.rendered }
                    </StyledInfoItemText>
                  </React.Fragment>
                )
              }

            })
          })() }
        </StyledInfoItem>
      )
    }
    const createDataStatusItems = () => {
      return this.state.dataStatus.map(item => {
        if(item.todayComplete){
          // 本日のデータ取得が完了しているなら
          return (
            <StyledStatusItem
              key = { _.uniqueId() }
            >
              <DividerDoubleH className="m-b-16" />
              <HeadingS className="m-b-8">
                { item.media }
              </HeadingS>
              <LabelCorrect>更新: { (() => {
                return item.lastModified
              })()
              }</LabelCorrect>
            </StyledStatusItem>
          )
        }else{
          return (
            <StyledStatusItem
              key = { _.uniqueId() }
            >
              <DividerDoubleH className="m-b-16" />
              <HeadingS className="m-b-8">
                { item.media }
              </HeadingS>
              <LabelIncorrect>更新: { (() => {
                return item.lastModified
              })()
              }</LabelIncorrect>
            </StyledStatusItem>
          )
        }

      });
    }

    // チェックのクリア
    const clearCheckItems = () => {
      this.setState({
        checked: []
      });
    }

    return (
      <div>
        <StyledColumnWrap>
          <StyledColumn>
            <HeadingS>アラート</HeadingS>
            <StyledLinkLower
              as = { Link }
              to = '/alert-notification/'
            >
              一覧
              <IconApps className="m-l-8" />
            </StyledLinkLower>
            {
              (() => {
                return this.state.alerts.map((a) => {
                  return (
                    <OccurredAlertItem
                      key = { _.uniqueId() }
                      opts = { a }
                    />
                  )
                });
              })()
            }
          </StyledColumn>
          <DividerDottedVWide className="m-r-40 m-l-40" />
          <StyledColumn>
            <HeadingS>インフォメーション</HeadingS>
            <StyledLinkLower
              as = { Link }
              to = '/information/'
            >
              一覧
              <IconApps className="m-l-8" />
            </StyledLinkLower>
            <ModalInfoMessage
            heading = 'info'
            isOpened = { this.state.isOpenedInfoModal }
            close = { closeInfoModal }
            bodyText = { this.state.infoText }
            ></ModalInfoMessage>
            { createInfomationItems() }
          </StyledColumn>
        </StyledColumnWrap>

        <DividerContent />

        <HeadingS>着地予測</HeadingS>
        <ReportTable
          dataTableHeader = { this.state.dataTableHeader }
          dataTable = { this.state.dataTable }
          dataTableFooter = { this.state.dataTableFooter }
          isDivided = { false }
          target = { 0 } // ダッシュボードからの進捗管理画面への遷移ではクライアントタブに飛びたいので0にする（リンクがtarget+1になるから）
          targetId = { 'clientId' }
          targetName = { 'clientName' }
          clearCheckItems = { clearCheckItems }
          sortId = { 'cost_with_fee' } // sort項目を指定する
        />

        <DividerContent />

        <Graph
          dateRange = { this.props.dateRange }
          dateRangeCompare = { this.props.dateRangeCompare }
          targetId = { this.state.graphTargetId }
          data = { this.state.dataGraph }
          dataCompare = { this.state.dataGraphCompare }
          candidate = { this.state.graphCandidate }
          division = { this.state.graphDivision }
          isEnabledCompare = { this.props.isEnabledCompare }
          isEnabledSelectTarget = { true }
          switchTarget = { switchTarget }
          switchGraphSplit = { switchGraphSplit }
        />

        <DividerContent />

        <HeadingS>データ取得状況</HeadingS>

        {/* ダミー表示のためいったんコメントアウト*/}
        {/* <HeadingS color="gray">3rdPartyデータ紐付け</HeadingS>
        <div>2018/01/24分データ　2018/01/23アップ　全データ中50%紐づけ成功</div>
        <div>2018/01/27分データ　2018/01/25アップ　全データ中100%紐づけ成功</div>
        <div>2018/01/30分データ　2018/01/29アップ　全データ中80%紐づけ成功</div> */}

        <HeadingS noMargin color="gray" className="m-t-32">メディア接続状況</HeadingS>
        <FlexBox flexWrap>
          { createDataStatusItems() }
        </FlexBox>

      </div>
    )
  }
}

export default Dashboard;
