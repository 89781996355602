import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Button from '../../../atoms/Button';
import BarBottom from '../../../atoms/BarBottom';
import HeadingS from '../../../atoms/HeadingS';
import ModalErrorMessage from '../../../organisms/ModalErrorMessage';

import User from '../../../../utils/user'

const backendApi = process.env.REACT_APP_BACKEND_URI;

class ExternalDataSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaCvNames: [],
      bodyText: '',
      isErrorOpened: false,
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = async () => {
    if(this.props.clientData.cvId) {
      // マッピングデータ取得
      await axios.get(backendApi + 'thirdPartyMapping', {
        params: {
          id: this.props.clientData.cvId,
          ...User.apiParams(),
        }
      })
      .then((response) => {
        if(response.data) {
          this.setState({
            data: response.data,
            mediaCvNames: response.data.map(x => x.mediaCvName)
          })
        }
      })
      .catch((error) => {
        // エラーモーダル表示
        this.toggleErrorModal(true);
        this.setState({
          bodyText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
        })
      })
    } else {
      this.props.history.push('/setting/external-data');
    }
  }

  handleToNextPage = () => {
    this.props.setNewData(false);
    this.props.history.push('/setting/external-data/ga');
  }

  handleDeleteExData = async () => {

    if(window.confirm('外部データ連携を本当に解除しますか？(データ蓄積ツールからのデータ削除も必要なため、解除する場合にはOKをクリックした後、管理者にご連絡ください。)')){
      // 確認してOKなら

      // プログレス表示
      this.props.startProgressing();
      await axios.get(backendApi + 'thirdPartySettingModify', {
        params: {
          func: 2,
          ids: this.props.clientData.cvId,
          ...User.apiParams(),
        }
      })
      .then((response) => {
        // プログレス非表示
        this.props.endProgressing();
        // TOPにリダイレクト
        this.props.history.push('/setting/external-data');
      })
      .catch((error) => {
        // エラーモーダル表示
        this.toggleErrorModal(true);
        this.setState({
          bodyText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
        })
        // プログレス非表示
        this.props.endProgressing();
      })
      }

  }

  // エラーモーダル開閉
  toggleErrorModal = (boolean) => {
    this.setState({
      isErrorOpened: boolean
    });
  }

  render() {
    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isErrorOpened }
          close = { () => this.toggleErrorModal(false) }
          bodyText = { this.state.bodyText }
        />
        <HeadingS>●クライアント名（広告主名）：{ this.props.clientData.clientName }</HeadingS>
        <HeadingS>●外部データ連携：GA利用{ this.props.clientData.viewId ? 'あり' : 'なし' }</HeadingS>
        {
          (() => {
            if(this.props.clientData.viewId) {
              return (
                <HeadingS>●Google AnalyticsのプロパティID：{ this.props.clientData.viewId }</HeadingS>
              )
            }
          })()
        }
        {
          (() => {
            if(this.props.clientData.metrics) {
              return (
                <div>
                  <HeadingS>●目標の選択：</HeadingS>
                  <HeadingS
                    className = "m-l-16"
                  >目標： { this.props.clientData.metrics.replace(',', ', ') }</HeadingS>
                  {/* <HeadingS
                    className = "m-l-16"
                  >ディメンション： { this.props.clientData.dimensions ? this.props.clientData.dimensions.replace(',', ', ') : 'なし' }</HeadingS> */}
                </div>
              )
            }
          })()
        }
        <HeadingS>●項目設定： { this.state.mediaCvNames.length > 0 ? this.state.mediaCvNames.join(', ') : 'なし'}</HeadingS>
        <BarBottom>
          <Button
            className = 'm-l-8'
            onClick = { this.handleDeleteExData }
          >
            外部データ連携を解除する
          </Button>
          <Button
            as = { Link }
            className = 'm-l-8'
            to = '/setting/external-data'
          >
            前に戻る
          </Button>
          {
            (() => {
              if (true) {
                return (
                  <Button
                    color="orange"
                    className = 'm-l-8'
                    onClick = { this.handleToNextPage }
                  >
                    編集する
                  </Button>
                )
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default ExternalDataSetting;
