import React from 'react';
import axios from 'axios';
import styled from 'styled-components';

import Button from '../atoms/Button';
import FlexBox from '../atoms/FlexBox';
import PullDownCommon from './PullDownCommon';
import InputText from '../atoms/InputText';
import HeadingS from '../atoms/HeadingS';
import Modal from '../molecules/Modal';

import User from '../../utils/user';

const StyledModalBody = styled.div`
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

class ModaLabelEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestParam: {
        budget:         0, // 設定予算
        alertSettingId: 0, // 設定したアラート情報ID	アラート情報を設定するなら該当するアラート情報のIDをセット。設定しない場合は0
      },
    };
  }


  render() {

      // アラートを選択
      const switchAlertId = async (id) => {
        await this.setState({
          requestParam: {
            ...this.state.requestParam,
            alertSettingId: id
          }
        });
      }

    // 一括保存する。
    const save = async () => {
      await axios.get(backendApi + 'labelModify', {
        params: {
          ...User.apiParams(),
          ...this.state.requestParam,
          func: 5,
          ids: this.props.ids,
        }
      })
      .then((response) => {
        // 更新されたラベル一覧画面を表示
        window.location.href = '/label/';
      })
      .catch(() => {
      });

    }


      return(
        <Modal
        heading = { this.props.heading }
        isOpened = { this.props.isOpened }
        close = { this.props.closeLabelModal }
      >
        <StyledModalBody>
          <HeadingS
            className = 'm-t-40'
          >
            ラベルのヨミ予算を入力してください
          </HeadingS>

          <div
            className = 'm-t-8'
          >
            <InputText
              type = "number"
              value = { this.state.requestParam.budget }
              min = { 0 }
              step = { 1 }
              className = "m-r-8"
              onChange = { ((e) => {
                this.setState({
                  requestParam: {
                    ...this.state.requestParam,
                    budget: e.currentTarget.value,
                  }
                });
              }) }
              />
              円
            </div>

            <HeadingS
              className = 'm-t-40'
            >
              ラベルに設定したいアラートを選択してください
            </HeadingS>
            <FlexBox>
              <PullDownCommon
                id = { this.state.requestParam.alertSettingId }
                items = { this.props.alertData }
                listWidth = { '30em' }
                onChange = { switchAlertId }
              />
            </FlexBox>

            <HeadingS
              className = 'm-t-40'
            ></HeadingS>

            <Button
                    color="orange"
                    onClick = { save }
                    className = 'm-l-8'
                  >
                    保存
            </Button>
          </StyledModalBody>
      </Modal>
      )
  }
};

export default ModaLabelEdit;
