import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';
import { format, isEqual } from 'date-fns';
import Url from 'url-parse';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import classnames from 'classnames';

import DividerContent from '../../atoms/DividerContent';
import DividerDottedV from '../../atoms/DividerDottedV';
import FlexBox from '../../atoms/FlexBox';
import IconDownload from '../../atoms/IconDownload';
import ProgressStatus from '../../atoms/ProgressStatus';
import BarEditProgressData from '../../molecules/BarEditProgressData';
import Tab from '../../molecules/Tab';
import Search from '../../molecules/Search';
import GraphWithToggleBtn from '../../organisms/GraphWithToggleBtn';
import ModalProgressDisplayEdit from '../../organisms/ModalProgressDisplayEdit';
import PullDownCommon from '../../organisms/PullDownCommon';
import PullDownProgressAvgData from '../../organisms/PullDownProgressAvgData';
import PullDownProgressDisplay from '../../organisms/PullDownProgressDisplay';
import PullDownProgressFilter from '../../organisms/PullDownProgressFilter';
import PullDownProgressSplit from '../../organisms/PullDownProgressSplit';
import ReportTable from '../../organisms/ReportTable';

import User from '../../../utils/user';

const dateFormat = 'YYYY-MM-DD';

const StyledTableMenu = styled(FlexBox)`
  height: 32px;
  margin-bottom: 32px;
`

const StyledFilterSection = styled.div`
  display: none;
  height: 32px;
  &.is-shown {
    display: flex;
    align-items: center;
  }
`

const backendApi = process.env.REACT_APP_BACKEND_URI;

class Base extends React.Component {
  constructor(props) {
    super(props);

    const url = new Url(window.location.href, true);
    const cookieResultListNo = Cookies.getJSON('rakuda-result-list-no');

    this.state = {
      dispItems: [], // 表示項目の候補
      editingDispItemId: 1, // 編集中の表示項目設定のID
      dataTableHeader: [], // 表示項目
      dataTableOriginal: [], // レポートデータ（Table用）
      dataTable: [], // レポートデータ（Table用）を表示用に間引きしたもの
      dataTableFooter: null, // 比較元データの合計行（Table用）
      dataGraph: [], // 比較元データ（グラフ用）
      dataGraphCompare: [], // 比較データ（グラフ用）
      division: (url.query.division !== undefined) ? parseInt(url.query.division) : 0, // Tableの分割ID
      graphDivision: 1, // グラフの分割ID 1:日別、2:週別、3:月別
      graphCandidate: [],
      selectedLabel: undefined,
      selectedCustomReport: undefined,
      checked: [],
      isShownTab: (url.query.label === undefined && url.query.customReport === undefined),
      isOpenedDisplayModal: false,
      labelData: [],
      labelDataFiltered: [],
      requestParam: {
        // 必須項目
        // ---
        target: props.target, // レポートデータの対象 1:クライアント、2:アカウント、3:キャンペーン、4:広告グループ、5:キーワード
        clientId:  ((props.target === 1 || props.target === 2) && url.query.filter !== undefined) ? url.query.filter : null, // クライアントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        accountId:  (props.target === 3 && url.query.filter !== undefined) ? url.query.filter : null, // アカウントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        campaignId: (props.target === 4 && url.query.filter !== undefined) ? url.query.filter : null, // キャンペーンID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        adgroupId:  (props.target === 5 && url.query.filter !== undefined) ? url.query.filter : null, // 広告グループID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        keywordId:  null, // キーワードID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空

        // ラベルorカスタムレポートを適用する
        label: null, // ラベル設定。登録したラベルの中で、表示したいラベルのIDを指定する。1など。指定しない場合は空
        customReport: null, // カスタムレポート設定。登録したカスタムレポートの中で、表示したいカスタムレポートのIDを指定する。

        // 検索窓で設定する項目
        // ---
        searchText: null, // 検索対象としたいクライアント名やアカウント名などを入力する（対象レポートによって検索対象の名称は変化する）
        matchType: 0,

        // フィルタで設定する項目
        // ---
        sectionId: null, // 部署ID 1など。指定しない場合は空
        groupId:   null, // グループID 1など。指定しない場合は空
        dataExist: 1,    // データのあるアカウントのみ表示するか 0:すべて、1:アクティブのみ
        aw:        null, // 対象媒体adwords 検索対象とするなら1、対象としないなら0もしくは空
        ss:        null, // 対象媒体Y!SS 検索対象とするなら1、対象としないなら0もしくは空
        ydn:       null, // 対象媒体YDN 検索対象とするなら1、対象としないなら0もしくは空
        cr:        null, // 対象媒体Criteo 検索対象とするなら1、対象としないなら0もしくは空
        tw:        null, // 対象媒体twitter 検索対象とするなら1、対象としないなら0もしくは空
        fb:        null, // 対象媒体Facebook 検索対象とするなら1、対象としないなら0もしくは空
        zeus:      null, // 対象媒体Xリスティング 検索対象とするなら1、対象としないなら0もしくは空
        line:      null, // 対象媒体LINE 検索対象とするなら1、対象としないなら0もしくは空
        dbm:       null, // 対象媒体DBM 検索対象とするなら1、対象としないなら0もしくは空
        device:    null, // 対象デバイス 0:全デバイス、1:PC、4:SP、8:TB、256:MO
        alNot:     null, // アラート非表示 非表示とするなら1、表示するなら0もしくは空
        al1:       null, // 媒体予算切れアラート 表示とするなら1、非表示とするなら0もしくは空
        al2:       null, // ヨミ予算切れアラート 表示とするなら1、非表示とするなら0もしくは空
        al3:       null, // 媒体予算オーバーペース 表示とするなら1、非表示とするなら0もしくは空
        al4:       null, // ヨミ予算切れアラート 表示とするなら1、非表示とするなら0もしくは空
        ex:        0, // 外部CV 0:なし,1:外部CVのみ,2:すべて
        alHis: (url.query.alertId !== undefined) ? url.query.alertId : null,

        // 分割で設定する項目
        // ---
        division: 0, // 分割設定 データ分割を行う場合に指定する。1:日別、2:週別、3:月別、4:曜日別、5:デバイス別、6:コンバージョン別
        multiCv:  null, // CV分割。複数CV、外部CVデータを表示する。※複数CVについてはtarget=2(アカウントレポートのみ)もしくはtarget=3(キャンペーンレポートのみ) CV分割を表示するなら1、表示しないなら0もしくは空

        // 着地予測の計算
        // ---
        avgData: null, // 平均データ 1:過去1日、3:過去3日、7:過去7日、0もしくは空なら指定なし

        // 期間設定関連
        // ---
        sDate:     format(props.dateRange.startDate, dateFormat),        // レポートデータ取得開始日 2018-01-01
        eDate:     format(props.dateRange.endDate, dateFormat),          // レポートデータ取得終了日 2018-01-01
        sDateC:    format(props.dateRangeCompare.startDate, dateFormat), // レポートデータ取得開始日（比較用）※比較する場合は必須 2018-01-01
        eDateC:    format(props.dateRangeCompare.endDate, dateFormat),   // レポートデータ取得終了日（比較用）※比較する場合は必須 2018-01-01
        compare:   (props.isEnabledCompare) ? 1 : null, // 比較設定 過去データを比較するなら1、しないなら0もしくは空
        compS:     null, // 比較設定。比較の際に比較結果を数値で表示するか、パーセンテージで表示するか 0:数値、1:パーセンテージ。未指定の場合は登録されているデフォルト値を適用する
        timeSpan:  null, // 指定期間 1:機能、2:過去7日間、3:過去14日間、4:今月、5:過去30日間、6:6先月、7:全期間 ※指定なしならsDate、eDateの値をそのまま適用する
        timeSpanC: null, // 指定期間（比較用） 1:前の期間、2:前年同期 ※指定なしならsDateC、eDateCの値をそのまま適用する

        // ソート関連
        // ---
        sortColNo: null, // ソートする項目 ソートするなら設定する。項目の番号はレポート項目詳細 >> 各レポート項目の詳細についてはこちらの「項目No」を参照
        orderby:   null, // ソート順 1:昇順、2:降順 ※指定なしなら昇順

        // ページャ関連
        // ---
        row:  null, // レポートデータを表示する行数。指定なしなら50
        page: null, // レポートデータを表示するページ数。指定なしなら1	rowで50件ずつ表示するとしたら、2を指定すれば51～100件目のデータを表示する

        // ページロード時にただTableを生成するためにレポートデータを取得するだけのときには使わないもの
        // ---
        resultListNo: (cookieResultListNo) ? cookieResultListNo : null, // 表示設定指定。登録されている表示設定の中で、表示したい設定の番号を指定する。1など。空の場合は自身で設定したデフォルト番号を使用する
        dl:           null, // csvファイルダウンロード csvファイルダウンロードなら1を設定する
        d:            null, // ダッシュボード画面からのリクエストかどうか ダッシュボード画面からのリクエストの場合1を設定する
        view:         null, // MyView表示設定。登録したMy Viewの中で、表示したいMy ViewのIDを指定する。1など。指定しない場合は空

        // 用途不明のもの
        // ---
        al:              null, // 自身で設定したアラート表示設定。登録されているアラートの中で、表示したいアラートのIDを指定する。 1など。指定しない場合は空
        alertOnly:       null, // デフォルトアラートのあるデータのみ表示 デフォルトアラートのあるデータのみ表示させるなら1
        addSearchResult: null, // 自身で追加したオリジナル項目 表示するなら1、表示しないなら空
      },
    };
    props.switchGlobalCatId(1);
    props.toggleDatePicker(true);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    // まず保存されている表示項目を取得
    await this.getDispSettingItems();

    // レポートデータを取得
    await this.getReportDataAll();

    // ラベルデータ取得
    await this.getLabelData();

    //　プログレス非表示
    this.props.endProgressing();
  }
  componentDidUpdate = async (prevProps, prevState) => {
    if (
      // 日付が切り替わったときにレポートデータを更新する。
      !isEqual(prevProps.dateRange.startDate, this.props.dateRange.startDate)
      || !isEqual(prevProps.dateRange.endDate, this.props.dateRange.endDate)
      || !isEqual(prevProps.dateRangeCompare.startDate, this.props.dateRangeCompare.startDate)
      || !isEqual(prevProps.dateRangeCompare.endDate, this.props.dateRangeCompare.endDate)
      // 比較ON/OFFが切り替わったときにレポートデータを更新する。
      || prevProps.isEnabledCompare !== this.props.isEnabledCompare
    ) {
      // プログレス表示
      this.props.startProgressing();

      await this.setState({
        division: (this.props.isEnabledCompare) ? 0 : this.state.division,
        requestParam: Object.assign({}, this.state.requestParam, {
          compare: (this.props.isEnabledCompare) ? 1 : null,
          division: (this.props.isEnabledCompare) ? 0 : this.state.division,
          sDate: format(this.props.dateRange.startDate, dateFormat),
          eDate: format(this.props.dateRange.endDate, dateFormat),
          sDateC: format(this.props.dateRangeCompare.startDate, dateFormat),
          eDateC: format(this.props.dateRangeCompare.endDate, dateFormat),
        }),
      });
      await this.getReportDataAll();

      //　プログレス非表示
      this.props.endProgressing();
    }
  }
  getDispSettingItems = () => {
    // APIをリクエストして表示項目のデータを取得
    return axios.get(backendApi + 'dispItem', {
      params: {
        ...User.apiParams(),
        func: 2,
        target: this.state.requestParam.target,
        resultListNo: this.state.editingDispItemId,
        division: this.state.division,
        compare: (this.props.isEnabledCompare) ? 1 : null,
      }
    })
    .then((response) => {
      this.setState({
        dispItems: response.data,
      });
    })
    .catch(() => {
    });
  }
  getReportData = (purposeId = 0) => {
    // purposeIdはレポートデータの利用目的ごとにデータの取得方法を振り分けるためのIDです
    // 0: Table用
    // 1: 比較元のグラフデータ
    // 2: 比較のグラフデータ

    // 比較オフ時に purposeId: 2 のリクエストがあった場合、空の配列を返す。
    if (purposeId === 2 && this.props.isEnabledCompare === false) {
      return {
        'dataGraphCompare': [],
      }
    }

    // nullが入っているパラメータを除外する
    const pickedParam = _.pickBy(this.state.requestParam, o => o !== null);

    // リクエストパラーメタ用オブジェクトをマージ
    const mergeParam = {
      ...User.apiParams(),
      ...pickedParam
    };

    // グラフ用のデータを取得する場合は一部パラメータを書き換える。
    switch (purposeId) {
      case 0:
        // 表に使用するレポートデータ取得の場合
        Object.assign(mergeParam, {
          division: this.state.division,
        });
        break;
      case 1:
        // 比較元のグラフデータ取得関連の処理
        Object.assign(mergeParam, {
          graph: 1,
          division: this.state.graphDivision,
          compare: null,
        });
        break;
      case 2:
        // 比較のグラフデータ取得関連の処理
        Object.assign(mergeParam, {
          graph: 1,
          division: this.state.graphDivision,
          compare: null,
          sDate: format(this.props.dateRangeCompare.startDate, dateFormat),
          eDate: format(this.props.dateRangeCompare.endDate, dateFormat),
        });
        break;
      default:
        break;
    }

    // APIをリクエストしてレポートデータを取得
    return axios.get(
      backendApi + 'report', {
        params: mergeParam
      }
    )
    .then((response) => {
      if (response.data.errorCode) {
        // APIリクエストエラー
      } else {
        // 成功
        // setStateはPromise.allでまとめて行うので、
        // ここではstateを更新したい内容のオブジェクトを返す。
        switch (purposeId) {
          case 0:
            // 表に使用するレポートデータ取得の場合

            // 分割の有無で受け取るレポートデータのプロパティ名が違う
            const reportJsonList = (response.data.reportJsonListDivided)
              ? response.data.reportJsonListDivided
              : response.data.reportJsonList;

            return {
              dataTableHeader: response.data.dispItem,
              dataTableOriginal: reportJsonList,
              dataTable: reportJsonList,
              dataTableFooter: response.data.total,
              selectedLabel: response.data.selectedLabel,
              selectedCustomReport: response.data.selectedCustomReport,
            }
          case 1:
            // グラフデータ(比較元)取得の場合
            const objGraph = {};
            objGraph['dataGraph'] = response.data.data;
            objGraph.graphCandidate = response.data.candidate;
            return objGraph;
          case 2:
            // グラフデータ(比較先)取得の場合
            const objGraphCompare = {};
            objGraphCompare['dataGraphCompare'] = response.data.data;
            return objGraphCompare;
          default:
            break;
        }
      }
    })
    .catch(() => {
      // サーバエラー
    });
  }
  getReportDataForTable = () => {
    // テーブルに使うレポートデータの取得
    return Promise.all([
      this.getReportData(0),
    ]).then((response) => {
      this.setState(Object.assign(...response));
    });
  }
  getReportDataForGraph = () => {
    // グラフに使うレポートデータの取得
    if (this.state.requestParam.target >= 3) return;
    return Promise.all([
      this.getReportData(1),
      this.getReportData(2),
    ]).then((response) => {
      this.setState(Object.assign(...response));
    });
  }
  getReportDataAll = async () => {
    // URIのクエリパラメータにラベル、カスタムレポートのIDが存在するかどうかを判断し、
    // 存在する場合はレポートデータのリクエストパラメータを更新する。
    const queries = queryString.parse(window.location.search);

    if (queries.customReport !== undefined) {
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          customReport: parseInt(queries.customReport),
        }
      })
    } else if (queries.label !== undefined) {
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          label: parseInt(queries.label),
        }
      })
    };

    // 全レポートデータ更新
    const promises = (this.state.requestParam.target < 3)
      ? [
          this.getReportData(0),
          this.getReportData(1),
          this.getReportData(2),
        ]
      : [
          this.getReportData(0)
        ];
    return Promise.all(promises).then((response) => {
      // test code
      if(_.includes(response, undefined)) return;
      this.setState(Object.assign(...response));
    });
  }

  getLabelData = () => {
    // APIをリクエストしてデータを取得
    return axios.get(backendApi + 'label', {
      params: {
        ...User.apiParams(),
      }
    })
    .then((response) => {
      this.setState({
        labelData: response.data,
      });

      const filteredData = this.state.labelData.filter((item, index) => {
        return (
          (
            // 現在選択されているtargetに合致するもののみ
            item.target === this.state.requestParam.target)
          )
      });
      this.setState({
        labelDataFiltered: filteredData,
      });
    })
    .catch(() => {
    });

    
  }

  render() {
    // タブの中身
    const tabItems = [
    {
        label: "クライアント",
        link: "/progress/1/",
    },
    {
        label: "アカウント",
        link: "/progress/2/",
    },
    {
        label: "キャンペーン",
        link: "/progress/3/",
    },
    {
        label: "広告グループ",
        link: "/progress/4/",
    },
    {
        label: "キーワード/ターゲティング",
        link: "/progress/5/",
    },
    ];

    // UI操作に関する関数
    // ---
    // グラフの期間変更
    const switchGraphSplit = async (division) => {
      // プログレス表示
      this.props.startProgressing();

      await this.setState({
        graphDivision: division,
      });
      await this.getReportDataForGraph();

      // プログレス非表示
      this.props.endProgressing();
    }

    // フリーワード検索
    const setSearchQuery = async (e) => {
      e.preventDefault();

      // プログレス表示
      this.props.startProgressing();

      // 検索文字列をリクエストパラメータにマージしてレポートデータを再取得
      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          searchText: e.target['searchQuery'].value,
        },
      });
      await this.getReportDataAll();

      // プログレス非表示
      this.props.endProgressing();
    }

    // フリーワード検索のルールを変更
    const switchSearchMethod = (id) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          matchType: id,
        },
      });
    }

    // フィルタを適用してレポートデータを再取得する
    const setDefaultParam = async (filterParam) => {
      // プログレス表示
      this.props.startProgressing();

      // フィルタ条件をリクエストパラメータにマージしてレポートデータを再取得
      await this.setState({
        requestParam: Object.assign({}, this.state.requestParam, filterParam),
      });
      await this.getReportDataAll();

      // プログレス非表示
      this.props.endProgressing();
    }

    // 分割方法を変える
    const setDivision = async (division) => {
      // プログレス表示
      this.props.startProgressing();

      // 分割条件を変更してレポートデータを再取得
      if (division !== null && this.state.requestParam.compare === 1) {
        // 比較が有効 & 何らかの条件で分割する場合は比較をオフにする
        this.props.offDateCompare();
        await this.setState({
          division: division,
          requestParam: Object.assign({}, this.state.requestParam, {
            compare: null
          }),
        });
        await this.getReportDataAll();
      } else {
        await this.setState({
          division: division,
        });
        await this.getReportDataForTable();
      }

      // プログレス非表示
      this.props.endProgressing();
    }

    // モーダルを開く
    const openDisplayModal = (e) => {
      this.setState({
        editingDispItemId: parseInt(e.currentTarget.value),
        isOpenedDisplayModal: true,
      });
    }

    // 表示項目設定モーダルを閉じる
    const closeDisplayModal = (e) => {
      this.setState({
        isOpenedDisplayModal: false
      });
    }

    // 表示項目設定を適用する
    const setDisplayItem = (e) => {
      const selected = _.find(this.state.dispItems, o => {
        return o.id === parseInt(e.currentTarget.value)
      });
      this.setState({
        dataTableHeader: selected.dispItem,
        requestParam: Object.assign({}, this.state.requestParam, {
          resultListNo: parseInt(e.currentTarget.value),
        }),
      });

      // Cookieに選択中の表示項目設定IDをセット
      Cookies.set('rakuda-result-list-no', e.currentTarget.value);
    }

    // 着地予測設定を適用する
    const setAvgData = async (id) => {
      // プログレス表示
      this.props.startProgressing();

      await this.setState({
        requestParam: {
          ...this.state.requestParam,
          avgData: id,
        },
      });
      await this.getReportDataAll();

      // プログレス非表示
      this.props.endProgressing();
    }

    // 表示項目設定を更新する
    const saveDisplayItem = (title, newDispItem) => {
      // プログレス表示
      this.props.startProgressing();
      this.setState({
        isOpenedDisplayModal: false,
      });

      axios.get(backendApi + 'dispItem', {
        params: {
          ...User.apiParams(),
          func: 1,
          target: this.state.requestParam.target,
          resultListNo: this.state.editingDispItemId,
          division: this.state.division,
          compare: (this.props.isEnabledCompare) ? 1 : null,
          id: this.state.editingDispItemId,
          name: title,
          dispItemData: newDispItem.map(o => { return o.id }).join(','),
          ex: this.state.requestParam.ex,
        }
      })
      .then((response) => {
        const selected = _.find(response.data, o => {
          return o.id === this.state.editingDispItemId
        });
        this.setState({
          dispItems: response.data,
          dataTableHeader: selected.dispItem,
          requestParam: Object.assign({}, this.state.requestParam, {
            resultListNo: this.state.editingDispItemId
          }),
        });

        // Cookieに選択中の表示項目設定IDをセット
        Cookies.set('rakuda-result-list-no', this.state.editingDispItemId);

        // プログレス非表示
        this.props.endProgressing();
      })
      .catch(() => {
      });
    }

    // 指定の表示項目設定を初期化
    const initDisplayItem = (e) => {
      // プログレス表示
      this.props.startProgressing();

      axios.get(
        backendApi + 'dispItem', {
          params: {
            ...User.apiParams(),
            func: 1,
            target: this.state.requestParam.target,
            resultListNo: this.state.editingDispItemId,
            division: this.state.division,
            compare: (this.props.isEnabledCompare) ? 1 : null,
            id: e.currentTarget.value,
            name: '',
          }
        }
      )
      .then((response) => {
        this.setState({
          dispItems: response.data,
          dataTableHeader: response.data[this.state.editingDispItemId].dispItem,
        });

        // プログレス非表示
        this.props.endProgressing();
      })
      .catch(() => {
      });
    }

    // 表示項目設定レポートデータをダウンロード
    const download = async (e) => {
      e.preventDefault();

      const url = new Url(backendApi + 'report', true);
      url.set('query', Object.assign(
        User.apiParams(),
        this.state.requestParam,
        {
          dl: 1,
          division: (this.props.isEnabledCompare) ? 0 : this.state.division,　// 分割設定があればダウンロードデータも分割する
        }
      ));

      var element = document.createElement('a');
      element.href = url.toString();
      element.setAttribute('download', 'reportdata.csv');
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    // 各行のチェック
    const checkItem = (e) => {
      const id = parseInt(e.currentTarget.value);
      const checked = this.state.checked.concat();

      if (checked.indexOf(id) > -1) {
        checked.splice(checked.indexOf(id), 1)
      } else {
        checked.push(id);
      }
      this.setState({
        checked: checked
      });
    }

    // チェックボックスをすべて選択
    const toggleCheckAll = async (dataTable) => {
      let checked = (this.state.checked.length < dataTable.length)
        ? dataTable.map(item => {
          switch (this.props.target) {
            case 1: return parseInt(item.clientId);
            case 2: return parseInt(item.accountId);
            case 3: return parseInt(item.campaignId);
            case 4: return parseInt(item.adgroupId);
            case 5: return parseInt(item.keywordId);
            default: return ``;
          }
        })
        : [];
      this.setState({
        checked: checked
      })
    }

    // チェックのクリア
    const clearCheckItems = () => {
      this.setState({
        checked: []
      });
    }

    return (
      <div>
        {
          (() => {
            if (this.state.selectedLabel) {
              return (
                <ProgressStatus
                  applyName = { 'ラベル' }
                  title = { this.state.selectedLabel.name }
                />
              )
            } else if (this.state.selectedCustomReport) {
              return (
                <ProgressStatus
                  applyName = { 'カスタムレポート' }
                  title = { this.state.selectedCustomReport.name }
                />
              )
            } else if (this.state.isShownTab === true) {
              return (
                <Tab
                  items = { tabItems }
                  currentId = { this.state.requestParam.target - 1 }
                  className = "m-b-40"
                />
              )
            }
          })()
        }

        {
          (() => {
            if (this.props.hasGraph === true) {
              return (
                <div>
                  <GraphWithToggleBtn
                    dateRange = { this.props.dateRange }
                    dateRangeCompare = { this.props.dateRangeCompare }
                    targetId = { this.state.requestParam.target }
                    data = { this.state.dataGraph }
                    dataCompare = { this.state.dataGraphCompare }
                    candidate = { this.state.graphCandidate }
                    division = { this.state.graphDivision }
                    isEnabledCompare = { this.props.isEnabledCompare }
                    switchGraphSplit = { switchGraphSplit }
                    hasToggleBtn
                  />
                  <DividerContent
                    className = "m-t-0"
                  />
                </div>
              )
            }
          })()
        }

        <StyledTableMenu>
          <StyledFilterSection
            className = {
              classnames({
                'is-shown': !this.state.requestParam.customReport
              })
            }
          >
            <Search
              name = "searchQuery"
              value = { this.state.requestParam.searchText }
              onSubmit = { setSearchQuery }
              className = 'm-r-16'
            />
            <PullDownCommon
              id = { this.state.requestParam.matchType }
              listWidth = '130px'
              items = {
                [
                { id: 0, label: '部分一致' },
                { id: 1, label: '完全一致' },
                { id: 2, label: '前方一致' },
                { id: 3, label: '後方一致' },
                ]
              }
              onChange = { switchSearchMethod }
            />
            <DividerDottedV hasMargin />
            <PullDownProgressFilter
              requestParam = { this.state.requestParam }
              setDefaultParam = { setDefaultParam }
              labelData = { this.state.labelDataFiltered }
            />
            <PullDownProgressSplit
              target = { this.props.target }
              division = { this.state.division }
              requestParam = { this.state.requestParam }
              setDivision = { setDivision }
              className="m-l-16"
            />
            <PullDownProgressDisplay
              dispItems = { this.state.dispItems }
              resultListNo = { this.state.requestParam.resultListNo }
              openDisplayModal = { openDisplayModal }
              setDisplayItem = { setDisplayItem }
              initDisplayItem = { initDisplayItem }
              isDisplay = { this.state.isShownTab }
              className = "m-l-16"
            />
            <PullDownProgressAvgData
              id = { this.state.requestParam.avgData }
              setAvgData = { setAvgData }
              className = "m-l-16"
            />
            <DividerDottedV hasMargin />
          </StyledFilterSection>

          <FlexBox
            style = {{ 'cursor': 'pointer' }}
            onClick = { download }
          >
            <IconDownload
              className = 'm-r-8'
            />
            CSVダウンロード
          </FlexBox>
        </StyledTableMenu>

        <BarEditProgressData
          count = { this.state.checked.length }
          checked = { this.state.checked }
          target = { this.props.target }
          division = { this.state.division }
        />
        <ReportTable
          dataTableHeader = { this.state.dataTableHeader }
          dataTable = { this.state.dataTable }
          dataTableFooter = { this.state.dataTableFooter }
          checked = { this.state.checked }
          division = { this.state.division }
          isDivided = { (this.state.division !== 0 && this.state.division !== 6 && this.state.division !== 7) }
          windowHeight = { this.props.windowHeight }
          target = { this.props.target }
          targetId = { this.props.targetId }
          targetName = { this.props.targetName }
          checkItem = { checkItem }
          toggleCheckAll = { toggleCheckAll }
          clearCheckItems = { clearCheckItems }
          sortId = { 'cost_with_fee' } // sort項目を指定する
        />
        <ModalProgressDisplayEdit
          heading = '表示項目の変更'
          editingDispItemId = { this.state.editingDispItemId }
          selectableDispItems = { this.props.selectableDispItems }
          dispItems = { this.state.dispItems }
          isOpened = { this.state.isOpenedDisplayModal }
          isOpenedSideMenu = { this.props.isOpenedSideMenu }
          save = { saveDisplayItem }
          close = { closeDisplayModal }
        />
      </div>
    )
  }
}

export default Base;
