import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { format } from 'date-fns';
import axios from 'axios';
import Url from 'url-parse';

import COLOR from '../../../_const/COLOR';
import FONTSIZE from '../../../_const/FONTSIZE';
import HeadingS from '../../../atoms/HeadingS';

import Button from '../../../atoms/Button';
import FlexBox from '../../../atoms/FlexBox';
import IconPlus from '../../../atoms/IconPlus';
import IconAttention from '../../../atoms/IconAttention';
import IconExport from '../../../atoms/IconExport';
import IconImport from '../../../atoms/IconImport';
import TableDataCell from '../../../atoms/TableDataCell';
import TableBodyRow from '../../../atoms/TableBodyRow';
import TableHeader from '../../../atoms/TableHeader';
import TableHeaderCell from '../../../atoms/TableHeaderCell';
import ModalFileImport from '../../../organisms/ModalFileImport';
import ModalErrorMessage from '../../../organisms/ModalErrorMessage';
import ModalInfoMessage from '../../../organisms/ModalInfoMessage';

import User from '../../../../utils/user';

const FIXED_WIDTH = (150 + 150);
const UNFIXED_NUM = 5;

const TABLE_HEADER = [
  {
    id: 'type',
    label: '種別',
    width: '150px',
  },
  {
    id: 'fileName',
    label: 'アップロードデータ',
    width: `calc(2 * (100% - ${FIXED_WIDTH}px) / ${UNFIXED_NUM})`,
  },
  {
    id: 'uploadDatetime',
    label: 'アップロード日',
    width: '150px',
  },
  {
    id: 'userId',
    label: 'アップロードした人',
    width: `calc((100% - ${FIXED_WIDTH}px) / ${UNFIXED_NUM})`,
  },
  {
    id: 'dataUploadStatus',
    label: 'ステータス',
    width: `calc((100% - ${FIXED_WIDTH}px) / ${UNFIXED_NUM})`,
  },
  {
    id: 'show',
    label: 'ステータスの表示',
    width: `calc((100% - ${FIXED_WIDTH}px) / ${UNFIXED_NUM})`,
  },
];

const StyledTable = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`

const StyledTableInfo = styled.div`
  display: flex;
  align-items: stretch;
  background-color: #fff;
`

// const StyledEditLink = styled(Link)`
//   color: ${COLOR.ORANGE};
//   text-decoration: none;
// `

const StyledEditLink = styled.div`
  color: ${COLOR.ORANGE};
  text-decoration: none;
  cursor: pointer;
`

const StyledTableInfoCell = styled.div`
  width: ${ props => props.width ? props.width : 'auto' };
  min-height: calc(1.75em + 28px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  white-space: ${ props => props.wrap ? props.wrap : 'nowrap' };
  line-height: 1.75;
  padding-top: 18px;
  padding-bottom: 10px;
  padding-right: 48px;
  padding-left: 18px;
  font-size: ${FONTSIZE.S}px;
  font-weight: 700;
  text-align: left;
  background-color: #fff;
`;

const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  list-style: none;
  cursor:pointer;
  font-size: ${FONTSIZE.S}px;
  font-weight: 600;
`

const backendApi = process.env.REACT_APP_BACKEND_URI;
const outsideSales = process.env.REACT_APP_OUTSIDE_SALES;

class ExternalDataHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sortId: '',
      responseData: [],
      cvDataUploadList: [],
      masterDataUploadList: [],
      isOpenedErrorModal: false,
      isOpenedModal: false,
      isOpenedUpdateModal: false,
      errorText: '',
      cvId: '',
      isUsingGa: '',
      clientId: '',
      isMaster: false,
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部コンバージョン設定');
    props.toggleDatePicker(false);
  }

  componentDidMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    // 設定値初期化
    this.props.initState();

    await axios.get( backendApi + 'thirdPartySetting', {
      params: {
        ...User.apiParams(),
        ...this.state.param,
      }
    })
    .then((response) => {
      if(response.data.length > 0) {
        this.setState({
          cvDataUploadList: response.data[0].cvDataUploadList,
          masterDataUploadList: response.data[0].masterDataUploadList,
          responseData: response.data
        });
      }
      //　プログレス非表示
      this.props.endProgressing();
    })
    .catch((error) => {
      //　プログレス非表示
      this.setState({
        isOpenedErrorModal: true,
        errorText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
      });
      this.props.endProgressing();
    })
  }

  render() {
    const IMPORT_URL = backendApi + 'thirdPartyUploadModify';
    const goToSettingPage = (isUsingGa, clientId, clientData) => {
      this.props.setUsingGa(isUsingGa);
      this.props.changeClientId(clientId);
      this.props.setClientData(clientData);
      this.props.history.push('/setting/external-data/setting-client');
    }
    // マスターデータ/CV実績データ取り込み状況ファイルダウンロード
    const dlUploadtedResultFile = async (funcNum, id) => {
      // パラメータ設定
      const baseUrl = backendApi + 'thirdPartyUpload';
      const params = {
        func: funcNum,
        id: id,
        ...User.apiParams(),
      }

      // データ取得
      await axios.get(baseUrl, {
        params: params
      })
      .then((response) => {
        // DL
        const url = new Url(baseUrl, true);
        url.set('query', params);
        var element = document.createElement('a');
        element.href = url.toString();
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        //　プログレス非表示
        this.props.endProgressing();
      })
      .catch((error) => {
        this.setState({
          isOpenedErrorModal: true,
          errorText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
        });
        //　プログレス非表示
        this.props.endProgressing();
      })
    }
    // インポートモーダル開閉
    const toggleImportModal = (boolean, cvId, isMaster) => {
      if(!cvId) {
        this.setState({
          isOpenedModal: boolean,
        });
      } else {
        this.setState({
          isOpenedModal: boolean,
          cvId: cvId,
          isMaster: isMaster,
        });
      }
    }
    // エラーモーダル開閉
    const toggleErrorModal = (boolean) => {
      this.setState({
        isOpenedErrorModal: boolean
      });
    }
    // エラーモーダルの本文を書き換える
    const changeErrorText = (text) => {
      this.setState({
        errorText: text
      });
    }
    // infoモーダルを開く
    const openInfoModal = (text) => {
      this.setState({
        isOpenedInfoModal: true,
        infoText: text
      });
    }
    // infoモーダルを閉じる
    const closeInfoModal = () => {
      this.setState({
        isOpenedInfoModal: false
      });
    }
    // マスターテンプレートダウンロード
    const getTemplateMaster = (e) => {
      e.preventDefault();
      var element = document.createElement('a');
      element.href = 'https://mmerge.jp/master.csv';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    // マスターテンプレートダウンロード
    const getTemplateMasterMacro = (e, keyType) => {
      if(keyType === 0){
        alert("選択されたパターンではマクロは必要ありません");
      }else{
        e.preventDefault();
        var element = document.createElement('a');
        //element.href = 'https://mmerge.jp/master.xlsm'
        // keyTypeによって対応するマクロを変更する
        if(keyType === 1){
          element.href = 'https://mmerge.jp/master_utm5.xlsm';
        }else if(keyType === 2){
          element.href = 'https://mmerge.jp/master_utm3.xlsm';
        }
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }
    // マスターテンプレートダウンロード(excel)
    const getTemplateMasterExcel = async (clientId) => {
      // パラメータ設定
      const baseUrl = backendApi + 'masterTemplate';
      const params = {
        id: clientId,
        ...User.apiParams(),
      }

      // データ取得
      await axios.get(baseUrl, {
        params: params
      })
      .then((response) => {
        // DL
        const url = new Url(baseUrl, true);
        url.set('query', params);
        var element = document.createElement('a');
        element.href = url.toString();
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        //　プログレス非表示
        this.props.endProgressing();
      })
      .catch((error) => {
        this.setState({
          isOpenedErrorModal: true,
          errorText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
        });
        //　プログレス非表示
        this.props.endProgressing();
      })
    }
    // コンバージョンテンプレートダウンロード
    const getTemplateConv = (e) => {
      e.preventDefault();
      var element = document.createElement('a');
      element.href = 'https://mmerge.jp/cv.csv';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    // コンバージョンテンプレートダウンロード(クライアント個別)
    const getTemplateConvClient = async (settingId) => {
      // パラメータ設定
      const baseUrl = backendApi + 'cvTemplate';
      const params = {
        id: settingId,
        ...User.apiParams(),
      }

      // データ取得
      await axios.get(baseUrl, {
        params: params
      })
      .then((response) => {
        // DL
        const url = new Url(baseUrl, true);
        url.set('query', params);
        var element = document.createElement('a');
        element.href = url.toString();
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        //　プログレス非表示
        this.props.endProgressing();
      })
      .catch((error) => {
        this.setState({
          isOpenedErrorModal: true,
          errorText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
        });
        //　プログレス非表示
        this.props.endProgressing();
      })
    }
    // アカウントデータダウンロード
    const getAccountData = async (clientId) => {
      // パラメータ設定
      const baseUrl = backendApi + 'account';
      const params = {
        clientId: clientId,
        dl: 2,
        ...User.apiParams(),
      }

      // データ取得
      await axios.get(baseUrl, {
        params: params
      })
      .then((response) => {
        // DL
        const url = new Url(baseUrl, true);
        url.set('query', params);
        var element = document.createElement('a');
        element.href = url.toString();
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        //　プログレス非表示
        this.props.endProgressing();
      })
      .catch((error) => {
        this.setState({
          isOpenedErrorModal: true,
          errorText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
        });
        //　プログレス非表示
        this.props.endProgressing();
      })
    }
    let msg = '　12時までにアップロードいただいたデータは14時以降に表示され、16時までにアップロードいただいたデータは18時以降に表示されます'
    if(outsideSales === "1"){
      msg = '　11時までにアップロードいただいたデータは13時以降に表示され、15時までにアップロードいただいたデータは17時以降に表示されます'
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isOpenedErrorModal }
          close = { (() => toggleErrorModal(false)) }
          bodyText = { this.state.errorText }
        ></ModalErrorMessage>
        <ModalInfoMessage
        heading = 'info'
        isOpened = { this.state.isOpenedInfoModal }
        close = { closeInfoModal }
        bodyText = { this.state.infoText }
        ></ModalInfoMessage>
        <ModalFileImport
          heading = {this.state.isMaster ? '管理表マスターアップロード' : 'コンバージョン実績アップロード'}
          apiUrl = { IMPORT_URL }
          apiParams = { User.apiParams() }
          isOpened = { this.state.isOpenedModal }
          funcId = {this.state.isMaster ? '2' : '1'}
          params = {{ id: this.state.cvId }}
          closeImportModal = { (() => toggleImportModal(false)) }
          changeErrorText = { changeErrorText }
          openErrorModal = { (() => toggleErrorModal(true) ) }
        />
        <FlexBox
          className="m-b-32"
          justify="flex-end"
        >
          <Button
            as = { Link }
            to = '/setting/external-data/client'
          >
            新規外部データ連携
            <IconPlus className="m-l-8" />
          </Button>
        </FlexBox>
        <div>
          <p>初めて設定する場合は「新規外部データ連携＋」ボタンを押してください</p>
          <p>変更する場合は「設定の編集」を押してください</p>
          <StyledInfo
            onClick = {
              (() => {
                openInfoModal('<p>・アップロード後、1時間ごとに取り込まれます。また管理表マスターの更新を待たずにコンバージョン実績をアップロードいただいても問題ありません</p><p>・また紐づけキーの追加を行う際は差分ではなく全件でご登録ください</p><p>・使用しなくなったパラメータも残しておくことを推奨します　消してアップロードされた場合、マスターからは消え紐づかなくなります</p><p>・システムに取り込まれるファイル形式、カラム表記が特殊なため、管理表マスタ登録用のマクロをご利用いただくことを推奨しております</p><p>・登録にはアカウントID必要となり、以下の「アカウントID」よりIDを確認することが可能です</p>');
              })
            }
          >
            <IconAttention className="m-l-8" />【管理表マスターについての注意点】
          </StyledInfo>
          <StyledInfo
            onClick = {
              (() => {
                openInfoModal('<p>・同日、同パラメータのデータがアップロードされた場合は上書きされますのでご注意ください</p><p>・デバイス情報のある媒体の場合、デバイス情報も外部ツールから取得の上アップロードしてください</p><p>　(Google広告、Yahoo広告、Facebook広告、twitterはデバイス情報が必要なメディアです)</p><p>・過去にアップロードした日と重複した日を含むファイルがアップロードされた場合、同パラメータの場合は上書きされます</p>' + msg + '<p>・登録する頻度は自由ですが毎日登録することで精度の高いデータ集計となりますため推奨しております</p>');
              })
            }
          >
            <IconAttention className="m-l-8" />【コンバージョン実績についての注意点】
          </StyledInfo>
        </div>
        <div
          key = { _.uniqueId() }
          className = "m-b-32"
          >
          {
            (() => {
              return this.state.responseData.map((table,index) => {
                return (
                  <StyledTable
                    key = { `table_${index}` }
                    windowHeight = { this.props.windowHeight }
                    className = "m-b-64"
                  >
                    {/* masterアップロード一覧 */}
                    <StyledTableInfo>
                      <StyledTableInfoCell
                        width = { `calc(400px + (2 * (100% - ${FIXED_WIDTH}px) / ${UNFIXED_NUM}))` }
                        wrap = "nowrap"
                        style = {{ 'cursor': 'pointer' }}
                        onClick = {
                          () => {
                            goToSettingPage(
                            !!table.viewId,
                            table.clientId,
                            {
                              cvId: table.id,
                              clientName: table.clientName,
                              metrics: table.metrics,
                              viewId: table.viewId,
                              dimensions: table.dimensions,
                              keyType: table.keyType,
                            }
                          )}
                        }
                      >
                        クライアント名：{
                          table.clientName
                        }  管理表マスターアップロード結果一覧（設定の編集）
                      </StyledTableInfoCell>
                      <StyledTableInfoCell>
                        <div>
                          <div>管理表マスター</div>
                          <FlexBox>
                            <FlexBox
                              align = 'flex-end'
                              style = {{ 'cursor': 'pointer' }}
                              onClick = { (e) => getTemplateMaster(e) }
                              >テンプレート
                              <IconExport
                                className = 'm-l-8'
                              />
                            </FlexBox>
                            <FlexBox
                              align = 'flex-end'
                              style = {{ 'cursor': 'pointer' }}
                              onClick = { (e) => getTemplateMasterMacro(e, table.keyType) }
                              >テンプレート(マクロ)
                              <IconExport
                                className = 'm-l-8'
                              />
                            </FlexBox>
                            <FlexBox
                              align = 'flex-end'
                              style = {{ 'cursor': 'pointer' }}
                              onClick = { () => getAccountData(table.clientId) }
                              >アカウントID
                              <IconExport
                                className = 'm-l-8'
                              />
                            </FlexBox>
                            <FlexBox
                              align = 'flex-end'
                              className = 'm-l-16'
                              style = {{ 'cursor': 'pointer' }}
                              onClick = { () => toggleImportModal(true, table.id, true) }
                              >アップロード
                              <IconImport
                                className = 'm-l-8'
                              />
                            </FlexBox>
                          </FlexBox>
                        </div>
                      </StyledTableInfoCell>
                    </StyledTableInfo>
                    <TableHeader>
                      {
                        (() => {
                          return TABLE_HEADER.map(o => {
                            return (
                              <TableHeaderCell
                                key = { _.uniqueId() }
                                style = { { minWidth: `${o.width}` } }
                                data-id = { o.id }
                              >
                                { o.label }
                              </TableHeaderCell>
                            )
                          })
                        })()
                      }
                    </TableHeader>
                    <div>
                      {
                        (() => {
                          if(table.masterDataUploadList) {
                            return table.masterDataUploadList.map(r => {
                              return (
                                <TableBodyRow
                                  key = { _.uniqueId() }
                                >
                                  {
                                    (() => {
                                      return TABLE_HEADER.map(o => {
                                        return (
                                          <TableDataCell
                                            key = { _.uniqueId() }
                                            style = { { minWidth: `${o.width}` } }
                                          >
                                            {
                                              (() => {
                                                switch (o.id) {
                                                  case 'type':
                                                    return '管理表マスター';
                                                  case 'uploadDatetime':
                                                    return (
                                                      <div>
                                                        { format(r[o.id], 'YYYY/MM/DD HH:mm') }
                                                      </div>
                                                    )
                                                  case 'dataUploadStatus':
                                                    switch(r[o.id]) {
                                                      case 0:
                                                        return 'アップロード前';
                                                      case 1:
                                                        return '同期中';
                                                      case 2:
                                                        return '同期完了';
                                                      case 4:
                                                        return 'エラー';
                                                      default:
                                                        return r[o.id]
                                                    }
                                                  case 'userId':
                                                    return r[o.id];
                                                  case 'show':
                                                    return (
                                                      <StyledEditLink
                                                        onClick = { (() => dlUploadtedResultFile(
                                                          2, table.id
                                                        )) }
                                                      >ダウンロード
                                                      </StyledEditLink>
                                                    )
                                                  default:
                                                    return r[o.id];
                                                }
                                              })()
                                            }
                                          </TableDataCell>
                                        )
                                      })
                                    })()
                                  }
                                </TableBodyRow>
                              )
                            })
                          } else {
                            return (<div></div>)
                          }
                        })()
                      }
                    </div>
                    {/* cvアップロード一覧 */}
                    <StyledTableInfo>
                      <StyledTableInfoCell
                        width = { `calc(400px + (2 * (100% - ${FIXED_WIDTH}px) / ${UNFIXED_NUM}))` }
                        wrap = "nowrap"
                        style = {{ 'cursor': 'pointer' }}
                        onClick = {
                          () => {
                            goToSettingPage(
                            !!table.viewId,
                            table.clientId,
                            {
                              cvId: table.id,
                              clientName: table.clientName,
                              metrics: table.metrics,
                              viewId: table.viewId,
                              dimensions: table.dimensions,
                              keyType: table.keyType,
                            }
                          )}
                        }
                      >
                        クライアント名：{
                          table.clientName
                        }  コンバージョン実績アップロード結果一覧（設定の編集）
                        
                      </StyledTableInfoCell>
                      <StyledTableInfoCell>
                        <div>
                          <div>コンバージョン実績</div>
                          <FlexBox>
                            <FlexBox
                              align = 'flex-end'
                              style = {{ 'cursor': 'pointer' }}
                              onClick = { () => getTemplateConvClient(table.id) }
                              >テンプレート
                              <IconExport
                                className = 'm-l-8'
                              />
                            </FlexBox>
                            <FlexBox
                              align = 'flex-end'
                              className = 'm-l-16'
                              style = {{ 'cursor': 'pointer' }}
                              onClick = { () => toggleImportModal(true, table.id, false) }
                              >アップロード
                              <IconImport
                                className = 'm-l-8'
                              />
                            </FlexBox>
                          </FlexBox>
                        </div>
                      </StyledTableInfoCell>
                    </StyledTableInfo>
                    <TableHeader>
                      {
                        (() => {
                          return TABLE_HEADER.map(o => {
                            return (
                              <TableHeaderCell
                                key = { _.uniqueId() }
                                style = { { minWidth: `${o.width}` } }
                                data-id = { o.id }
                              >
                                { o.label }
                              </TableHeaderCell>
                            )
                          })
                        })()
                      }
                    </TableHeader>
                    <div>
                      {
                        (() => {
                          if(table.cvDataUploadList) {
                            return table.cvDataUploadList.map(r => {
                              return (
                                <TableBodyRow
                                  key = { _.uniqueId() }
                                >
                                  {
                                    (() => {
                                      return TABLE_HEADER.map(o => {
                                        return (
                                          <TableDataCell
                                            key = { _.uniqueId() }
                                            style = { { minWidth: `${o.width}` } }
                                          >
                                            {
                                              (() => {
                                                switch (o.id) {
                                                  case 'type':
                                                    return 'CV実績';
                                                  case 'uploadDatetime':
                                                    return (
                                                      <div>
                                                        { format(r[o.id], 'YYYY/MM/DD HH:mm') }
                                                      </div>
                                                    )
                                                  case 'dataUploadStatus':
                                                    switch(r[o.id]) {
                                                      case 0:
                                                        return 'アップロード前';
                                                      case 1:
                                                        return '同期中';
                                                      case 2:
                                                        return '同期完了';
                                                      case 3:
                                                        return 'エラー';
                                                      default:
                                                        return r[o.id]
                                                    }
                                                  case 'userId':
                                                    return r[o.id];
                                                  case 'show':
                                                    return (
                                                      <StyledEditLink
                                                        onClick = { (() => dlUploadtedResultFile(
                                                          1, r["id"]
                                                        )) }
                                                      >ダウンロード
                                                      </StyledEditLink>
                                                    )
                                                  default:
                                                    return r[o.id];
                                                }
                                              })()
                                            }
                                          </TableDataCell>
                                        )
                                      })
                                    })()
                                  }
                                </TableBodyRow>
                              )
                            })
                          } else {
                            return (<div></div>)
                          }
                        })()
                      }
                    </div>
                  </StyledTable>
                )
              })
            })()
          }
        </div>
      </div>
    )
  }
}

export default ExternalDataHome;
