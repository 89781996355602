import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import COLOR from '../_const/COLOR'
import DISPITEM_ALERT from '../_const/DISPITEM_ALERT'
import FONTSIZE from '../_const/FONTSIZE'
import REPORT_TARGETS from '../_const/REPORT_TARGETS'

import DividerDottedH from './DividerDottedH';

const StyledWrap = styled(Link)`
  display: flex;
  text-decoration: none;
  padding: 12px 16px;
  ${
    props => {
      if (props.isAlreadyRead === true) {
        return `
          color: ${COLOR.BLACK};
        `
      } else {
        return `
          color: ${COLOR.RED};
          background-color: ${COLOR.LIGHTRED};
        `
      }
    }
  }
`
const StyledDate = styled.div`
  width: 8em;
  flex-shrink: 0;
`
const StyledBadge = styled.div`
  display: inline-block;
  line-height: 1;
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 2px;
  font-size: ${FONTSIZE.SS}px;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.RED};
`
const StyledDivider = styled(DividerDottedH)`
  margin-top: 4px;
  margin-bottom: 4px;
`

const renderSentence = (targetColId, targetIsRelative, timing, timingCustom, targetDay, targetNum, compEqualSign) => (
  <span>
    「
    {
      (() => {
        return _.find(DISPITEM_ALERT, o => { return targetColId === o.id }).label;
      })()
    }
    」
    { (timing === 0) ? 'の' : 'において' }
    {
      (() => {
        switch (timing) {
          case 1:
            return `昨日と比較した`;
          case 2:
            return `過去7日間と比較した`;
          case 3:
            return `過去14日間と比較した`;
          case 4:
            return `過去30日間と比較した`;
          case 5:
            return `先月と比較した`;
          case 6:
            return `残り${ timingCustom }日時点での`;
          case 7:
            return `月初から${ targetDay }日経過した時点での`;
          case 0:
          default:
            return '';
        }
      })()
    }
    {
      (() => {
        return (timing === 0)
          ? '値が'
          : (targetIsRelative === 0)
            ? '値の差が'
            : '値の割合が';
      })()
    }
    {
      (() => {
        const unit = _.find(DISPITEM_ALERT, o => { return targetColId === o.id }).unit;
        if (targetIsRelative === 2) {
          return `${targetNum}%`
        } else if (unit !== '%') {
          return `${targetNum.toLocaleString()}${unit}`;
        } else {
          return `${targetNum}${unit}`;
        }
      })()
    }
    {
      (() => {
        if (timing === 0 || timing === 6 || timing === 7) {
          switch (compEqualSign) {
            case 1: return 'より大きくなり';
            case 2: return 'より小さくなり';
            case 3: return '以上になり';
            case 4: return '以下になり';
            case 5: return 'と等しくなり';
            case 0:
            default: return '';
          }
        } else {
          switch (compEqualSign) {
            case 1: return 'より大きく増加し';
            case 2: return 'より大きく減少し';
            case 3: return '以上増加し';
            case 4: return '以下減少し';
            case 5: return 'と等しくなり';
            case 0:
            default: return '';
          }
        }
      })()
    }
  </span>
);

const OccurredAlertItem = ({ opts }) => (
  <div>
    <StyledWrap
      to = { `/progress/${ opts.target }/?alertId=${ opts.id }` }
      isAlreadyRead = { opts.isAlreadyRead }
    >
      <StyledDate>{ opts.date }</StyledDate>
      <div>
        { _.find(REPORT_TARGETS, o => { return opts.target === o.id }).label }
        {
          (() => {
            // alertId>0だけだと、アラート履歴にデータがあっても、アラート設定からデータが削除されたケースでエラーになるので、opts.customが設定されていること（アラート設定があること）も条件にした
            if (opts.alertId > 0 && opts.custom !== undefined) {
              return (
                <span>
                  「{ opts.label }」の
                  {
                    (() => {
                      if (opts.custom.addConditionKind === 2) {
                        return (
                          <span>
                            {
                              renderSentence(
                                opts.custom.targetColId,
                                opts.custom.targetIsRelative,
                                opts.custom.timing,
                                opts.custom.timingCustom,
                                opts.custom.targetDay,
                                opts.custom.targetNum,
                                opts.custom.compEqualSign
                              )
                            }
                            、
                            <br />
                            且つ
                            {
                              renderSentence(
                                opts.custom.targetColIdAdd,
                                opts.custom.targetIsRelativeAdd,
                                opts.custom.timingAdd,
                                opts.custom.timingCustomAdd,
                                opts.custom.targetDayAdd,
                                opts.custom.targetNumAdd,
                                opts.custom.compEqualSignAdd
                              )
                            }
                            ました。
                          </span>
                        )
                      } else {
                        return (
                          <span>
                            {
                              renderSentence(
                                opts.custom.targetColId,
                                opts.custom.targetIsRelative,
                                opts.custom.timing,
                                opts.custom.timingCustom,
                                opts.custom.targetDay,
                                opts.custom.targetNum,
                                opts.custom.compEqualSign
                              )
                            }
                            ました。
                          </span>
                        )
                      }
                    })()
                  }
                </span>
              )
            } else {
              return (
                <span>
                  「{ opts.label }」が
                  {
                    (() => {
                      switch (opts.alertId) {
                        case -1: return '媒体予算切れ';
                        case -2: return '媒体予算オーバーペース';
                        case -3: return 'ヨミ予算切れ';
                        case -4: return 'ヨミ予算オーバーペース';
                        default: return;
                      }
                    })()
                  }
                  です。
                </span>
              )
            }
          })()
        }
        {
          (() => {
            if (opts.isAlreadyRead === false) {
              return (
                <StyledBadge>未読</StyledBadge>
              )
            }
          })()
        }
      </div>
    </StyledWrap>
    <StyledDivider
      width = '100%'
    />
  </div>
);

export default OccurredAlertItem;
