import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Button from '../../../atoms/Button';
import BarBottom from '../../../atoms/BarBottom';
import HeadingS from '../../../atoms/HeadingS';
import TableHeader from '../../../atoms/TableHeader';
import TableHeaderCell from '../../../atoms/TableHeaderCell';
import TableDataCell from '../../../atoms/TableDataCell';
import TableBodyRow from '../../../atoms/TableBodyRow';
import StepNavi from '../../../molecules/StepNavi';

import User from '../../../../utils/user';

const StyledTable = styled.div`
  width: 100%;
  max-height: ${ props => props.windowHeight - 120 - 106 - 44 - 40 }px;
  overflow: scroll;
  position: relative;
  z-index: 1;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

class ExternalDataSample extends React.Component {
  constructor(props) {
    super(props);
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = () => {
    if(!this.props.clientId) {
      this.props.history.push('/setting/external-data');
    }
  }

  handleToPrevPage = () => {
    this.props.history.goBack();
  }

  handleToNextPage = async () => {
    await await axios.get(backendApi + 'thirdPartySettingModify', {
      params: {
        func: 1,
        clientId: this.props.clientId,
        viewId: this.props.clientData.viewId,
        dimensions: this.props.clientData.dimensions,
        metrics: this.props.clientData.metrics,
        keyType: this.props.clientData.keyType,
        ...User.apiParams(),
      }
    }).then((response) => {
      this.props.history.push('/setting/external-data/edit');
    }).catch(error => {
      console.log(error);
    })
  }

  render() {
    return (
      <div>
        <StepNavi
          step = { 2 }
          navi = {this.props.navi[`${this.props.isUsingGa ? 1 : 0}`]}
        />
        {
          (() => {
            if(this.props.sampleCode){
              return (
                <div>
                  <HeadingS
                    className = 'm-t-16'
                    >サンプルを表示します。問題がない場合は「次に進む」をクリックしてください。</HeadingS>
                  <HeadingS
                    className = 'm-t-16'
                    >※GAのタイムアウトによりデータが取得できない場合はsampleというデータのみを表示していますので、その場合も「次に進む」をクリックしてください。</HeadingS>
                  <StyledTable
                    windowHeight = { this.props.windowHeight }
                    >
                    <TableHeader>
                      {
                        (() => {
                          return this.props.sampleCode.header.map((o, i) => {
                            return (
                              <TableHeaderCell
                                key = { 'th_' + i }
                                style = { { width: '200px' } }
                                >{ o }</TableHeaderCell>
                            )
                          })
                        })()
                      }
                    </TableHeader>
                    <div>
                      {
                        (() => {
                          return this.props.sampleCode.rows.map((rowItem, i) => {
                            return (
                              <TableBodyRow
                                key = { `itemRow${i}` }
                                >
                                {
                                  (() => {
                                    return rowItem.map((item, j) => {
                                      return (
                                        <TableDataCell
                                          key = { `item${j}` }
                                          style = { { width: '200px' } }
                                          >
                                          { item }
                                        </TableDataCell>
                                      );
                                    })
                                  })()
                                }
                              </TableBodyRow>
                            )
                          })
                        })()
                      }
                    </div>
                  </StyledTable>
                </div>
              )
            } else {
              return (<div></div>)
            }
          })()
        }
        <BarBottom>
          <Button
            onClick = { this.handleToPrevPage }
          >
            前に戻る
          </Button>
          {
            (() => {
              if (true) {
                return (
                  <Button
                    color="orange"
                    className = 'm-l-8'
                    onClick = { this.handleToNextPage }
                  >
                    次に進む
                  </Button>
                )
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default ExternalDataSample;
