import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { format } from 'date-fns';

import COLOR from '../_const/COLOR';
import DISPITEM_TYPE from '../_const/DISPITEM_TYPE';
import FONTSIZE from '../_const/FONTSIZE';

import FlexBox from '../atoms/FlexBox';
import GraphHoverItem from './GraphHoverItem';
import PullDownCommon from './PullDownCommon';

import arrangeReportDataValue from '../_util/arrangeReportDataValue';

const outsideSales = process.env.REACT_APP_OUTSIDE_SALES;

// const SUMMARIES = [
//   { id: 'imp', label: '表示回数' },
//   { id: 'click', label: 'クリック数' },
//   { id: 'ctr', label: 'CTR' },
//   { id: 'cost', label: '費用' },
//   { id: 'cost_with_fee', label: '費用(込)' },
//   { id: 'cpc', label: 'CPC' },
//   { id: 'cpc_with_fee', label: 'CPC(込)' },
//   { id: 'cv', label: 'CV数' },
//   { id: 'cvr', label: 'CVR' },
//   { id: 'cpa', label: 'CPA' },
//   { id: 'cpa_with_fee', label: 'CPA(込)' },
//   { id: 'roas', label: 'ROAS' },
//   { id: 'roas_with_fee', label: 'ROAS(込)' },
//   { id: 'roi', label: 'ROI' },
//   { id: 'roi_with_fee', label: 'ROI(込)' },
//   { id: 'revenue', label: '売上金額' },
//   { id: 'revenueNotTax', label: '売上金額(税抜)' },
// ];
const SUMMARIES = (() => {
  if(outsideSales === "0"){
    // 社内向け
    return [
      { id: 'imp', label: '表示回数' },
      { id: 'click', label: 'クリック数' },
      { id: 'ctr', label: 'CTR' },
      { id: 'cost', label: '費用' },
      { id: 'cost_with_fee', label: '費用(込)' },
      { id: 'cpc', label: 'CPC' },
      { id: 'cpc_with_fee', label: 'CPC(込)' },
      { id: 'cv', label: 'CV数' },
      { id: 'cvr', label: 'CVR' },
      { id: 'cpa', label: 'CPA' },
      { id: 'cpa_with_fee', label: 'CPA(込)' },
      { id: 'roas', label: 'ROAS' },
      { id: 'roas_with_fee', label: 'ROAS(込)' },
      { id: 'roi', label: 'ROI' },
      { id: 'roi_with_fee', label: 'ROI(込)' },
      { id: 'revenue', label: '売上金額' },
      { id: 'revenueNotTax', label: '売上金額(税抜)' },
    ];
  }else{
    // 外販向け
    return [
      { id: 'imp', label: '表示回数' },
      { id: 'click', label: 'クリック数' },
      { id: 'ctr', label: 'CTR' },
      { id: 'cost', label: '費用' },
      { id: 'cpc', label: 'CPC' },
      { id: 'cv', label: 'CV数' },
      { id: 'cvr', label: 'CVR' },
      { id: 'cpa', label: 'CPA' },
      { id: 'roas', label: 'ROAS' },
      { id: 'roi', label: 'ROI' },
      { id: 'revenue', label: '売上金額' },
      { id: 'revenueNotTax', label: '売上金額(税抜)' },
    ];
  }
})();
const StyledGraphWrap = styled.div`
  position: relative;
`
const StyledSummaryLine = styled.div.attrs(
  ({ isEnabledCompare, summaryNo }) => {
    return {
      style: {
        ...(isEnabledCompare === false)
        ? {
          height: '2px'
        }
        : {
          height: '8px',
          borderBottomWidth: '2px',
          borderBottomStyle: 'dashed',
        }
        ,
        borderColor: (summaryNo === 1) ? COLOR.ORANGE : COLOR.BLUE
      }
    };
  }
)`
  width: 16px;
  box-sizing: border-box;
  margin-right: 8px;
  border-top-width: 2px;
  border-right: 0;
  border-left: 0;
  border-top-style: solid;
`
const StyledPullDownWrap = styled.div`
  height: (40 * 2 + 8px);
  margin-bottom: 16px;
`
const StyledPullDownColLabel = styled.div`
  margin-right: 8px;
  font-weight: 600;
`
const StyledGraphBody = styled.div`
  width: 100%;
  height: 280px;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #d9d9d9;
`
const StyledGraphSvg = styled.svg`
  width: calc(100% - 96px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin-right: auto;
  margin-left: auto;
  & > path {
    &.is-dash {
      stroke-dasharray: 3;
    }
  }
`
const StyledGraphPoint = styled.div.attrs(
  (props) => {
    return {
      style: {
        top: props.y,
        left: props.x,
        borderStyle: (props.isComparing === false) ? 'solid' : 'dashed',
        borderColor: (props.summaryNo === 1) ? COLOR.ORANGE : COLOR.BLUE,
      }
    }
  }
)`
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  margin-top: -6px;
  margin-left: -6px;
  border-width: 1px;
  border-radius: 50%;
`
const StyledGraphPointIn = styled.div.attrs(
  (props) => {
    return {
      style: {
        backgroundColor: (props.summaryNo === 1) ? COLOR.ORANGE : COLOR.BLUE,
      }
    }
  }
)`
  width: 6px;
  height: 6px;
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  border-radius: 50%;
`
const StyledGraphHoverWrap = styled.div`
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  right: 48px;
  left: 48px;
  z-index: 20;
`
const StyledGraphBgWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 0;
`
const StyledGraphBgLine = styled.div`
  height: 33.3%;
  &:nth-child(2) {
    height: 33.4%;
  }
  &:nth-child(2n+1) {
    background-color: ${COLOR.LIGHTGRAY};
  }
`
const StyledGraphBottomLabels = styled.div`
  width: calc(100% - 96px);
  height: 40px;
  position: relative;
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
`
const StyledGraphLabelH = styled.div.attrs(
  ({ left }) => {
    return {
      style: {
        left: `calc(${left}% - 5em)`
      }
    }
  }
)`
  width: 10em;
  height: 100%;
  line-height: 1;
  position: absolute;
  top: 0;
  font-size: ${FONTSIZE.SS}px;
  text-align: center;
`
const StyledGraphSideLabels = styled.div.attrs(
  ({ summaryNo }) => {
    return {
      style: (summaryNo === 1)
        ? {
          left: 0,
          color: COLOR.ORANGE,
        }
        : {
          right: 0,
          textAlign: 'right',
          color: COLOR.BLUE,
        }
    }
  }
)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 10;
`
const StyledGraphLabelV = styled.div`
  height: 33.33%;
  box-sizing: border-box;
  padding: 8px;
  line-height: 1;
`

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: 0,
      summary1: SUMMARIES[0],
      summary2: SUMMARIES[1],
    }
  }
  componentDidUpdate = async (prevProps, prevState) => {
    // クライアント/アカウント一覧の初期値を定義
    if (prevProps.candidate !== this.props.candidate && this.props.candidate.length > 0) {
      this.setState({
        itemId: this.props.candidate[0].id
      });
    }
  }
  render() {
    // レポートデータからサマリーに指定されているキーの値のみを抽出した配列にして返す
    const data = [
      _.map(_.filter(this.props.data, o => {
        return parseInt(o.id) === this.state.itemId;
      }), this.state.summary1.id),
      _.map(_.filter(this.props.dataCompare, o => {
        return parseInt(o.id) === this.state.itemId;
      }), this.state.summary1.id),
      _.map(_.filter(this.props.data, o => {
        return parseInt(o.id) === this.state.itemId;
      }), this.state.summary2.id),
      _.map(_.filter(this.props.dataCompare, o => {
        return parseInt(o.id) === this.state.itemId;
      }), this.state.summary2.id),
    ];
    // 配列の数値が文字列型になっているのを変換
    const intData = _.map(data, (d) => {
      return _.map(d, o => { return o * 1 });
    });
    // 配列の長さを取得
    const maxLength = Math.max(intData[0].length, intData[1].length);
    // 各配列ごと最大値を抽出
    const maxArray = intData.map((d) => {
      return _.max(d);
    });
    // 比較元と比較両データをあわせた最大値を抽出
    const arrangeMax = (arr1, arr2) => {
      const max = Math.max(((arr1) ? arr1 : 0), ((arr2) ? arr2 : 0));
      const digit = Math.pow(10, String(Math.floor(max)).length);
      return Math.ceil(Math.ceil(max * 100 / digit / 3) * digit) / 100 * 3;
    }
    const max1 = arrangeMax(maxArray[0], maxArray[1]);
    const max2 = arrangeMax(maxArray[2], maxArray[3]);
    // データが存在する日付を取得
    const date = [
      _.map(_.filter(this.props.data, o => {
        return parseInt(o.id) === this.state.itemId;
      }), 'date'),
      _.map(_.filter(this.props.dataCompare, o => {
        return parseInt(o.id) === this.state.itemId;
      }), 'date'),
    ];
    // サマリーのタイプを取得
    const type1 = DISPITEM_TYPE[this.state.summary1.id];
    const type2 = DISPITEM_TYPE[this.state.summary2.id];

    // ドロップダウンの項目の配列を定義
    const targets = [
      { id: 1, label: 'クライアント' },
      { id: 2, label: 'アカウント' },
    ];
    const parseCandidate = this.props.candidate.map(o => {
      return {
        id: o.id,
        label: o.name,
      }
    });
    const divisions = [
      { id: 1, label: '日別' },
      { id: 2, label: '週別' },
      { id: 3, label: '月別' },
    ];

    const switchTarget = async (id) => {
      await this.props.switchTarget(id);
      this.setState({
        itemId: parseInt(this.props.candidate[0].id),
      });
    }
    const switchItemId = (id) => {
      this.setState({
        itemId: id
      });
    }
    const switchSummary1 = (id) => {
      this.setState({
        summary1: _.find(SUMMARIES, o => { return o.id === id })
      });
    }
    const switchSummary2 = (id) => {
      this.setState({
        summary2: _.find(SUMMARIES, o => { return o.id === id })
      });
    }

    return (
      <StyledGraphWrap className={ this.props.className }>
        <StyledPullDownWrap>
          <FlexBox>
            {
              (() => {
                if (this.props.isEnabledSelectTarget === true) {
                  return (
                    <FlexBox
                      className = 'm-r-24'
                    >
                      <StyledPullDownColLabel>
                        表示するカテゴリ：
                      </StyledPullDownColLabel>
                      <PullDownCommon
                        id = { this.props.targetId }
                        items = { targets }
                        listWidth = { "150px" }
                        onChange = { switchTarget }
                      />
                    </FlexBox>
                  )
                }
              })()
            }
            <FlexBox
              className = 'm-r-24'
            >
              <StyledPullDownColLabel>
                {
                  (() => {
                    return (this.props.targetId === 1)
                      ? '表示するクライアント：'
                      : (this.props.targetId === 2)
                        ? '表示するアカウント：'
                        : '';
                  })()
                }
              </StyledPullDownColLabel>
              <PullDownCommon
                id = { this.state.itemId }
                items = { parseCandidate }
                onChange = { switchItemId }
              />
            </FlexBox>
            <FlexBox>
              <StyledPullDownColLabel>
                期間分割：
              </StyledPullDownColLabel>
              <PullDownCommon
                id = { this.props.division }
                items = { divisions }
                listWidth = { "120px" }
                onChange = { this.props.switchGraphSplit }
              />
            </FlexBox>
          </FlexBox>
          <FlexBox
            className = 'm-t-8'
          >
            <FlexBox
              className = 'm-r-24'
            >
              <StyledSummaryLine
                summaryNo = { 1 }
                isEnabledCompare = { this.props.isEnabledCompare }
              />
              <StyledPullDownColLabel>
                表示項目1：
              </StyledPullDownColLabel>
              <PullDownCommon
                id = { this.state.summary1.id }
                disableIds = { [this.state.summary2.id] }
                items = { SUMMARIES }
                listWidth = { "180px" }
                onChange = { switchSummary1 }
              />
            </FlexBox>
            <FlexBox>
              <StyledSummaryLine
                summaryNo = { 2 }
                isEnabledCompare = { this.props.isEnabledCompare }
              />
              <StyledPullDownColLabel>
                表示項目2：
              </StyledPullDownColLabel>
              <PullDownCommon
                id = { this.state.summary2.id }
                disableIds = { [this.state.summary1.id] }
                items = { SUMMARIES }
                listWidth = { "180px" }
                onChange = { switchSummary2 }
              />
            </FlexBox>
          </FlexBox>
        </StyledPullDownWrap>
        <StyledGraphBody>
          <StyledGraphSvg
            width = "100"
            height = "280"
            viewBox = "0, 0, 100, 280"
            preserveAspectRatio = "none"
          >
            {
              // グラフの線を追加
              (() => {
                // 各線のpath要素の座標を計算し、JSXに整形して返す。
                const paths = [];
                for (var i = 0; i < intData.length; i++) {
                  if (intData[i].length < 2) continue;
                  const num = Math.ceil((i + 1) / 2);
                  let d = 'M';

                  for (var j = 0; j < intData[i].length; j++) {
                    const x = j / (maxLength - 1) * 100;
                    const y = (i < 2)
                      ? (max1 === 0) ? 276 : 278 - intData[i][j] / max1 * 276
                      : (max2 === 0) ? 276 : 278 - intData[i][j] / max2 * 276;
                    d += `${x} ${y}`
                    if (j < maxLength) d += `, `
                  }

                  paths.push(
                    (
                      <path
                        key = { _.uniqueId() }
                        className = { i % 2 === 1 ? 'is-dash' : '' }
                        stroke = { (num <= 1) ? COLOR.ORANGE : COLOR.BLUE }
                        strokeWidth = { 2 }
                        fill = "none"
                        vectorEffect = "non-scaling-stroke"
                        d = { d }
                      />
                    )
                  )
                }
                return paths;
              })()
            }
          </StyledGraphSvg>
          {
            // グラフの点を追加
            (() => {
              return intData.map((d, i) => {
                return d.map((o, j) => {
                  const x = (maxLength <= 1)
                    ? '50%'
                    : `calc((100% - 96px) * ${j / (maxLength - 1)} + 48px)`;
                  const y = (i < 2)
                    ? (max1 === 0) ? 275 : 278 - o / max1 * 275
                    : (max2 === 0) ? 275 : 278 - o / max2 * 275;
                  return (
                    <StyledGraphPoint
                      key = { _.uniqueId() }
                      x = { x }
                      y = { `${ Math.floor(y) }px` }
                      summaryNo = { Math.floor(i / 2) + 1 }
                      isComparing = { i % 2 === 1 }
                    >
                      <StyledGraphPointIn
                        summaryNo = { Math.floor(i / 2) + 1 }
                      />
                    </StyledGraphPoint>
                  )
                });
              });
            })()
          }
          <StyledGraphHoverWrap>
            {
              (() => {
                const items = [];
                for (var i = 0; i < maxLength; i++) {
                  items.push(
                    (
                      <GraphHoverItem
                        key = { _.uniqueId() }
                        index = { i }
                        length = { maxLength }
                        summary1 = { this.state.summary1 }
                        summary2 = { this.state.summary2 }
                        date = { date[0][i] }
                        dateCompare = { date[1][i] }
                        data1 = { data[0][i] }
                        data1Compare = { data[1][i] }
                        data2 = { data[2][i] }
                        data2Compare = { data[3][i] }
                        max1 = { max1 }
                        max2 = { max2 }
                        isEnabledCompare = { this.props.isEnabledCompare }
                      />
                    )
                  )
                }
                return items;
              })()
            }
          </StyledGraphHoverWrap>
          <StyledGraphBgWrap>
            <StyledGraphBgLine />
            <StyledGraphBgLine />
            <StyledGraphBgLine />
          </StyledGraphBgWrap>
          <StyledGraphSideLabels
            summaryNo = { 1 }
          >
            <StyledGraphLabelV>
              <span>
                { arrangeReportDataValue(max1, type1) }
              </span>
            </StyledGraphLabelV>
            <StyledGraphLabelV>
              <span>
                { arrangeReportDataValue(max1 / 3 * 2, type1) }
              </span>
            </StyledGraphLabelV>
            <StyledGraphLabelV>
              <span>
                { arrangeReportDataValue(max1 / 3, type1) }
              </span>
            </StyledGraphLabelV>
          </StyledGraphSideLabels>
          <StyledGraphSideLabels
            summaryNo = { 2 }
          >
            <StyledGraphLabelV>
              <span>
                { arrangeReportDataValue(max2, type2) }
              </span>
            </StyledGraphLabelV>
            <StyledGraphLabelV>
              <span>
                { arrangeReportDataValue(max2 / 3 * 2, type2) }
              </span>
            </StyledGraphLabelV>
            <StyledGraphLabelV>
              <span>
                { arrangeReportDataValue(max2 / 3, type2) }
              </span>
            </StyledGraphLabelV>
          </StyledGraphSideLabels>
        </StyledGraphBody>
        <StyledGraphBottomLabels>
          {
            (() => {
              return date[0].map((d, i) => {
                let value = (this.props.division === 3)
                  ? d
                  : (maxLength > 32 && (i === 0 || parseInt(format(d, 'DD')) === 1))
                    ? format(d, 'YYYY年MM月')
                    : (
                      maxLength <= 16
                      || (maxLength <= 24 && i % 2 === 0)
                      || (maxLength <= 32 && i % 3 === 0)
                    )
                      ? format(d, 'MM月DD日')
                      : ''
                ;

                return (
                  <StyledGraphLabelH
                    key = { _.uniqueId() }
                    left = { i / (maxLength - 1) * 100 }
                  >
                    <span>{ value }</span>
                  </StyledGraphLabelH>
                )
              });
            })()
          }
        </StyledGraphBottomLabels>
      </StyledGraphWrap>
    )
  }
}
export default Graph;
