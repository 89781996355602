import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ExternalDataHome     from './ExternalDataContent/ExternalDataHome';
import ExternalDataClient   from './ExternalDataContent/ExternalDataClient';
import ExternalDataGa       from './ExternalDataContent/ExternalDataGa';
import ExternalDataGaId     from './ExternalDataContent/ExternalDataGaId';
import ExternalDataGaGoal   from './ExternalDataContent/ExternalDataGaGoal';
import ExternalDataGaSample from './ExternalDataContent/ExternalDataGaSample';

import ExternalDataUpload1      from './ExternalDataContent/ExternalDataUpload1';
import ExternalDataUpload2      from './ExternalDataContent/ExternalDataUpload2';

import ExternalDataEdit   from './ExternalDataContent/ExternalDataEdit';
import ExternalDataFinish from './ExternalDataContent/ExternalDataFinish';

import ExternalDataSetting from './ExternalDataContent/ExternalDataSetting';

class ExternalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: null, // クライアントID
      gaIndexData: null, // gaで取得したデータ
      isUsingGa: null, // gaを使用しているかどうか
      isNewData: false, // 新規作成かどうか
      sampleCode: null, // 取得したサンプルコード
      clientData: {
        cvId: '', // 設定ID
        viewId: '', // GAID
        clientName: '', // クライアント名
        metrics: '', // gaでユーザが設定したmetrics
        dimensions: '', // gaでユーザが設定したディメンション
        keyType: null, // gaでユーザが設定した紐付けkeyのタイプ
      },
      navi: [["管理表マスター取り込み", "コンバージョン実績取り込み", "取り込み結果確認", "表示項目選択", "完了"],["ID入力", "目標設定", "サンプル確認", "表示項目選択", "完了"]],
    };
  }

  render() {
    const changeClientId = (id) => {
      this.setState({
        clientId: id
      });
    }

    const setUsingGa = (isUsingGa) => {
      this.setState({
        isUsingGa: isUsingGa
      });
    }

    const setNewData = (isNew) => {
      this.setState({
        isNewData: isNew
      });
    }

    const setGaIndexData = (indexData) => {
      this.setState({
        gaIndexData: indexData
      });
    }

    const setClientData = (clientData) => {
      this.setState({
        ...this.state.clientData,
        clientData: clientData
      });
    }

    const setSampleCode = (sampleCode) => {
      this.setState({
        sampleCode: sampleCode
      });
    }

    const initState = () => {
      this.setState({
        clientId: null,
        gaIndexData: null,
        isUsingGa: null,
        isNewData: false,
        sampleCode: null,
        clientData: {
          cvId: '',
          viewId: '',
          clientName: '',
          metrics: '',
          dimensions: '',
          keyType: null,
        },
      });
    }

    return (
      <div>
        <Switch>
          <Route
            exact
            path = '/setting/external-data'
            render = { (props) => (
              <ExternalDataHome
                setUsingGa = { setUsingGa }
                setClientData = { setClientData }
                changeClientId = { changeClientId }
                setNewData = { setNewData }
                initState = { initState }
                { ...props }
                { ...this.props }
                />
            )}
            >
          </Route>
          <Route
            exact
            path = '/setting/external-data/client'
            render = { (props) => (
              <ExternalDataClient
                changeClientId = { changeClientId }
                { ...props }
                { ...this.props }
                { ...this.state }
                />
            )}
            >
          </Route>
          <Route
            exact
            path = '/setting/external-data/ga'
            render = { (props) => (
              <ExternalDataGa
                setUsingGa = { setUsingGa }
                setNewData = { setNewData }
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
          <Route
            exact
            path = '/setting/external-data/ga-id'
            render = { (props) => (
              <ExternalDataGaId
                setClientData = { setClientData }
                setGaIndexData = { setGaIndexData }
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
          <Route
            exact
            path = '/setting/external-data/ga-goal'
            render = { (props) => (
              <ExternalDataGaGoal
                setClientData = { setClientData }
                setSampleCode = { setSampleCode }
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
          <Route
            exact
            path = '/setting/external-data/ga-sample'
            render = { (props) => (
              <ExternalDataGaSample
                setClientData = { setClientData }
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
          <Route
            exact
            path = '/setting/external-data/edit'
            render = { (props) => (
              <ExternalDataEdit
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
          <Route
            exact
            path = '/setting/external-data/finish'
            render = { (props) => (
              <ExternalDataFinish
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
          <Route
            exact
            path = '/setting/external-data/upload1'
            render = { (props) => (
              <ExternalDataUpload1
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
          <Route
            exact
            path = '/setting/external-data/upload2'
            render = { (props) => (
              <ExternalDataUpload2
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
          <Route
            exact
            path = '/setting/external-data/setting-client'
            render = { (props) => (
              <ExternalDataSetting
                setUsingGa = { setUsingGa }
                setNewData = { setNewData }
                { ...props }
                { ...this.props }
                { ...this.state }
               />
            )}
          />
        </Switch>
      </div>
    )
  }
}

export default ExternalData;
