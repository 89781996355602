import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

import BarBottom from '../../../atoms/BarBottom';
import Button from '../../../atoms/Button';
import HeadingS from '../../../atoms/HeadingS';
import InputText from '../../../atoms/InputText';
import StepNavi from '../../../molecules/StepNavi';
import ModalErrorMessage from '../../../organisms/ModalErrorMessage';
import IconAttentionInline from '../../../atoms/IconAttentionInline';

const StyledLink = styled.a`
box-sizing: border-box;
display: block;
position: relative;
margin-top: 16px;
text-decoration: none;
`

const gaData = process.env.REACT_APP_GA_DATA;

class ExternalDataGaId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      param: {
        id: ''
      },
      isErrorOpened: false,
      bodyText: '',
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = () => {
    if(!this.props.clientId) {
      this.props.history.push('/setting/external-data');
    }
    if(this.props.clientData.viewId) {
      if(!this.state.param.id) {
        this.setState({
          param: {
            id: this.props.clientData.viewId
          }
        });
      }
    }
  }

  handleToPrevPage = () => {
    this.props.history.goBack();
  }

  handleToNextPage = async () => {
    // プログレス表示
    this.props.startProgressing();
    // データ取得
    await axios.get( gaData, {
      params: {
        id: this.state.param.id
      }
    })
    .then((response) => {
      //　プログレス非表示
      this.props.endProgressing();
      // パラメータ設定
      this.props.setClientData({
        ...this.props.clientData,
        viewId: this.state.param.id
      });
      this.props.setGaIndexData(response.data);
      if(this.props.isNewData) {
        this.props.history.push('/setting/external-data/ga-goal');
      } else {
        this.props.history.push('/setting/external-data');
      }
    })
    .catch((error) => {
      this.setState({
        isErrorOpened: true,
        bodyText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
      });
      //　プログレス非表示
      this.props.endProgressing();
    })
  }

  render() {
    // エラーモーダル開閉
    const toggleErrorModal = (boolean) => {
      this.setState({
        isErrorOpened: boolean
      });
    }

    return (
      <div>
        <ModalErrorMessage
          heading = 'エラー'
          isOpened = { this.state.isErrorOpened }
          close = { () => toggleErrorModal(false) }
          bodyText = { this.state.bodyText }
        />
        <div
          style = {{ display: `${this.props.isNewData ? 'block' : 'none'}` }}
          >
          <StepNavi
            step = { 0 }
            navi = {this.props.navi[`${this.props.isUsingGa ? 1 : 0}`]}
          />
        </div>
        <HeadingS
          className = 'm-t-16'
          >GoogleAnalyticsで取得したコンバージョンデータ（目標）を、M-Mergeに自動反映することが可能です。</HeadingS>
        <HeadingS
          className = 'm-t-16'
          >①GoogleAnalyticsにユーザーを登録して下さい。</HeadingS>
        <p>メールアドレス「account-1@reporting-2015.iam.gserviceaccount.com」</p>
        <p>名前「メディックス（レポート自動送付）」を入力して下さい。</p>
        <StyledLink
          href = 'https://inf.mmerge.jp/2020/12/01/googleanalytics%e3%81%ae%e9%80%a3%e6%90%ba%e6%96%b9%e6%b3%95/'
          target = "_blank"
        >
          <IconAttentionInline className="m-r-8" />GoogleAnalyticsの連携方法
        </StyledLink>
        <HeadingS
          className = 'm-t-16'
          >上記が完了後、GoogleAnalyticsのビューIDを入力して下さい。</HeadingS>
        <InputText
          type = 'number'
          value = { this.state.param.id }
          min = { 0 }
          step = { 1 }
          className = "m-b-32"
          onChange = {
            ((e) => {
              this.setState({
                param: {
                  id: e.currentTarget.value
                }
              });
            })
          }
        />
        <StyledLink
          href = 'https://inf.mmerge.jp/2020/12/01/%e3%83%93%e3%83%a5%e3%83%bcid%e3%82%92%e7%a2%ba%e8%aa%8d%e3%81%99%e3%82%8b/'
          target = "_blank"
        >
          <IconAttentionInline className="m-r-8" />ビューIDの確認方法
        </StyledLink>

        <BarBottom>
          <Button
            onClick = { this.handleToPrevPage }
          >
            { this.props.isNewData ? '前に戻る' : 'キャンセル' }
          </Button>
          {
            (() => {
              if (this.state.param.id) {
                return (
                  <Button
                    color="orange"
                    className = 'm-l-8'
                    onClick = { this.handleToNextPage }
                  >
                    { this.props.isNewData ? '次に進む' : '設定' }
                  </Button>
                )
              }
            })()
          }
        </BarBottom>
      </div>
    )
  }
}

export default withRouter(ExternalDataGaId);
