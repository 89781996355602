import React from 'react';

import Button from '../../../atoms/Button';
import BarBottom from '../../../atoms/BarBottom';
import HeadingS from '../../../atoms/HeadingS';
import StepNavi from '../../../molecules/StepNavi';

const outsideSales = process.env.REACT_APP_OUTSIDE_SALES;

class ExternalDataEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = () => {
    if(!this.props.clientId) {
      this.props.history.push('/setting/external-data');
    }
  }

  handleToNextPage = () => {
    this.props.history.push('/setting/external-data');
  }

  render() {
    let msg = '12時までにアップロードいただいたデータは14時以降に表示され、16時までにアップロードいただいたデータは18時以降に表示されます'
    if(outsideSales === "1"){
      msg = '11時までにアップロードいただいたデータは13時以降に表示され、15時までにアップロードいただいたデータは17時以降に表示されます'
    }

    return (
      <div>
        <StepNavi
          step = { 4 }
          navi = {this.props.navi[`${this.props.isUsingGa ? 1 : 0}`]}
        />
        <HeadingS
          className = 'm-t-16'
          >外部データ連携の設定が完了しました</HeadingS>
        <HeadingS>変更する場合は右下の「TOPへ」ボタンを押した後のページで「設定の編集」を押してください</HeadingS>
        <br/>
        <HeadingS>次に「管理表マスター」の登録をお願い致します</HeadingS>
        <HeadingS>管理表のマスタの登録はアップロード後、1時間ごとの更新で取り込まれます</HeadingS>
        <br/>
        <HeadingS>また、管理表マスタの登録が完了しましたら、「コンバージョン実績」の登録をお願い致します</HeadingS>
        <HeadingS>{ msg }</HeadingS>
        <HeadingS>登録する頻度は自由ですが毎日登録することで精度の高いデータ集計となりますため推奨しております</HeadingS>
        <BarBottom>
          <Button
            color="orange"
            className = 'm-l-8'
            onClick = { this.handleToNextPage }
          >
            TOPへ
          </Button>
        </BarBottom>
      </div>
    )
  }
}

export default ExternalDataEdit;
