import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import axios from 'axios';

import COLOR from '../../../_const/COLOR'

import Button from '../../../atoms/Button';
import BarBottom from '../../../atoms/BarBottom';
import FlexBox from '../../../atoms/FlexBox';
import IconPlus from '../../../atoms/IconPlus';
import HeadingS from '../../../atoms/HeadingS';
import ButtonClose from '../../../atoms/ButtonClose';
import PullDownArray from '../../../organisms/PullDownArray';
import StepNavi from '../../../molecules/StepNavi';
import ModalErrorMessage from '../../../organisms/ModalErrorMessage';
import Radio from '../../../atoms/Radio';

const MAX_GOAL_METRICS = 10;
const MAX_DIMENSION = 4;

const gaData = process.env.REACT_APP_GA_DATA;

const StyledRadio = styled(Radio)`
  width: 500px;
  margin-top: 8px;
`
class ExternalDataGaGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metricsId: [],
      dimensionId: [],
      goalNum: 1,
      dimensionNum: 1,
      keyType: 0,
      isErrorOpened: false,
      bodyText: '',
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('外部データ連携');
    props.toggleDatePicker(false);
  }

  componentDidMount = () => {
    if(!this.props.clientId) {
      this.props.history.push('/setting/external-data');
    }
    if(this.props.clientData.metrics) {
      const metricsIds = this.props.clientData.metrics.split(',');
      this.setState({
        metricsId: metricsIds,
        goalNum: metricsIds.length,
      });
    }
    if(this.props.clientData.dimensions) {
      const dimensionIds = this.props.clientData.dimensions.split(',');
      this.setState({
        dimensionId: dimensionIds,
        dimensionNum: dimensionIds.length,
      });
    }
    if(this.props.clientData.keyType) {
      const keyType = this.props.clientData.keyType;
      this.setState({
        keyType: keyType,
      });
    }
    
  }

  handleToPrevPage = () => {
    this.props.history.goBack();
  }

  handleToNextPage = async () => {
    //　プログレス表示
    this.props.startProgressing();
    // パラメータをpropに設定
    let clientData = this.props.clientData;
    clientData.metrics = _.chain(this.state.metricsId).compact().join().value();
    clientData.dimensions = _.chain(this.state.dimensionId).compact().join().value();
    clientData.keyType = this.state.keyType;
    this.props.setClientData(clientData)
    // サンプルデータ取得
    await axios.get( gaData, {
      params: {
        id: this.props.clientData.viewId,
        metrics: this.props.clientData.metrics,
        dimensions: this.props.clientData.dimensions,
        keyType: this.state.keyType,
      }
    })
    .then((response) => {
      if(response.data) {
        this.props.setSampleCode(response.data);
        this.props.history.push('/setting/external-data/ga-sample');
      }
      //　プログレス非表示
      this.props.endProgressing();
    })
    .catch((error) => {
      this.setState({
        isErrorOpened: true,
        bodyText: error.response.data.errorMessage ? error.response.data.errorMessage : error.message,
      });
      //　プログレス非表示
      this.props.endProgressing();
    })
  }

  render() {
    const switchGoalId = (id, index) => {
      const arrayId = Array.from(this.state.metricsId);
      arrayId[index] = id;
      this.setState({
        metricsId: arrayId
      });
    }
    const switchDimensionId = (id, index) => {
      const arrayId = Array.from(this.state.dimensionId);
      arrayId[index] = id;
      this.setState({
        dimensionId: arrayId
      });
    }
    const addGoal = (id) => {
      const num = this.state.goalNum;
      if(num < MAX_GOAL_METRICS ) {
        this.setState({
          goalNum: num + 1
        });
      }
    }
    const delGoal = (nth) => {
      const num = this.state.goalNum;
      let arrayId = Array.from(this.state.metricsId);
      arrayId.splice(nth, 1);
      this.setState({
        metricsId: arrayId,
        goalNum: num - 1
      });
    }
    const addDimension = (id) => {
      const num = this.state.dimensionNum;
      if(num < MAX_DIMENSION ) {
        this.setState({
          dimensionNum: num + 1
        });
      }
    }
    const delDimension = (nth) => {
      const num = this.state.dimensionNum;
      let arrayId = Array.from(this.state.dimensionId);
      arrayId.splice(nth, 1);
      this.setState({
        dimensionId: arrayId,
        dimensionNum: num - 1
      });
    }
    // エラーモーダル開閉
    const toggleErrorModal = (boolean) => {
      this.setState({
        isErrorOpened: boolean
      });
    }

    if(this.props.gaIndexData) {
      return (
        <div>
          <ModalErrorMessage
            heading = 'エラー'
            isOpened = { this.state.isErrorOpened }
            close = { () => toggleErrorModal(false) }
            bodyText = { this.state.bodyText }
          />
          <StepNavi
            step = { 1 }
            navi = {this.props.navi[`${this.props.isUsingGa ? 1 : 0}`]}
          />
          <HeadingS
            className = 'm-t-16'
            >
            目標選択
          </HeadingS>
          <p>システムの疎通確認ができました。</p>
          <p>Google Analytics設定情報を画面に表示します。</p>
          <p>ドロップボックスから選択して下さい。</p>
          <p>選択後「次に進む」とサンプル画面を表示します。</p>
          <HeadingS
            className = 'm-t-32'
          >
            ●目標を選択して下さい。
            <span
              style = {{color: COLOR.RED}}
              className = 'm-l-8'
            >※必須項目です</span>
          </HeadingS>
          {
            (
              () => {
                let i;
                const items = [];
                for(i = 0; i < this.state.goalNum; i++) {
                  const num = i;
                  items.push(
                    <FlexBox
                    key = { `goal${i}` }
                    >
                      <ButtonClose
                        onClick = { () => delGoal(num) }
                      />
                      <PullDownArray
                        id = { this.state.metricsId[i] }
                        width = '200px'
                        listWidth = '200px'
                        items = { this.props.gaIndexData.goalMetrics }
                        disableIds = { this.state.metricsId }
                        onChange = { switchGoalId }
                        index = { i }
                        className = 'm-r-8'
                      />
                    </FlexBox>
                  )
                }
                return (
                  <FlexBox
                    flexWrap = { true }
                  >{items}</FlexBox>
                )
              }
            )()
          }
          <Button
            onClick = { (() => addGoal()) }
            ><IconPlus className="m-r-8" />追加</Button>
          {/* <HeadingS
            className = 'm-t-32'
          >
            ●ディメンションを選択して下さい。
            <span
              className = 'm-l-8'
            >※選択項目です</span>
          </HeadingS>
          {
            (
              () => {
                let i;
                const items = [];
                for(i = 0; i < this.state.dimensionNum; i++) {
                  const num = i;
                  items.push(
                    <FlexBox
                      key = { `dimension${i}` }
                    >
                      <ButtonClose
                        onClick = { () => delDimension(num) }
                      />
                      <PullDownArray
                        id = { this.state.dimensionId[i] }
                        width = '200px'
                        listWidth = '200px'
                        items = { this.props.gaIndexData.customDimension }
                        disableIds = { this.state.dimensionId }
                        onChange = { switchDimensionId }
                        index = { i }
                        className = 'm-r-8'
                      />
                    </FlexBox>
                  )
                }
                return (
                  <FlexBox
                    flexWrap = { true }
                  >{items}</FlexBox>
                )
              }
            )()
          }
          <Button
            onClick = { (() => addDimension()) }
            ><IconPlus className="m-r-8" />追加</Button> */}

          <HeadingS
            className = 'm-t-32'
          >
            ●紐付けキーを選択して下さい。
          </HeadingS>
          <StyledRadio
            name = 'keyType'
            id = { `keyType-0` }
            value = { 0 }
            checked = { this.state.keyType === 0? 'checked' : '' }
            onChange = {
              ((e) => {
                this.setState({
                  keyType: 0
                });
              })
            }
          >
            完全自動紐づけ
          </StyledRadio>
          <p>GoogleAnalyticsで「content」の個所で全ての媒体でユニークに紐づけキー(パラメータ)が振られている場合</p>
          <p>※こちらの場合は外部コンバージョンの連携に必要な作業がすべて自動で行われます</p>
          <p>※「content」の部分だけで全ての媒体でユニークとなっていないと使用できません　mediaやcampaignまで含めてユニークの場合は対応不可となります</p>
          <p>※criteoだけ別途、紐づけキー(パラメータ)のマスタ登録が必要となります(コンバージョン実績の登録は自動で行われます)</p>
          <p>※AdEBiSも完全自動にすることができます　詳しくは担当者までお問い合わせください</p>
          <StyledRadio
            name = 'keyType'
            id = { `keyType-1` }
            value = { 1 }
            checked = { this.state.keyType === 1? 'checked' : '' }
            onChange = {
              ((e) => {
                this.setState({
                  keyType: 1
                });
              })
            }
          >
            ハイブリッド紐づけ(Key5個)
          </StyledRadio>
          <p>GoogleAnalyticsを用いていて「content」だけではユニークになっておらず、</p>
          <p>5つのKey(source|medium|campaign|keyword|content)を用いて全媒体でユニークに採番している場合はこちらになります</p>
          <p>※こちらの場合は紐づけキー(パラメータ)のマスタ登録が必要となります</p>
          <p>※紐づけキーに追加、変更があった場合にはその都度、ご登録が必要です</p>
          <p>※コンバージョン実績は自動で紐づきます</p>
          <p>※マスタ登録の際には所定のcsvファイルをご利用ください</p>
          <StyledRadio
            name = 'keyType'
            id = { `keyType-2` }
            value = { 2 }
            checked = { this.state.keyType === 2? 'checked' : '' }
            onChange = {
              ((e) => {
                this.setState({
                  keyType: 2
                });
              })
            }
          >
            ハイブリッド紐づけ(Key3個)
          </StyledRadio>
          <p>GoogleAnalyticsを用いていて「content」だけではユニークになっておらず</p>
          <p>3つのKey(source|medium|campaign)を用いて全媒体でユニークに採番している場合はこちらになります</p>
          <p>※こちらの場合は紐づけキー(パラメータ)のマスタ登録が必要となります</p>
          <p>※紐づけキーに追加、変更があった場合にはその都度、ご登録が必要です</p>
          <p>※コンバージョン実績は自動で紐づきます</p>
          <p>※マスタ登録の際には所定のcsvファイルをご利用ください</p>

          <HeadingS
            className = 'm-t-32'
          >
            ＜POINT＞</HeadingS>
          <ul>
            <li>目標（メトリクス）は最大10個まで設定できます。</li>
            {/* <li>ディメンションは最大4個まで設定できます。</li> */}
          </ul>
          <BarBottom>
            <Button
              onClick = { this.handleToPrevPage }
            >
              前に戻る
            </Button>
            {
              (() => {
                if (this.state.metricsId.length > 0) {
                  return (
                    <Button
                      color="orange"
                      className = 'm-l-8'
                      onClick = { this.handleToNextPage }
                    >
                      次に進む
                    </Button>
                  )
                }
              })()
            }
          </BarBottom>
        </div>
      )
    } else {
      return (<div></div>)
    }
  }
}

export default ExternalDataGaGoal;
