import React from 'react';
import styled from 'styled-components'
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import classnames from 'classnames';

import REPORT_TARGETS from '../../_const/REPORT_TARGETS'

import AlertConditionText from '../../atoms/AlertConditionText';
import BarBottom from '../../atoms/BarBottom';
import Button from '../../atoms/Button';
import CheckboxWithLabel from '../../atoms/CheckboxWithLabel';
import DividerContent from '../../atoms/DividerContent';
import DividerDottedH from '../../atoms/DividerDottedH';
import FlexBox from '../../atoms/FlexBox';
import IconPlus from '../../atoms/IconPlus';
import HeadingS from '../../atoms/HeadingS';
import Radio from '../../atoms/Radio';
import Textarea from '../../atoms/Textarea';
import EditAlertCondition from '../../organisms/EditAlertCondition';
import ModalAlertEditAddRule from '../../organisms/ModalAlertEditAddRule';
import SelectTargetItems from '../../organisms/SelectTargetItems';
import SelectUsers from '../../organisms/SelectUsers';
import TitleEdit from '../../organisms/TitleEdit';

import User from '../../../utils/user';

const BLANK_TITLE_LABEL = '無題アラート';

const StyledProgressSection = styled.div`
  display: none;
  &.is-shown {
    display: block;
  }
`
const StyledLabelRuleLogic = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 32px;
  margin-left: 32px;
  color: #b2b2b2;
`
const StyledOptionLabel = styled.div`
  font-weight: 600;
`
const backendApi = process.env.REACT_APP_BACKEND_URI;

class AlertEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targetId: 0,
      targetData: [],
      checkedTargets: [],
      checkedUsers: [],
      isOpenedAddRuleModal: false,
      requestParam: {
        id: null,　// 新規追加の場合は0もしくは空
        name: "",

        // アラートを設定する対象
        clientIds:   null, // クライアントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        accountIds:  null, // アカウントID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        campaignIds: null, // キャンペーンID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        adgroupIds:  null, // 広告グループID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空
        keywordIds:  null, // キーワードID 1など。複数指定可能(API側は配列で受け取る)。指定しない場合は空

        // アラートのルール関連
        targetColId:      4, // 対象の項目ID
        targetIsRelative: 0, // 演算方法 0: 差(絶対値)、2: 割合(相対値)
        timing:           1, // 判定対象 0:なし、1:昨日と比較して、2:過去7日間と比較して、3:過去14日間と比較して、4:過去30日間と比較して、5:先月と比較して、6:カスタム→残り何日時点で、7:月初何日時点から
        timingCustom:     null, // カスタム（残り何日）の日数1～31
        targetDay:        null, // 月初何日時点からの際の、月初から何日時点からの日数
        targetNum:        0, // 対象数値
        compEqualSign:    1, // 不等号 0：なし、1:＞、2:＜、3:≧、4:≦、5:＝

        // 複数アラート条件の論理演算指定
        addConditionKind: 0, // 追加ルール 0：追加なし、1:OR条件、2:AND条件

        // アラートのルール関連(追加)
        targetColIdAdd:      null, // 追加ルールの対象の項目ID
        targetIsRelativeAdd: null, // 追加ルールの演算方法 0: 差(絶対値)、2: 割合(相対値)
        timingAdd:           null, // 追加ルールの判定対象 0:なし、1:昨日と比較して、2:過去7日間と比較して、3:過去14日間と比較して、4:過去30日間と比較して、5:先月と比較して、6:カスタム→残り何日時点で、7:月初何日時点から
        timingCustomAdd:     null, // 追加ルールのカスタム（残り何日）の日数1～31
        targetDayAdd:        null, // 追加ルールの月初何日時点からの際の、月初から何日時点からの日数
        targetNumAdd:        null, // 追加ルールの対象数値
        compEqualSignAdd:    null, // 追加ルールの不等号 0：なし、1:＞、2:＜、3:≧、4:≦、5:＝

        // メモ
        note: '', // 備考

        // 通知、公開範囲、お知らせ
        alertNotification: 1, // アラート通知ステータス 0:オフ、1:オン、2:当月メールのみオフ
        shareSetting:      0, // 公開設定 0:自身、1:グループ、2:部署、3:全社
        isShowDisplay:     null, // 画面にアラートを表示 1:する、それ以外:しない
        isMailSend:        null, // アラートをメール送付 1:する、それ以外:しない

        // 編集画面に選択肢が存在しないプロパティ
        alertMailAddress:  null, // アラート送付先のアドレス hasebe@medix-inc.co.jp ※複数ある場合は\r\n区切り
      },
    };
    props.switchGlobalCatId(4);
    props.switchContentTitle('アラート編集');
    props.toggleDatePicker(false);
  }
  componentWillMount = async () => {
    // プログレス表示
    this.props.startProgressing();

    // データを取得
    await this.getExistingData();

    //　プログレス非表示
    this.props.endProgressing();
  }
  getTargetData = () => {
    // 各階層の要素一覧の取得
    return axios.get(backendApi + 'report', {
      params: {
        ...User.apiParams(),
        target: this.state.targetId,
        timeSpan: 8,
        dataExist: 1,
      }
    })
    .then((response) => {
      this.setState({
        targetData: response.data.reportJsonList,
      });
    })
    .catch(() => {
    });
  }
  getExistingData = async () => {
    // URIのクエリパラメータにidが存在するかどうかを判断し、
    // 存在する場合は既存アラートの情報を取得してsetStateする。
    const queries = queryString.parse(window.location.search);

    if (
      // idが存在する場合はAPIを実行
      queries.id !== undefined
    ) {
      let alertData;
      await axios.get(backendApi + 'alert', {
        params: {
          ...User.apiParams(),
          id: parseInt(queries.id),
          myAlert: 1,
        }
      })
      .then((response) => {
        if (response.data.length > 0) alertData = response.data[0];
        if (alertData.name === BLANK_TITLE_LABEL) alertData.name = '';
      })
      .catch(() => {
      });

      // responseのdata配列が0件の場合はreturn
      if (alertData.length === 0) {
        return;
      };

      let targetId = 1;
      let checkedTargets = '';
      if (alertData.keywordIds) {
        targetId = 5;
        checkedTargets = alertData.keywordIds;
      } else if (alertData.adgroupIds) {
        targetId = 4;
        checkedTargets = alertData.adgroupIds;
      } else if (alertData.campaignIds) {
        targetId = 3;
        checkedTargets = alertData.campaignIds;
      } else if (alertData.accountIds) {
        targetId = 2;
        checkedTargets = alertData.accountIds;
      } else if (alertData.clientIds) {
        checkedTargets = alertData.clientIds;
      }

      if (checkedTargets !== '') {
        checkedTargets = checkedTargets.split(',').map(o => {
          return parseInt(o);
        })
      } else {
        checkedTargets = [];
      }

      let checkedUsers = [];
      if (alertData.userIds) {
        checkedUsers = alertData.userIds.split(',').map(o => {
          return o;
        })
      }

      await this.setState({
        targetId: targetId,
        checkedTargets: checkedTargets,
        checkedUsers: checkedUsers,
        requestParam: {
          ...this.state.requestParam,
          ...alertData,
        },
      });
    } else if (
      // target/targetIdsがいずれも存在する場合は
      queries.target !== undefined
      && queries.targetIds !== undefined
    ) {
      await this.setState({
        targetId: parseInt(queries.target),
        checkedTargets: queries.targetIds.split(',').map(n => { return parseInt(n) }),
      })
    } else {
      // id/target/targetIdsがいずれも存在しない場合はreturn
      return;
    }

    await this.getTargetData();

    return;
  }
  render() {
    // タイトル編集
    const editTitle = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          name: e.currentTarget.value,
        },
      });
    }

    // targetの階層を選択
    const switchTargetId = async (e) => {
      // プログレス表示
      this.props.startProgressing();

      await this.setState({
        targetId: parseInt(e.target.value),
        checkedTargets: [],
      });
      await this.getTargetData();

      //　プログレス非表示
      this.props.endProgressing();
    }

    // targetの各要素をチェック
    const checkTargetItem = (e) => {
      const id = parseInt(e.target.value);
      const { checkedTargets } = this.state;

      if (checkedTargets.indexOf(id) > -1) {
        checkedTargets.splice(checkedTargets.indexOf(id), 1)
      } else {
        checkedTargets.push(id);
      }
      this.setState({
        checkedTargets: checkedTargets
      });
    }

    // Targetのチェックボックスをすべて選択
    const toggleCheckTargetsAll = (checkedFiltered, bool) => {
      const { checkedTargets } = this.state;

      if (bool) {
        this.setState({
          checkedTargets: _.union(
            checkedTargets,
            checkedFiltered
          )
        });
      } else {
        this.setState({
          checkedTargets: _.without(
            checkedTargets,
            ...checkedFiltered
          )
        });
      }
    }

    // Targetのチェックボックスをすべてクリア
    const clearCheckTargetAll = () => {
      this.setState({
        checkedTargets: []
      })
    }

    const setAddConditionValue = (updateParam) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          ...updateParam,
        },
      });
    }

    // ルール追加モーダルを開く
    const openAddRuleModal = () => {
      this.setState({
        isOpenedAddRuleModal: true
      });
    }

    // ルール追加モーダルを閉じる
    const closeAddRuleModal = () => {
      this.setState({
        isOpenedAddRuleModal: false
      });
    }

    // 追加ルールの条件を設定
    const setAddCondition = (e) => {
      this.setState({
        isOpenedAddRuleModal: false,
        requestParam: {
          ...this.state.requestParam,
          addConditionKind: parseInt(e.target.value),
          targetColIdAdd: 4,
          targetNumAdd: 0,
          compEqualSignAdd: 1,
          timingAdd: 1,
        },
      });
    }

    // ルールの削除
    const removeRule = () => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          targetColId: this.state.requestParam.targetColIdAdd,
          timing: this.state.requestParam.timingAdd,
          targetNum: this.state.requestParam.targetNumAdd,
          compEqualSign: this.state.requestParam.compEqualSignAdd,
          addConditionKind: 0,
          targetColIdAdd: null,
          timingAdd: null,
          targetNumAdd: null,
          compEqualSignAdd: null,
        },
      });
    }
    const removeAdditionalRule = () => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          addConditionKind: 0,
          targetColIdAdd: null,
          timingAdd: null,
          targetNumAdd: null,
          compEqualSignAdd: null,
        },
      });
    }

    // メモの編集
    const editNote = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          note: e.target.value,
        }
      });
    }

    // mailAddresの編集
    const editMailAddress = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          alertMailAddress: e.target.value,
        }
      });
    }

    // 公開範囲のラジオボタンを制御
    const switchShareSetting = async (e) => {
      const id = parseInt(e.target.value);

      this.setState({
        checkedUsers: (id === 4) ? this.state.checkedUsers : [],
        requestParam: {
          ...this.state.requestParam,
          shareSetting: id,
        }
      });
    }

    // ユーザーの各要素をチェック
    const checkUser = (e) => {
      const id = e.target.value;
      const { checkedUsers } = this.state;

      if (checkedUsers.indexOf(id) > -1) {
        checkedUsers.splice(checkedUsers.indexOf(id), 1)
      } else {
        checkedUsers.push(id);
      }
      this.setState({
        checkedUsers: checkedUsers,
      });
    }

    // ユーザーのチェックボックスをすべて選択
    const toggleCheckUsersAll = (checkedFiltered, bool) => {
      const { checkedUsers } = this.state;

      if (bool) {
        this.setState({
          checkedUsers: _.union(
            checkedUsers,
            checkedFiltered
          )
        });
      } else {
        this.setState({
          checkedUsers: _.without(
            checkedUsers,
            ...checkedFiltered
          )
        });
      }
    }

    // ユーザーのチェックボックスをすべてクリア
    const clearCheckUsersAll = () => {
      this.setState({
        checkedUsers: []
      })
    }

    // 通知スイッチの切り替え
    const switchAlertNotification = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          alertNotification: parseInt(e.target.value),
        }
      });
    }

    // お知らせ ダッシュボードのチェックを制御
    const checkShowDisplay = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          isShowDisplay: (e.target.checked) ? 1 : null,
        }
      });
    }

    // お知らせ メール通知のチェックを制御
    const checkMailSend = (e) => {
      this.setState({
        requestParam: {
          ...this.state.requestParam,
          isMailSend: (e.target.checked) ? 1 : null,
        }
      });
    }

    // 保存
    const save = async () => {
      // プログレス表示
      this.props.startProgressing();

      // 選択したIDを含むデータを抽出
      let keyname = '';
      switch(this.state.targetId) {
        case 1:
          keyname = 'clientId';
          break;
        case 2:
          keyname = 'accountId';
          break;
        case 3:
          keyname = 'campaignId';
          break;
        case 4:
          keyname = 'adgroupId';
          break;
        case 5:
          keyname = 'keywordId';
          break;
        default:
      }
      let checkedDataFilter = []; // チェック対象かつ取得したデータの中にあるIDを保持する変数
      let checkedData = this.state.checkedTargets.map(x =>
        _.find(this.state.targetData, (o) => {
          if(o[keyname] === parseInt(x)){
            // チェックされたIDかつ取得データにあるIDのみ保持する（チェックされたIDでも取得データにない場合エラーになるので ※登録時は取得データにあったが、変更時にはないパターンもあるため）
            checkedDataFilter.push(parseInt(x));
          }
        })
      );
 
      await axios.get(backendApi + 'alertModify', {
        params: {
          ...User.apiParams(),
          ...this.state.requestParam,
          func       : 1,
          name       : (this.state.requestParam.name === '') ? BLANK_TITLE_LABEL : this.state.requestParam.name,
          clientIds   : (this.state.targetId === 1) ? checkedDataFilter.join(',') : null,
          accountIds  : (this.state.targetId === 2) ? checkedDataFilter.join(',') : null,
          campaignIds : (this.state.targetId === 3) ? checkedDataFilter.join(',') : null,
          adgroupIds  : (this.state.targetId === 4) ? checkedDataFilter.join(',') : null,
          keywordIds  : (this.state.targetId === 5) ? checkedDataFilter.join(',') : null,
          userIds    : this.state.checkedUsers.join(','),
        }
      })
      .then((response) => {
        this.props.history.push('/setting/alert');

        //　プログレス非表示
        this.props.endProgressing();
      });
    }

    return (
      <div>
        <TitleEdit
          name = 'alertTitle'
          value = { this.state.requestParam.name }
          blankLabel = { BLANK_TITLE_LABEL }
          placeholder = 'アラート名を入力してください。'
          onChange = { editTitle }
        />

        <HeadingS>
          アラートを設定するカテゴリーを選んでください　
        </HeadingS>
        <FlexBox>
          {
            (() => {
              return REPORT_TARGETS.map(item => {
                return (
                  <Radio
                    className = 'm-r-24'
                    key = { _.uniqueId() }
                    name = 'target-category'
                    id = { `target-category-${item.id}` }
                    value = { String(item.id) }
                    checked = { this.state.targetId === item.id ? 'checked' : '' }
                    onChange = { switchTargetId }
                  >
                    { item.label }
                  </Radio>
                )
              });
            })()
          }
        </FlexBox>

        <StyledProgressSection
          className = {
            classnames({
              'is-shown': this.state.targetId > 0
            })
          }
        >
          <HeadingS
            className = 'm-t-40'
          >
            アラートを設定する対象を選んでください　
          </HeadingS>
          <SelectTargetItems
            targetId = { this.state.targetId }
            targetData = { this.state.targetData }
            checked = { this.state.checkedTargets }
            checkItem = { checkTargetItem }
            toggleCheckAll = { toggleCheckTargetsAll }
            clearCheckAll = { clearCheckTargetAll }
          />

          <HeadingS
            className = 'm-t-40'
          >
            アラートのルールを設定してください　
          </HeadingS>
          <DividerDottedH
            hasMargin
          />
          <AlertConditionText
            param = { this.state.requestParam }
          />
          <DividerDottedH
            hasMargin
          />
          <FlexBox
            align = 'flex-start'
          >
            <EditAlertCondition
              isAddCondition = { false }
              targetColId = { this.state.requestParam.targetColId }
              targetIsRelative = { this.state.requestParam.targetIsRelative }
              timing = { this.state.requestParam.timing }
              timingCustom = { this.state.requestParam.timingCustom }
              targetDay = { this.state.requestParam.targetDay }
              targetNum = { this.state.requestParam.targetNum }
              compEqualSign = { this.state.requestParam.compEqualSign }
              addConditionKind = { this.state.requestParam.addConditionKind }
              setAddConditionValue = { setAddConditionValue }
              removeRule = { removeRule }
            />
            {
              (() => {
                if (this.state.requestParam.addConditionKind === 0) {
                  return (
                    <Button
                      className = 'm-l-24'
                      onClick = { openAddRuleModal }
                    >
                      ルールを追加
                      <IconPlus
                        className = 'm-l-8'
                      />
                    </Button>
                  )
                } else {
                  return (
                    <StyledLabelRuleLogic>
                      {
                        (() => {
                            // アラートのルールの条件ラベルを表示
                          switch (this.state.requestParam.addConditionKind) {
                            case 1: return 'OR'
                            case 2: return 'AND'
                            default: return;
                          }
                        })()
                      }
                    </StyledLabelRuleLogic>
                  )
                }
              })()
            }
            {
              (() => {
                if (this.state.requestParam.addConditionKind > 0) {
                  return (
                    <EditAlertCondition
                      isAddCondition = { true }
                      targetColId = { this.state.requestParam.targetColIdAdd }
                      targetIsRelative = { this.state.requestParam.targetIsRelativeAdd }
                      timing = { this.state.requestParam.timingAdd }
                      timingCustom = { this.state.requestParam.timingCustomAdd }
                      targetDay = { this.state.requestParam.targetDayAdd }
                      targetNum = { this.state.requestParam.targetNumAdd }
                      compEqualSign = { this.state.requestParam.compEqualSignAdd }
                      addConditionKind = { this.state.requestParam.addConditionKind }
                      setAddConditionValue = { setAddConditionValue }
                      removeRule = { removeAdditionalRule }
                    />
                  )
                }
              })()
            }
          </FlexBox>

          <HeadingS
            className = 'm-t-40'
          >
            メモを記入してください
            <small
              className = 'm-l-8'
            >
              (任意)
            </small>
          </HeadingS>
          <Textarea
            placeholder = "メモを記入してください。"
            value = { this.state.requestParam.note }
            onChange = { editNote }
          />

          <DividerContent />

          <HeadingS>
            公開範囲を指定してください。
          </HeadingS>
          <FlexBox>
            {
              (() => {
                const items = [
                  '自身',
                  'グループ',
                  '部署',
                  '全社',
                  '特定のユーザー',
                ]
                return items.map((o, i) => {
                  return (
                    <Radio
                      className = 'm-r-24'
                      key = { _.uniqueId() }
                      name = 'share-setting'
                      id = { `share-setting-${i}` }
                      value = { String(i) }
                      checked = { this.state.requestParam.shareSetting === i ? 'checked' : '' }
                      onChange = { switchShareSetting }
                    >
                      { o }
                    </Radio>
                  )
                });
              })()
            }
          </FlexBox>
          {
            (() => {
              // 特定のユーザーを選択した場合はユーザー一覧から対象を選択するためのUIを出す。
              if (this.state.requestParam.shareSetting === 4) {
                return (
                  <SelectUsers
                    users = { this.props.users }
                    checked = { this.state.checkedUsers }
                    check = { checkUser }
                    toggleCheckAll = { toggleCheckUsersAll }
                    clearCheckAll = { clearCheckUsersAll }
                    className = 'm-t-24'
                  />
                )
              }
            })()
          }

          <DividerContent />

          <FlexBox
            justify = 'space-between'
          >
            <FlexBox>
              <StyledOptionLabel>
                通知
              </StyledOptionLabel>
              <Radio
                id = "notification-0"
                name = "notification"
                value = '0'
                checked = { this.state.requestParam.alertNotification === 0 }
                onChange = { switchAlertNotification }
                className = 'm-l-16'
              >
                オフ
              </Radio>
              <Radio
                id = "notification-1"
                name = "notification"
                value = '1'
                checked = { this.state.requestParam.alertNotification === 1 }
                onChange = { switchAlertNotification }
                className = 'm-l-16'
              >
                オン
              </Radio>
              <Radio
                id = "notification-2"
                name = "notification"
                value = '2'
                checked = { this.state.requestParam.alertNotification === 2 }
                onChange = { switchAlertNotification }
                className = 'm-l-16'
              >
                当月メールのみオフ
              </Radio>
            </FlexBox>
            <FlexBox>
              <StyledOptionLabel>
                お知らせ
              </StyledOptionLabel>
              <CheckboxWithLabel
                id = "show-display"
                name = "show-display"
                checked = { this.state.requestParam.isShowDisplay === 1 }
                onChange = { checkShowDisplay }
                className = 'm-l-16'
              >
                ダッシュボード
              </CheckboxWithLabel>
              <CheckboxWithLabel
                id = "mail-send"
                name = "mail-send"
                checked = { this.state.requestParam.isMailSend === 1 }
                onChange = { checkMailSend }
                className = 'm-l-16'
              >
                メール通知
              </CheckboxWithLabel>
            </FlexBox>
          </FlexBox>
          <HeadingS
            className = 'm-t-40'
          >
            メール通知先を記入してください
            <small
              className = 'm-l-8'
            >
              (メール通知の場合、必須)
            </small>
          </HeadingS>
          <Textarea
            placeholder = "メール通知先を記入してください※複数ある場合は改行して入力"
            value = { this.state.requestParam.alertMailAddress }
            onChange = { editMailAddress }
          />
        </StyledProgressSection>

        <BarBottom>
          <Button
            as = { Link }
            to = '/setting/alert/'
          >
            キャンセル
          </Button>
          {
            (() => {
              if (this.state.targetId > 0) {
                return (
                  <Button
                    color="orange"
                    onClick = { save }
                    className = 'm-l-8'
                  >
                    アラートを保存する
                  </Button>
                )
              }
            })()
          }
        </BarBottom>

        <ModalAlertEditAddRule
          heading = 'ルールの追加'
          isOpened = { this.state.isOpenedAddRuleModal }
          isOpenedSideMenu = { this.props.isOpenedSideMenu }
          setAddCondition = { setAddCondition }
          close = { closeAddRuleModal }
        />
      </div>
    )
  }
}

export default AlertEdit;
